import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    ButtonsContainer,
    MiddleFormContainer,
    MiddleFormSubtitleContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1Title, SubtitleText } from '../../common/styles/text'
import { ButtonBlock, FormContainer } from '../../common/styles/form-styles'
import TextField from '../../common/form/text-field/TextField'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import {
    changeConfirmPasswordValue,
    changePasswordValue,
    changeRequestStatus,
    selectForgotPasswordFormData,
    selectGlobalError,
    selectValidationErrors,
    setValidationError,
} from '../../../reducers/forms/forgot-password-form'
import { FieldContainer } from '../../common/form/text-field/styles'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { confirmNewPassword } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'

function PasswordForm({ confirmChangePasswordToken }) {
    const dispatch = useDispatch()

    const forgotPasswordFormData = useSelector(selectForgotPasswordFormData)
    const globalError = useSelector(selectGlobalError)
    const validationErrors = useSelector(selectValidationErrors)

    const changePasswordField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'password', message: null }))
        dispatch(changePasswordValue({ password: event.target.value }))
    }

    const changeConfirmPasswordField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'confirmPassword', message: null }))
        dispatch(changeConfirmPasswordValue({ confirmPassword: event.target.value }))
    }

    const confirmChangePasswordAction = (event) => {
        event.preventDefault()
        const { password, confirmPassword } = forgotPasswordFormData

        const validateField = (fieldName, fieldValue, regex, errorMessage) => {
            if (!regex.test(fieldValue)) {
                dispatch(setValidationError({ field: fieldName, message: errorMessage }))
                return false
            }
            return true
        }

        let validForm = true
        //todo: add full validation
        validForm = validateField('password', password, /.+/, 'Empty password') && validForm
        validForm = validateField('confirmPassword', confirmPassword, /.+/, 'Empty confirm password') && validForm

        if (validForm) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(confirmNewPassword({ confirmChangePasswordToken, password, confirmPassword }))
        }
    }

    return (
        <MiddleFormContainer marginTop="130px">
            <MiddleFormTitleContainer>
                <H1Title>Change password</H1Title>
            </MiddleFormTitleContainer>
            <MiddleFormSubtitleContainer>
                <SubtitleText>Your new password must be different from previous used password</SubtitleText>
            </MiddleFormSubtitleContainer>

            <ErrorMessage error={globalError} />

            <FormContainer onSubmit={confirmChangePasswordAction}>
                <fieldset>
                    <FieldContainer>
                        <fieldset>
                            {/*todo: add validation*/}
                            <TextField
                                label={'Password'}
                                value={forgotPasswordFormData.password}
                                errorMessage={validationErrors.password}
                                changeEvent={changePasswordField}
                                placeholder={'Enter your password'}
                                type={'password'}
                            />
                        </fieldset>
                    </FieldContainer>
                    <FieldContainer>
                        <fieldset>
                            {/*todo: add validation*/}
                            <TextField
                                label={'Confirm password'}
                                value={forgotPasswordFormData.confirmPassword}
                                errorMessage={validationErrors.confirmPassword}
                                changeEvent={changeConfirmPasswordField}
                                placeholder={'Confirm your password'}
                                type={'password'}
                            />
                        </fieldset>
                    </FieldContainer>
                </fieldset>
                <ButtonsContainer>
                    <ButtonBlock>
                        <SimpleButton text="Change password" />
                    </ButtonBlock>
                </ButtonsContainer>
            </FormContainer>
        </MiddleFormContainer>
    )
}

export default memo(PasswordForm)
