import React, { memo, useState } from 'react'
import Checkbox from '../switch/Checkbox'
import AnimatedAccordion from '../animated-accordion/AnimatedAccordion'
import { AccordionWrapper, TitleContainer } from './styles'
import { RegularText } from '../styles/text'
import { TextInput } from '../form/styles'

function SavePaymentMethod() {
    const [selected, setSelected] = useState(false)

    return (
        <AccordionWrapper>
            <AnimatedAccordion
                isOpen={selected}
                titleComponent={
                    <TitleContainer onClick={() => setSelected((prevState) => !prevState)}>
                        <Checkbox
                            borderRadius="6px"
                            isChecked={selected}
                            onChange={() => setSelected((prevState) => !prevState)}
                        />
                        <RegularText>Save payment method</RegularText>
                    </TitleContainer>
                }
                contentComponent={<TextInput placeholder="Method title (optional)" />}
            />
        </AccordionWrapper>
    )
}

export default memo(SavePaymentMethod)
