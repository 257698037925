import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/user'
import { useNavigate } from 'react-router'

const ProtectedRoute = ({ children }) => {
    const [authChecked, setAuthChecked] = useState(false)
    const user = useSelector(selectUser)
    const navigate = useNavigate()

    useEffect(() => {
        !user ? navigate('/sign-in') : setAuthChecked(true)
    }, [user])

    return authChecked ? <>{children}</> : <span>Checking</span>
}

export default ProtectedRoute
