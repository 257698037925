import React, { memo, useMemo } from 'react'
import { EditButtonContainer, EditButtonWrapper, FilterOptionsContainer, ItemContainer } from './styles'
import ThreeDots from '../../../../assets/images/three-dots.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { useDropdown } from '../../../common/hooks/useDropdown'
import useWindowDimensions from '../../../common/hooks/useWindowDimensions'

function ItemWrapper({ content, withoutMenu, onRename, onDelete }) {
    const { isMobile } = useWindowDimensions()

    const styles = useMemo(
        () => ({
            width: '12.5rem',
            top: '2.5rem',
            right: isMobile ? '0' : '-70%',
        }),
        [isMobile],
    )

    const editOptions = [
        {
            id: 'rename-option',
            name: 'Rename folder',
            action: (e) => {
                e.preventDefault()
                e.stopPropagation()
                onRename()
            },
        },
        {
            id: 'delete-option',
            name: 'Delete folder',
            action: (e) => {
                e.preventDefault()
                e.stopPropagation()
                onDelete()
            },
        },
    ]
    const { generateDropdown, toggleDropdown, showOptions } = useDropdown(editOptions, styles)

    const handleEditMenuClick = (e) => {
        toggleDropdown()
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <ItemContainer>
            {content}
                <EditButtonWrapper withoutMenu={withoutMenu}>
                    <EditButtonContainer showMenu={showOptions} onClick={handleEditMenuClick}>
                        <StyledSVG src={ThreeDots} />
                    </EditButtonContainer>
                </EditButtonWrapper>
            {generateDropdown()}
        </ItemContainer>
    )
}

export default memo(ItemWrapper)
