import React, { memo } from 'react'
import { EditableSectionTitle, TitleLeftContent } from '../styles'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'
import DeleteIcon from '../../../../../assets/images/delete.svg'
import DehazeIcon from '../../../../../assets/images/dehaze.svg'
import { RegularText } from '../../../../common/styles/text'

function SectionTitle({ isTitle, index, onDelete }) {
    return (
        <EditableSectionTitle>
            <TitleLeftContent>
                {!isTitle && <StyledSVG src={DehazeIcon} />}
                <RegularText>{isTitle ? 'Title' : `Section ${index}`}</RegularText>
            </TitleLeftContent>
            {!isTitle && <StyledSVG src={DeleteIcon} onClick={onDelete} />}
        </EditableSectionTitle>
    )
}

export default memo(SectionTitle)
