import React, { memo } from 'react'
import { TransparentBorderButtonComponent } from './styles'
import { ButtonText } from '../styles'
import { white } from '../../styles/colors'
import StyledSVG from '../../styled-svg/StyledSVG'

const TransparentBorderButton = ({ text, action, icon, color = white, disabled = false }) => {
    return (
        <TransparentBorderButtonComponent disabled={disabled} color={color} onClick={action}>
            {icon && <StyledSVG src={icon} />}
            <ButtonText color={color}>&nbsp;{text}</ButtonText>
        </TransparentBorderButtonComponent>
    )
}

export default memo(TransparentBorderButton)
