import styled from 'styled-components'
import { TextInput } from '../../../../common/form/styles'
import { ButtonComponent } from '../../../../common/button/styles'

export const EditPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 24px;
`

export const EditField = styled(TextInput)`
    background: #F9F9F9;
`

export const ButtonTextContainer = styled.div`
    padding: 4px 24px;
` 

export const CustomButton = styled(ButtonComponent)`
    height: 56px;
    width: 150px;
    border-radius: 32px;
`