import React, { memo, useMemo, useState } from 'react'
import { AutocompleteDropdownContainer, FieldLabel } from './styles'

const AutocompleteDropdown = ({ name, label, placeholder, items, onChange, value, maxResults, forceFilter }) => {
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const filteredItems = useMemo(() => {
        const filteredItems = items.filter(item => (
            (!forceFilter && !search) || item.text.toLowerCase().includes(search.toLowerCase())
        )).slice(0, maxResults);
        
        if (value && !filteredItems.find(item => item.value == value)) {
            const selectedItem = items.find(item => item.value == value);
            if(selectedItem) {
                filteredItems.push(selectedItem);
            }
        }
        
        return filteredItems;
    }, [items, search]);

    return (<FieldLabel>
        {label}
        <AutocompleteDropdownContainer
            open={isOpen}
            icon="angle down"
            placeholder={placeholder}
            onClick={(e) => setIsOpen(true)}
            search
            selectOnNavigation={false}
            defaultSearchQuery={search}
            selection
            options={filteredItems}
            value={value}
            onSearchChange={(e) => {
                setSearch(e.target.value)
                setIsOpen(true)
            }}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setTimeout(() => setIsOpen(false), 100)}
            onChange={(e, { value }) => {
                onChange({ target: { value, name }, preventDefault: () => {} })
                setTimeout(() => setIsOpen(false), 100)
            }}
        />
    </FieldLabel>)
}

export default memo(AutocompleteDropdown)
