import React, { memo, useRef, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentFormContainer } from '../styles'
import Loading from '../../../common/loading/Loading'
import SimpleButton from '../../../common/button/simple-button/SimpleButton'
import { ButtonBlock } from '../../styles'
import { ROUTES } from '../../../../utils/utils'
import { useNavigate } from 'react-router'
import { changeSubscription } from '../../../../reducers/user'
import SavePaymentMethod from '../../../common/payment/SavePaymentMethod'
import SavedPaymentMethodsList from '../../../common/payment/SavedPaymentMethodsList'
import PaymentTitle from '../../../credit/stripe/PaymentTitle'
import { tagEvent } from '../../../../utils/tagEvent'
import PaymentPromo from './PaymentPromo'
import { clearPromo, selectPromo } from '../../../../reducers/subscription'
import { searchPromo, updateSubscription } from '../../slice'

function PaymentForm({ subscriptionId, price, productId, period }) {
    const dispatch = useDispatch()
    const elements = useElements()
    const navigate = useNavigate()
    const stripe = useStripe()
    const searchedPromo = useRef('')
    const promo = useSelector(selectPromo)
    const [promoCode, setPromoCode] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const validatePromo = () => {
        if (searchedPromo.current === promoCode) return
        if (promoCode) {
            dispatch(searchPromo({ productId, promoCode }))
            searchedPromo.current = promoCode
        } else {
            dispatch(clearPromo())
            searchedPromo.current = ''
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            return
        }

        if (promoCode && !promo.id) return

        setIsLoading(true)

        let newSubscriptionId
        if (promo.id) {
            try {
                newSubscriptionId = (await dispatch(updateSubscription({ promoId: promo.id, subscriptionId })).unwrap())
                    .subscriptionId
            } catch (e) {
                console.error('Promo failed for subscription.')
                setIsLoading(false)
                return
            }
        }

        if (promo.skipPayment) {
            // This is a promo that doesn't require collecting card info
            NotificationManager.info(`Subscribed successfully`)
            navigate(ROUTES.ARTICLES)
            dispatch(changeSubscription({ subscriptionId: newSubscriptionId }))
            return
        }

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: { save_payment_method: true },
        })

        if (result.error) {
            NotificationManager.error(`Something went wrong. Please try again, or contact support if the problem persists.`)
        } else if (result.paymentIntent.status === 'succeeded') {
            NotificationManager.info(`Subscribed successfully`)
            navigate(ROUTES.ARTICLES)
            dispatch(changeSubscription({ subscriptionId }))
            tagEvent('purchase', {
                transaction_id: result.paymentIntent.id,
                currency: 'USD',
                value: Number.parseFloat(price.toString().replace('$', '')),
                items: [
                    {
                        item_id: productId,
                        item_name: `${period} subscription - ${subscriptionId}`,
                    },
                ],
            })
        }

        setIsLoading(false)
    }

    return (
        <>
            {isLoading && <Loading />}

            <PaymentFormContainer>
                <PaymentTitle />
                {!promo.skipPayment ? (
                    <SavedPaymentMethodsList>
                        <PaymentElement id="payment-element" />
                        <SavePaymentMethod />
                    </SavedPaymentMethodsList>
                ) : (
                    ''
                )}
                <PaymentPromo
                    value={promoCode}
                    onChange={({ target }) => setPromoCode(target.value)}
                    onBlur={validatePromo}
                />
                <ButtonBlock>
                    <SimpleButton disabled={!!promo.error} action={handleSubmit} text="Submit" />
                </ButtonBlock>
            </PaymentFormContainer>
        </>
    )
}

export default memo(PaymentForm)
