import React, { memo, useState, useEffect, useRef } from 'react'
import {
    ArrowIconContainer,
    SelectboxContainer,
    SelectboxFieldBox,
    SelectboxOption,
    SelectboxOptionsContainer,
    SelectedValue,
} from './styles'
import ArrowDownIcon from '../../../../assets/images/arrow-down.svg'
import { FieldLabel } from '../styles'
import StyledSVG from '../../styled-svg/StyledSVG'
import { black } from '../../styles/colors'

const SelectboxField = ({
    label,
    selectedValue,
    placeholder,
    options,
    name,
    changeValueEvent,
    customDropdownComponent: DropdownComponent,
    useDefaultValue,
}) => {
    const wrapperRef = useRef(null)
    const [showOptions, setShowOptions] = useState('')

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowOptions(false)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    const changeValue = (event, value) => {
        event.preventDefault()
        setShowOptions(false)
        changeValueEvent(value, name)
    }

    const selectedOption = options.find((option) => option.value === selectedValue)

    return (
        <FieldLabel>
            {label}
            <SelectboxContainer ref={wrapperRef}>
                <SelectboxFieldBox
                    onClick={() => {
                        setShowOptions(true)
                    }}
                >
                    {useDefaultValue ? (
                        <SelectedValue>{selectedValue}</SelectedValue>
                    ) : (
                        <SelectedValue>{selectedOption ? selectedOption.name : placeholder}</SelectedValue>
                    )}
                    <ArrowIconContainer>
                        <StyledSVG color={black} width={12} height={9} src={ArrowDownIcon} />
                    </ArrowIconContainer>
                </SelectboxFieldBox>
                {showOptions && (
                    <SelectboxOptionsContainer>
                        {DropdownComponent ? (
                            <>{DropdownComponent}</>
                        ) : (
                            <>
                                {options.map((option) => (
                                    <SelectboxOption
                                        key={option.value}
                                        onClick={(event) => changeValue(event, option.value)}
                                    >
                                        {option.name}
                                    </SelectboxOption>
                                ))}
                            </>
                        )}
                    </SelectboxOptionsContainer>
                )}
            </SelectboxContainer>
        </FieldLabel>
    )
}

export default memo(SelectboxField)
