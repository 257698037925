import styled from 'styled-components'

export const LeftAlignedContainer = styled.div`
    width: fit-content;
    margin-left: auto;
`

export const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`