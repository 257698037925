import React, { memo, useEffect } from 'react'
import { BlockContainer, GeneralButtonContainer, MainContainer } from './styles'
import { black } from '../../common/styles/colors'
import { RegularText } from '../../common/styles/text'
import { FormContainer } from '../../common/styles/form-styles'
import { FieldContainer } from '../../common/form/text-field/styles'
import DynamicGroupField from '../../common/form/dynamic-group-field/DynamicGroupField'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import { useDispatch, useSelector } from 'react-redux'
import {
    addCompany,
    changeCompanyValue,
    deleteCompany,
    selectCompaniesFormData,
    selectRequestStatus,
    setCompanies,
} from '../../../reducers/forms/companies-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { selectCompanies } from '../../../reducers/user'
import { NotificationManager } from 'react-notifications'
import { createCompany, updateCompany, deleteCompany as deleteCompanyThunk } from '../slice'
import { initApp } from '../../app/slice'
import { CompanyStatus } from '../../../utils/utils'

function Company() {
    const userCompanies = useSelector(selectCompanies)
    const companiesFormData = useSelector(selectCompaniesFormData)
    const requestStatus = useSelector(selectRequestStatus)

    const dispatch = useDispatch()

    useEffect(() => {
        if (userCompanies.length > 0) {
            const companies = userCompanies
                .filter(({ status }) => status === CompanyStatus.ACTIVE)
                .map(({ uuid, name }) => ({ id: uuid, value: name }))
                .sort((company1, company2) => company1.id - company2.id)
            dispatch(setCompanies(companies))
        } else {
            dispatch(setCompanies([{ id: `${Date.now()}`, value: '' }]))
        }
    }, [userCompanies])

    const handleAddCompany = () => {
        dispatch(addCompany())
    }

    const handleDeleteCompany = (id) => {
        dispatch(deleteCompany(id))
    }

    const handleUpdateCompany = (event) => {
        const id = event.target.attributes['data-id'].value
        const { value } = event.target

        dispatch(changeCompanyValue({ id, value }))
    }

    const handleSaveCompanies = async () => {
        if (companiesFormData.length === 0 && userCompanies.length === 0) return

        if (companiesFormData.length === 0) {
            await Promise.all(userCompanies.map(({ uuid }) => dispatch(deleteCompanyThunk(uuid)).unwrap()))
            NotificationManager.info(`Companies updated`)
            return
        }

        const userCompaniesIds = new Set(userCompanies.map(({ uuid }) => uuid))
        const companiesFormDataMap = companiesFormData.reduce(
            (map, { id, value }) => ({
                ...map,
                [id]: value,
            }),
            {},
        )

        const companiesToCreate = companiesFormData.filter(({ id, value }) => !userCompaniesIds.has(id) && value)

        const companiesToUpdate = userCompanies.reduce((companies, { uuid, name }) => {
            if (companiesFormDataMap[uuid] === name || !companiesFormDataMap[uuid]) return companies
            return [...companies, { id: uuid, value: companiesFormDataMap[uuid] }]
        }, [])

        const companiesToDelete = userCompanies.reduce((companies, { uuid, name, status }) => {
            if (!companiesFormDataMap[uuid] && status === CompanyStatus.ACTIVE)
                return [...companies, { id: uuid, value: name }]
            return companies
        }, [])

        if (companiesToCreate.length === 0 && companiesToUpdate.length === 0 && companiesToDelete.length === 0) return

        try {
            const operations = [
                ...(companiesToCreate.length > 0
                    ? companiesToCreate.map(({ value }) => dispatch(createCompany({ companyName: value })).unwrap())
                    : []),
                ...(companiesToUpdate.length > 0
                    ? companiesToUpdate.map(({ id, value }) =>
                          dispatch(updateCompany({ companyId: id, name: value })).unwrap(),
                      )
                    : []),
                ...(companiesToDelete.length > 0
                    ? companiesToDelete.map(({ id }) => dispatch(deleteCompanyThunk(id)).unwrap())
                    : []),
            ]

            await Promise.all(operations)
            NotificationManager.info(`Companies updated`)
            dispatch(initApp())
        } catch (err) {
            NotificationManager.error(`Companies updating failed`)
            throw err
        }
    }

    return (
        <>
            {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}

            <MainContainer>
                <BlockContainer>
                    <div>
                        <RegularText fontSize="24px" lineHeight="32px" color={black}>
                            Name
                        </RegularText>
                    </div>
                    <div>
                        <FormContainer width="55%">
                            <FieldContainer>
                                <fieldset>
                                    <DynamicGroupField
                                        fields={companiesFormData}
                                        placeholder="Enter company name"
                                        label="Enter company name"
                                        addButtonText="add company"
                                        eachFieldHasLabel={true}
                                        addFieldAction={handleAddCompany}
                                        deleteFieldAction={handleDeleteCompany}
                                        changeEventAction={handleUpdateCompany}
                                    />
                                </fieldset>
                            </FieldContainer>
                        </FormContainer>
                    </div>
                </BlockContainer>
                <GeneralButtonContainer>
                    <SimpleButton action={handleSaveCompanies} text="Save changes" />
                </GeneralButtonContainer>
            </MainContainer>
        </>
    )
}

export default memo(Company)
