import React from 'react'
import { StyledPreviewFrame, StyledShadow, StyledPreview } from './styled'

const ReadyArticlePreview = ({ lastVersion }) => {
    return (
        <StyledPreviewFrame>
            <StyledPreview dangerouslySetInnerHTML={{ __html: lastVersion }} />
            <StyledShadow />
        </StyledPreviewFrame>
    )
}

export default ReadyArticlePreview
