import styled from 'styled-components'
import { white } from '../../common/styles/colors'

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const BlockContainer = styled.div`
    display: flex;
    background-color: ${white};
    border-radius: 24px;
    padding: 16px 24px 28px 24px;
    flex-direction: column;
    margin: 20px 0;
`

export const PaymentBlockContainer = styled(BlockContainer)`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 64px;
    @media (max-width: 480px) {
        flex-direction: column;
    }
`

export const ButtonContainer = styled.div`
    margin-top: 29px;
    align-self: start;
`

export const SeparateLineContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #d5d0cd;
    margin: 24px 0;
`

export const GeneralButtonContainer = styled.div`
    display: flex;
    align-self: start;
    margin-bottom: 112px;
`

export const CurrentPlanTitleContainer = styled.div`
    margin-top: 12px;
`
export const ManageSubscriptionContainer = styled.div`
    display: flex;
    background: #f9f9f9;
    border-radius: 24px;
    align-items: center;
    width: 203px;
    margin-top: 12px;
    justify-content: space-around;
    padding: 4px 8px;
`

export const ManageSubscriptionDropdownContainer = styled.div`
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 220px;
    margin-top: 5px;
`
export const CurrentPlanDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const PlanPriceTitleContainer = styled.div`
    display: flex;
    margin-top: 5px;
    align-items: baseline;
`

export const HistoryBlockContainer = styled(BlockContainer)`
    display: flex;
    flex-direction: column;
    margin-bottom: 112px;
`
export const BillRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d5d0cd;
    &:last-child {
        padding-bottom: 4px;
        border: none;
    }
`

export const PaymentFormContainer = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 100px auto;
`
export const PromoInput = styled.div`
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 500px;
    margin-top: 24px;

    input {
        font-size: .9em;
        padding: 15px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,.1);
        box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px, rgba(0, 0, 0, 0.02) 0px 3px 6px;
    }

    label {
        margin-bottom: 10px;
        font-size: .9em;
        color: rgba(0,0,0,.8);
        display: block;
    }
`