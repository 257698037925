import styled from 'styled-components'
import { layoutComponentsBackgroundColor, layoutComponentsBorderColor, regularGray } from '../../../../styles/colors'

export const UserPopoverContainer = styled.div`
    position: absolute;
    right: 0;
    top: 63px;
    width: 240px;
    box-sizing: border-box;
    padding: 1px 0;
    border: 1px solid ${layoutComponentsBorderColor};
    background: #ffffff;
    z-index: 1;
`

export const LinkContainer = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
`

export const NavigationButton = styled.button`
    position: relative;
    width: 100%;
    height: 100%;
    background: none;
    border: none;

    color: ${regularGray};

    font-size: 14px;

    position: relative;
    padding: 0 24px;
    box-sizing: border-box;
    text-align: left;

    &:hover {
        cursor: pointer;
        background: ${layoutComponentsBackgroundColor};
    }
`
