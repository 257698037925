import { createSlice, current } from '@reduxjs/toolkit'
import { initApp } from '../components/app/slice'
import { failed } from './reducer-helper'
import { Status } from '../utils/utils'
import { googleAuth, signIn } from '../components/user/sign-in/slice'
import { confirmEmail } from '../components/user/sign-up/slice'
import {
    cancelSubscription,
    createCompany,
    refreshCredits,
    subscribeFreePlan,
    updateProfile,
} from '../components/settings/slice'
import { NotificationManager } from 'react-notifications'
import { logout } from '../components/common/layouts/private-area/header/user-popover/slice'

const initialState = {
    user: null,
    error: null,
    status: null,
    companies: [],
    tags: ['SEO', 'Digital', 'Visibility', 'Marketing', 'Optimization', 'Website', 'Organic traffic'],
}

function success(state) {
    state.status = Status.SUCCESS
    delete state.error
}

function hydrateTawkUserInfo({ profile, email }) {
    try {
        window.Tawk_API.setAttributes({
            name: `${profile.firstName}${profile.lastName ? ` ${profile.lastName}` : ''}`,
            email: email,
        })
    } catch (e) {}
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers(builder) {
        builder.addCase(initApp.fulfilled, (state, action) => {
            success(state)
            state.companies = action.payload.user.companies.filter((company) => company?.status === 'active')
            state.user = action.payload.user
            hydrateTawkUserInfo(state.user)
        })
        builder.addCase(initApp.rejected, (state, action) => {
            const BAD_TOKEN_CODES = ['API_TOKEN_NOT_FOUND_ERROR', 'HTTP_FORBIDDEN_ERROR']

            if (BAD_TOKEN_CODES.includes(action.payload?.errorCode)) {
                state.error = 'BAD_TOKEN'
            }
        })
        builder.addCase(signIn.fulfilled, (state, action) => {
            success(state)
            state.companies = action.payload.user.companies.filter((company) => company?.status === 'active')
            state.user = action.payload.user
            hydrateTawkUserInfo(state.user)
        })
        builder.addCase(createCompany.fulfilled, (state, action) => {
            success(state)
            const companies = [...current(state).companies]
            companies.push(action.payload)
            state.companies = companies
        })
        builder.addCase(signIn.rejected, failed)
        builder.addCase(googleAuth.fulfilled, (state, action) => {
            success(state)
            state.user = action.payload.user
            hydrateTawkUserInfo(state.user)
        })
        builder.addCase(googleAuth.rejected, failed)
        builder.addCase(confirmEmail.fulfilled, (state, action) => {
            success(state)
            state.user = action.payload.user
            hydrateTawkUserInfo(state.user)
        })
        builder.addCase(confirmEmail.rejected, failed)
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            success(state)
            state.user.profile = {
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                companies: state.user.profile.companies,
            }
            NotificationManager.info(`Profile updated`)
            hydrateTawkUserInfo(state.user)
        })
        builder.addCase(cancelSubscription.rejected, failed)
        builder.addCase(cancelSubscription.fulfilled, (state) => {
            success(state)
            // state.user.payment.subscriptionId = null
            NotificationManager.info(`Subscription canceled`)
        })
        builder.addCase(subscribeFreePlan.rejected, failed)
        builder.addCase(subscribeFreePlan.fulfilled, (state, action) => {
            success(state)
            state.user.payment.subscriptionId = action.payload.subscriptionId
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.user = null
        })
        builder.addCase(refreshCredits.fulfilled, (state, action) => {
            state.user.payment.credits = action.payload.user?.payment?.credits || state.user.payment.credits
        })
    },
    reducers: {
        addCredit: (state, action) => {
            state.user.payment.credits = Number(state.user.payment.credits) + Number(action.payload.creditToAdd)
        },
        changeSubscription: (state, action) => {
            state.user.payment.subscriptionId = action.payload.subscriptionId
        },
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        updateCompanyState: (state, actions) => {
            const companies = [...current(state).companies]
            const companyIndex = companies.findIndex((company) => company.uuid === actions.payload.uuid)
            const companyObject = { ...companies[companyIndex] }
            companyObject.name = actions.payload.name
            state.companies = [...companies.slice(0, companyIndex), companyObject, ...companies.slice(companyIndex + 1)]
        },
        deleteCompanyState: (state, actions) => {
            const companies = [...current(state).companies]
            const companyIndex = companies.findIndex((company) => company.uuid === actions.payload.uuid)

            companies.splice(companyIndex, 1)
            state.companies = companies
        },
        addFolders: (state, actions) => {
            const companies = [...current(state).companies]
            const companyIndex = companies.findIndex((company) => company.uuid === actions.payload.uuid)
            if (companyIndex === -1) return
            const companyObject = { ...companies[companyIndex] }
            companyObject.folders = actions.payload.folders
            state.companies = [...companies.slice(0, companyIndex), companyObject, ...companies.slice(companyIndex + 1)]
        },
        renameFolder: (state, actions) => {
            const companies = [...current(state).companies]
            const companyIndex = companies.findIndex((company) => company.uuid === actions.payload.uuid)
            const companyObject = { ...companies[companyIndex] }

            const folderIndex = companyObject.folders.findIndex((folder) => folder.uuid === actions.payload.folderUuid)

            const updatedFolders = [...companyObject.folders]
            const updatedFolderObject = { ...updatedFolders[folderIndex] }
            updatedFolderObject.name = actions.payload.folderName
            updatedFolders[folderIndex] = updatedFolderObject

            companyObject.folders = updatedFolders
            state.companies = [...companies.slice(0, companyIndex), companyObject, ...companies.slice(companyIndex + 1)]
        },
        deleteFolder: (state, actions) => {
            const companies = [...current(state).companies]
            const companyIndex = companies.findIndex((company) => company.uuid === actions.payload.uuid)
            const companyObject = { ...companies[companyIndex] }

            const folderIndex = companyObject.folders.findIndex((folder) => folder.uuid === actions.payload.folderUuid)

            const updatedFolders = [...companyObject.folders]
            updatedFolders.splice(folderIndex, 1)

            companyObject.folders = updatedFolders
            state.companies = [...companies.slice(0, companyIndex), companyObject, ...companies.slice(companyIndex + 1)]
        },
        moveArticleInFolder: (state, actions) => {
            const companies = [...current(state).companies]
            const originalCompanyIndex = companies.findIndex(
                (company) => company.uuid === actions.payload.originalCompanyUuid,
            )
            const originalCompanyObject = { ...companies[originalCompanyIndex] }
            
            // Remove it from the original folder if it was in one
            const originalFolderIndex = originalCompanyObject.folders.findIndex(
                (folder) => folder.uuid === actions.payload.originalFolderUuid,
            )
            if (originalFolderIndex > -1) {
                const updatedOriginalFolder = {
                    ...originalCompanyObject.folders[originalFolderIndex],
                    articles: originalCompanyObject.folders[originalFolderIndex].articles.filter(
                        (article) => article.uuid !== actions.payload.articleId,
                    ),
                }

                const updatedOriginalFolders = [...originalCompanyObject.folders]
                if (originalFolderIndex > -1) {
                    updatedOriginalFolders[originalFolderIndex] = updatedOriginalFolder
                }

                originalCompanyObject.folders = updatedOriginalFolders
            }

            const newCompanyIndex = companies.findIndex((company) => company.uuid === actions.payload.newCompanyUuid)
            
            // We need to reference the same object as the original if it's
            // A movement within the company. Otherwise the folders won't properly update
            const newCompanyObject = newCompanyIndex === originalCompanyIndex ? originalCompanyObject : { ...companies[newCompanyIndex] }
            
            // Add it to the original folder if one is entered
            if (actions.payload.newFolderUuid) {
                const newFolderIndex = newCompanyObject.folders.findIndex(
                    (folder) => folder.uuid === actions.payload.newFolderUuid,
                )
                const updatedNewFolder = {
                    ...newCompanyObject.folders[newFolderIndex],
                    articles: [
                        ...newCompanyObject.folders[newFolderIndex].articles,
                        Object.assign({ uuid: actions.payload.articleId }, actions.payload.article),
                    ],
                }

                const updatedNewFolders = [...newCompanyObject.folders]
                updatedNewFolders[newFolderIndex] = updatedNewFolder

                newCompanyObject.folders = updatedNewFolders
            }

            companies[originalCompanyIndex] = originalCompanyObject
            companies[newCompanyIndex] = newCompanyObject

            state.companies = companies
        },
    },
})

const { reducer, actions } = userSlice

const selectUserSlice = (state) => state.user

export const selectUserProfile = (state) => selectUserSlice(state).user?.profile
export const selectUserEmail = (state) => selectUserSlice(state).user?.email
export const selectUser = (state) => selectUserSlice(state).user
export const selectCompanies = (state) => selectUserSlice(state).user?.companies
export const selectPaymentInformation = (state) => selectUserSlice(state).user?.payment
export const selectRequestStatus = (state) => selectUserSlice(state).status

export const {
    addCredit,
    changeSubscription,
    changeRequestStatus,
    updateCompanyState,
    deleteCompanyState,
    addFolders,
    renameFolder,
    deleteFolder,
    moveArticleInFolder,
} = actions

export default reducer
