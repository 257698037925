import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    BackNavigation,
    ButtonsContainer,
    CompanyContainer,
    FoldersContainer,
    FoldersListContainer,
    FoldersListWrapper,
    OptionButton,
} from './styles'
import { H1Title, RegularText } from '../../../common/styles/text'
import { black, darkGray } from '../../../common/styles/colors'
import ShareIcon from '../../../../assets/images/share-icon.svg'
import ArrowUpIcon from '../../../../assets/images/arrow-up.svg'
import AddFolderIcon from '../../../../assets/images/add-folder.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import FolderItem from './FolderItem'
import { useDispatch, useSelector } from 'react-redux'
import useWindowDimensions from '../../../common/hooks/useWindowDimensions'
import { useUpdateValuePopup } from '../side-menu/update-value-popup/hooks/useUpdateValuePopup'
import { selectActiveFolderUuid } from '../../../../reducers/common'
import { useFolders } from '../../hooks/useFolders'

function FoldersList() {
    const companies = useSelector((state) => state.user.companies)
    const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)
    const activeFolderUuid = useSelector((state) => state.common.activeFolderUuid)
    const searchText = useSelector((state) => state.common.searchText)
    const searchTags = useSelector((state) => state.common.searchTags)
    const { currentCompanyName, getFoldersList, createFolder } = useFolders()
    const dispatch = useDispatch()
    const { isMobile } = useWindowDimensions()
    const openPopup = useUpdateValuePopup()

    const currentFolders = useMemo(
        () => companies.find((company) => company.uuid === activeCompanyUuid)?.folders || [],
        [companies, activeCompanyUuid],
    )

    useEffect(() => {
        if (!activeCompanyUuid) return
        const fetchFolders = async () => {
            await getFoldersList(activeCompanyUuid)
        }
        fetchFolders()
    }, [activeCompanyUuid])

    const foldersList = useMemo(() => {
        return currentFolders.map((folder, index) => <FolderItem key={index} folder={folder} />)
    }, [companies, activeCompanyUuid, currentFolders])

    const handleBackNavigation = useCallback(() => {
        dispatch(selectActiveFolderUuid({ activeFolderUuid: null }))
    }, [dispatch])

    const handleFolderCreation = () => {
        openPopup({
            title: 'New folder',
            subTitle: 'Folder name',
            buttonText: 'Create',
            handleClick: async (value, close) => {
                await createFolder(value, activeCompanyUuid)
                close()
            },
        })
    }

    const showExtraElements = useMemo(() => {
        if (!activeCompanyUuid || activeFolderUuid || searchText || searchTags.length > 0) {
            return false
        }
        return true
    }, [activeCompanyUuid, activeFolderUuid, searchText, searchTags])

    const mainTitle = useMemo(() => {
        if (searchText) {
            return `Search results for "${searchText}"`
        } else if (searchTags?.length) {
            return 'Search results by tag'
        } else if (activeFolderUuid) {
            return (companies
                .find((company) => company.uuid === activeCompanyUuid)
                .folders || []).find((folder) => folder.uuid === activeFolderUuid)?.name
        }
        return currentCompanyName
    }, [companies, currentCompanyName, activeFolderUuid, activeCompanyUuid, searchText, searchTags])

    return (
        <FoldersContainer margin={showExtraElements ? '1.75rem' : '0'}>
            {activeCompanyUuid && (
                <CompanyContainer>
                    <div>
                        {activeFolderUuid && (
                            <BackNavigation onClick={handleBackNavigation}>
                                <StyledSVG src={ArrowUpIcon} />
                                <RegularText fontSize="0.875rem" color={darkGray} fontWeight={400}>
                                    {currentCompanyName}
                                </RegularText>
                            </BackNavigation>
                        )}
                        <H1Title mobileFontSize="24px">{mainTitle}</H1Title>
                    </div>
                    {showExtraElements && (
                        <ButtonsContainer>
                            <OptionButton onClick={handleFolderCreation}>
                                <StyledSVG src={AddFolderIcon} />
                                {!isMobile && <RegularText>Create folder</RegularText>}
                            </OptionButton>
                            {/* <OptionButton>
                            <StyledSVG src={ShareIcon} />
                            {!isMobile && <RegularText>Share</RegularText>}
                        </OptionButton> */}
                        </ButtonsContainer>
                    )}
                </CompanyContainer>
            )}
            {showExtraElements && foldersList?.length > 0 && (
                <FoldersListWrapper>
                    <RegularText color={black}>Folders</RegularText>
                    <FoldersListContainer>{foldersList}</FoldersListContainer>
                </FoldersListWrapper>
            )}
        </FoldersContainer>
    )
}

export default memo(FoldersList)
