import React, {memo} from 'react'
import { H1Title, SubtitleText } from '../../common/styles/text'
import {
    MiddleFormContainer,
    MiddleFormSubtitleContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { useSelector } from 'react-redux'
import {
    selectSignUpFormData,
} from '../../../reducers/forms/sign-up-form'

function EmailSentConfirmation() {
    const signUpFormData = useSelector(selectSignUpFormData)

    return (
        <MiddleFormContainer marginTop="130px">
            <MiddleFormTitleContainer>
                <H1Title>Verification Sent!</H1Title>
            </MiddleFormTitleContainer>
            <MiddleFormSubtitleContainer>
                <SubtitleText>
                    We've emailed a confirmation link to <b>{signUpFormData.email}</b>! If you don't see it in a few minutes, please check your spam folder.
                </SubtitleText>
            </MiddleFormSubtitleContainer>
        </MiddleFormContainer>
    )
}

export default memo(EmailSentConfirmation)
