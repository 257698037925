import React, { memo } from 'react'
import { RegularText } from '../../common/styles/text'
import { PopupContent } from '../styles'
import { useDispatch } from 'react-redux'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { NotificationManager } from 'react-notifications'
import { ButtonContainer, ButtonsContainer, MainInformationContainer } from './styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import TransparentBorderButton from '../../common/button/transparent-border-button/TransparentBorderButton'
import { closePopup } from '../../../reducers/popup'
import { orange } from '../../common/styles/colors'
import { getArticles, humanizeArticle } from '../slice'

function HumanizeArticlePopup({ extraData }) {
    const dispatch = useDispatch()

    const humanizeArticleAction = async () => {
        dispatch(closePopup({ component: PopupComponentKeys.HUMANIZE_ARTICLE }))

        try {
            await dispatch(humanizeArticle(extraData.articleId)).unwrap()
            await dispatch(getArticles()).unwrap()
            NotificationManager.info('Article humanized.')
        } catch (err) {
            console.error(err)
            NotificationManager.error('Article humanization failed.')
        }
    }

    const closePopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.HUMANIZE_ARTICLE }))
    }

    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    Humanize Article (Experimental)
                </RegularText>
            </div>
            <MainInformationContainer>
                <RegularText lineHeight={'24px'} fontWeight={400}>
                    Would you like to attempt to humanize the article? This is experimental, and may introduce minor errors into the article. The original form of the article will still be available under history.
                </RegularText>
            </MainInformationContainer>
            <ButtonsContainer>
                <ButtonContainer>
                    <SimpleButton action={humanizeArticleAction} text="Yes" />
                </ButtonContainer>
                <ButtonContainer>
                    <TransparentBorderButton color={orange} action={closePopupAction} text="Cancel" />
                </ButtonContainer>
            </ButtonsContainer>
        </PopupContent>
    )
}

export default memo(HumanizeArticlePopup)
