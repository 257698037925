import React, { memo } from 'react'

const Media = ({ block, contentState }) => {
    let entity = contentState.getEntity(block.getEntityAt(0))
    const { src } = entity.getData()
    const type = entity.getType()

    return (
        <>
            {type === 'image' && (
                <img src={src} />
            )}
        </>
    )
}

export default memo(Media)
