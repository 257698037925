import React, { memo, useMemo, useRef, useState } from 'react'
import { ButtonContainer } from './styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import Dropdown from '../../common/dropdown/Dropdown'
import CopyTextIcon from '../../../assets/images/copy-text.svg'
import CopyHtmlIcon from '../../../assets/images/copy-html.svg'
import { NotificationManager } from 'react-notifications'

function CopyAction({ currentArticle }) {
    const wrapperRef = useRef(null)
    const [showSaveDropdown, setShowSaveDropdown] = useState(false)

    const articleHtmlWithImage = useMemo(() => {
        const imgHtml = `
            <img src="${currentArticle?.featuredPhoto?.url}" alt="${currentArticle?.featuredPhoto?.altText}" />
            ${currentArticle?.featuredPhoto?.attributionUrl ? 
                `<p font-size=".75em" color="#484543">
                    Photo provided by <a target="_blank" href="${currentArticle?.featuredPhoto?.attributionUrl}">Pexels</a>
                </p>`
                : ''}
        `;

        const [styles, mainHtml] = currentArticle?.articleHtmlContent?.split('</style>') || []

        return styles + '</style>' + imgHtml + mainHtml
    }, [currentArticle])

    const copyAsText = () => {
        // const tempElement = document.createElement('div')
        // tempElement.innerHTML = currentArticle.articleText
        // const textContent = tempElement.textContent || tempElement.innerText
        navigator.clipboard.writeText(currentArticle.articleTextContent)
        NotificationManager.info(`Text copied`)
        setShowSaveDropdown(false)
    }

    const copyAsHtml = () => {
        navigator.clipboard.writeText(articleHtmlWithImage)
        NotificationManager.info(`HTML copied`)
        setShowSaveDropdown(false)
    }

    return (
        <ButtonContainer>
            <SimpleButton
                text="Copy as"
                action={(event) => {
                    event.preventDefault()
                    setShowSaveDropdown(!showSaveDropdown)
                }}
            />
            {showSaveDropdown && (
                <Dropdown
                    wrapperRef={wrapperRef}
                    items={[
                        { name: 'Copy Text', icon: CopyTextIcon, action: copyAsText },
                        { name: 'Copy HTML', icon: CopyHtmlIcon, action: copyAsHtml },
                    ]}
                />
            )}
        </ButtonContainer>
    )
}

export default memo(CopyAction)
