import { EditorState, RichUtils } from 'draft-js'
import React, { memo } from 'react'
import EmbeddedLink from './EmbeddedLink'
import EmbeddedImage from './EmbeddedImage'
import UndoIcon from '../../../../../../assets/images/undo.svg'
import RedoIcon from '../../../../../../assets/images/redo.svg'
import BoldIcon from '../../../../../../assets/images/bold.svg'
import ItalicIcon from '../../../../../../assets/images/italic.svg'
import UnderlinedIcon from '../../../../../../assets/images/underlined.svg'
import BulletedListIcon from '../../../../../../assets/images/bulleted-list.svg'
import NumberedListIcon from '../../../../../../assets/images/numbered-list.svg'
import { RichTextEditorButton, RichTextTooltipContainer } from '../../styles'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'

function RichEditorToolbar({ editorState, setEditorState }) {
    const tools = [
        {
            label: 'bold',
            style: 'BOLD',
            icon: <StyledSVG src={BoldIcon} />,
            method: 'inline',
        },
        {
            label: 'italic',
            style: 'ITALIC',
            icon: <StyledSVG src={ItalicIcon} />,
            method: 'inline',
        },
        {
            label: 'underline',
            style: 'UNDERLINE',
            icon: <StyledSVG src={UnderlinedIcon} />,
            method: 'inline',
        },
        {
            label: 'Unordered-List',
            style: 'unordered-list-item',
            icon: <StyledSVG src={BulletedListIcon} />,
            method: 'block',
        },
        {
            label: 'Ordered-List',
            style: 'ordered-list-item',
            icon: <StyledSVG src={NumberedListIcon} />,
            method: 'block',
        },
    ]

    const applyStyle = (e, style, method) => {
        e.preventDefault()
        method === 'block'
            ? setEditorState(RichUtils.toggleBlockType(editorState, style))
            : setEditorState(RichUtils.toggleInlineStyle(editorState, style))
    }

    // const isActive = (style, method) => {
        // if (method === 'block') {
        //     const selection = editorState.getSelection()
        //     const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
        //     return blockType === style
        // } else {
        //     const currentStyle = editorState.getCurrentInlineStyle()
        //     return currentStyle.has(style)
        // }
    // }

    return (
        <RichTextTooltipContainer>
            <RichTextEditorButton onClick={() => setEditorState(EditorState.undo(editorState))}>
                <StyledSVG src={UndoIcon} />
            </RichTextEditorButton>
            <RichTextEditorButton onClick={() => setEditorState(EditorState.redo(editorState))}>
                <StyledSVG src={RedoIcon} />
            </RichTextEditorButton>
            {tools.map((item, index) => (
                <RichTextEditorButton
                    // style={{
                    //     color: isActive(item.style, item.method) ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.3)',
                    // }}
                    key={`${item.label}-${index}`}
                    title={item.label}
                    onClick={(e) => applyStyle(e, item.style, item.method)}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {item.icon}
                </RichTextEditorButton>
            ))}
            <EmbeddedLink editorState={editorState} setEditorState={setEditorState} />
            <EmbeddedImage editorState={editorState} setEditorState={setEditorState} />
        </RichTextTooltipContainer>
    )
}

export default memo(RichEditorToolbar)
