import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { FilterColseButton, FilterMenuContainer, FilterSearchField } from './styles'
import ArticlesList from './ArticlesList'
import { useSelector } from 'react-redux'
import { selectArticles } from '../../../../../reducers/articles'
import TagsList from './TagsList'
import CloseIcon from '../../../../../assets/images/close-icon.svg'
import { useClickAway } from 'react-use'
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions'
import StyledSVG from '../../../../common/styled-svg/StyledSVG'

function FilteMenu({ isOpen, setIsOpen }) {
    const [searchText, setSearchText] = useState('')
    const articles = useSelector(selectArticles)
    const tags = useSelector((state) => state.user.tags)
    const containerRef = useRef(null)
    const { isMobile } = useWindowDimensions()

    useClickAway(containerRef, () => {
        setIsOpen(false)
    }) 

    const selectedArticles = useMemo(() => {
        if (!articles) return []

        if (searchText) {
            return articles.filter((article) => (article.articleTitle || '').includes(searchText)).slice(0, 4)
        }
        return articles.filter((article) => !!article.articleTitle).slice(0, 4)
    }, [articles, searchText])

    useEffect(() => {
        const input = document.querySelector('#filter-menu-input')

        if(input) {
            input.focus()
        }
    }, [isOpen])

    return (
        <div ref={containerRef}>
            {/* <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1 },
                            collapsed: { opacity: 0 },
                        }}
                        transition={{ duration: 0}}
                    >
                        <FilterMenuContainer>
                            {isMobile && (
                                <FilterColseButton onClick={() => setIsOpen(false)}>
                                    <StyledSVG src={CloseIcon} />
                                </FilterColseButton>
                            )}
                            <FilterSearchField
                                value={searchText}
                                changeEvent={setSearchText}
                                placeholder="Search by name of article or tags"
                                id='filter-menu-input'
                            />
                            <ArticlesList articles={selectedArticles} searchText={searchText} />
                            <TagsList tags={tags} />
                        </FilterMenuContainer>
                    </motion.div>
                )}
            </AnimatePresence> */}
        </div>
    )
}

export default memo(FilteMenu)
