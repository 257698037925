import React, { memo, useMemo } from 'react'
import { PromoInput } from '../styles'
import ErrorMessage from '../../../common/error-message/ErrorMessage'
import { useSelector } from 'react-redux'
import { selectPromo } from '../../../../reducers/subscription'

function PaymentPromo({ value, onChange, onBlur }) {
    const promo = useSelector(selectPromo)

    return (
        <PromoInput>
            <label>Promo Code</label>
            <input placeholder="Promo Code" value={value} onChange={onChange} onBlur={onBlur}></input>
            <ErrorMessage error={promo.error} />
        </PromoInput>
    )
}

export default memo(PaymentPromo)
