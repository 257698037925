import { createSlice } from '@reduxjs/toolkit'
import { initApp } from '../../components/app/slice'
import { googleAuth, signIn } from '../../components/user/sign-in/slice'
import { confirmEmail } from '../../components/user/sign-up/slice'

const initialState = {
    data: {
        companyUuid: '',
        folderUuid: '',
        companyName: '',
        keywords: '',
        articleTitle: '',
        words: '',
        tone: 'instructive',
        locationId: 2840,
        callToActionTitle: '',
        callToActionLink: '',
        links: [''],
        additionalKeywords: [''],
        additionalDetails: '',
        languageCode: 'en-us',
        autoHumanize: false,
    },
    error: null,
}

const setActiveCompanyUuid = (state, action) => {
    const activeCompanies = action.payload.user.companies.filter(company => company.status === 'active')
    state.data.companyUuid = activeCompanies?.[0].uuid
}

const createArticleFormSlice = createSlice({
    name: 'forms.createArticleForm',
    initialState,
    reducers: {
        setFieldValue: (state, action) => {
            const { key, value } = action.payload

            state.data[key] = value
        },
        resetForm: (state) => Object.assign({}, initialState, {
            data: Object.assign({}, initialState.data, { companyUuid: state.data.uuid})
        })
    },
    extraReducers(builder) {
        builder.addCase(initApp.fulfilled, setActiveCompanyUuid)
        builder.addCase(signIn.fulfilled, setActiveCompanyUuid)
        builder.addCase(googleAuth.fulfilled, setActiveCompanyUuid)
        builder.addCase(confirmEmail.fulfilled, setActiveCompanyUuid)
    }
})

const selectCreateArticleFormSlice = (state) => state.forms.createArticleForm

export const selectCreateArticleFormData = (state) => selectCreateArticleFormSlice(state).data

const { reducer, actions } = createArticleFormSlice

export const { setFieldValue, resetForm } = actions

export default reducer
