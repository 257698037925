import React, { memo } from 'react'
import { H1Title } from '../../common/styles/text'
import {
    MiddleFormContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import PlanSelector from '../../credit/PlanSelector'
import PublicArea from '../../common/layouts/public-area/PublicArea'
import { ROUTES } from '../../../utils/utils'
import PrivateArea from '../../common/layouts/private-area/PrivateArea'

function PlanSelection() {
    return (
        <PrivateArea>
            <MiddleFormContainer marginTop="50px">
                <MiddleFormTitleContainer>
                    <H1Title>Select Your Plan</H1Title>
                </MiddleFormTitleContainer>
                <PlanSelector />
            </MiddleFormContainer>
        </PrivateArea>
    )
}

export default memo(PlanSelection)
