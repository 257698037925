import React, { memo, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { ButtonBlock, PaymentFormContainer } from '../../settings/styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import Loading from '../../common/loading/Loading'
import { PaymentAgent } from '../../../api/paymentAgent'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../utils/utils'
import { useDispatch } from 'react-redux'
import { addCredit } from '../../../reducers/user'
import SavePaymentMethod from '../../common/payment/SavePaymentMethod'
import SavedPaymentMethodsList from '../../common/payment/SavedPaymentMethodsList'
import PaymentTitle from './PaymentTitle'
import { tagEvent } from '../../../utils/tagEvent'

function PaymentForm({ creditAmount, price, productId }) {
    const dispatch = useDispatch()
    const elements = useElements()
    const navigate = useNavigate()
    const stripe = useStripe()

    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            return
        }

        setIsLoading(true)

        const result = await stripe.confirmPayment({ elements, redirect: 'if_required' })

        if (result.error) {
            NotificationManager.error(`Something went wrong. Please try again, or contact support if the problem persists.`)
        } else if (result.paymentIntent.status === 'succeeded') {
            // await PaymentAgent.confirmCreditPaymentIntent(result.paymentIntent.id)
            NotificationManager.info(`You’ve add ${creditAmount} credits`)
            dispatch(addCredit({ creditToAdd: creditAmount }))
            navigate(ROUTES.ARTICLES)
            tagEvent('purchase', {
                transaction_id: result.paymentIntent.id,
                currency: 'USD',
                value: price,
                items: [
                    {
                        item_id: productId,
                        item_name: `${creditAmount} credits`,
                    },
                ],
            })
        }

        setIsLoading(false)
    }

    return (
        <>
            {isLoading && <Loading />}

            <PaymentFormContainer>
                <PaymentTitle />
                <SavedPaymentMethodsList>
                    <PaymentElement id="payment-element" />
                    <SavePaymentMethod />
                </SavedPaymentMethodsList>
                <ButtonBlock>
                    <SimpleButton action={handleSubmit} text="Submit" />
                </ButtonBlock>
            </PaymentFormContainer>
        </>
    )
}

export default memo(PaymentForm)
