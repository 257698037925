import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FolderAgent } from '../../../api/folderAgent'
import { addFolders, deleteFolder, renameFolder } from '../../../reducers/user'
import { NotificationManager } from 'react-notifications'

export const useFolders = () => {
    const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)
    const companies = useSelector((state) => state.user.companies)
    const dispatch = useDispatch()

    const getFoldersListWrapper = useCallback(
        async (companyUuid = activeCompanyUuid, forceFetch = false) => {
            const savedFolders = companies.find((company) => company.uuid === companyUuid)?.folders

            if (savedFolders?.length > 0 && !forceFetch) return savedFolders

            const folders = await FolderAgent.getFoldersList(companyUuid)
            const activeFolders = folders.filter((folder) => folder.status === 'active')

            dispatch(
                addFolders({
                    uuid: companyUuid,
                    folders: activeFolders,
                }),
            )

            return activeFolders
        },
        [activeCompanyUuid, companies],
    )

    const createFolderWrapper = useCallback(
        async (folderName, companyUuid = activeCompanyUuid) => {
            try {
                const res = await FolderAgent.createFolder(companyUuid || activeCompanyUuid, { folderName })
                getFoldersListWrapper(companyUuid, true)
                NotificationManager.info('Folder created')
                return res
            } catch (err) {
                NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
                console.log(err)
            }
        },
        [activeCompanyUuid],
    )

    const renameFolderWrapper = useCallback(
        async (folderName, folderUuid, companyUuid = activeCompanyUuid) => {
            try {
                const res = await FolderAgent.renameFolder(companyUuid, folderUuid, { folderName })
                dispatch(
                    renameFolder({
                        uuid: companyUuid,
                        folderUuid,
                        folderName,
                    }),
                )
                NotificationManager.info('Folder renamed')
                return res
            } catch (err) {
                NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
                console.log(err)
            }
        },
        [activeCompanyUuid],
    )

    const deleteFolderWrapper = useCallback(
        async (folderUuid, companyUuid = activeCompanyUuid) => {
            try {
                const res = await FolderAgent.deleteFolder(companyUuid, folderUuid)

                dispatch(
                    deleteFolder({
                        uuid: companyUuid,
                        folderUuid: folderUuid,
                    }),
                )
                NotificationManager.info('Folder deleted')
                return res
            } catch (err) {
                NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
                console.log(err)
            }
        },
        [activeCompanyUuid],
    )

    const currentCompanyName = useMemo(() => {
        return companies.find((company) => company.uuid === activeCompanyUuid)?.name
    }, [companies, activeCompanyUuid])

    const getArticlesInFolder = useCallback(
        (folderUuid) => {
            for (let company of companies) {
                if (company.folders?.length) {
                    for (let folder of company.folders) {
                        if (folder?.uuid === folderUuid) {
                            return folder?.articles || []
                        }
                    }
                }
            }

            return []
        },
        [companies],
    )

    return {
        getFoldersList: getFoldersListWrapper,
        createFolder: createFolderWrapper,
        renameFolder: renameFolderWrapper,
        deleteFolder: deleteFolderWrapper,
        currentCompanyName,
        getArticlesInFolder,
    }
}
