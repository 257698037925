import styled from 'styled-components'

export const StyledPreviewFrame = styled.div`
    height: 12.5rem;
    overflow: hidden;
`

export const StyledShadow = styled.div`
    background: linear-gradient(180deg, rgba(239, 235, 232, 0) 0%, rgb(248.63, 246.26, 244.48) 100%);
    height: 12.5rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 16.25rem;
`

export const StyledPreview = styled.div`
    padding: 0.938rem;
    font-size: 0.275rem;
    font-family: 'Inter';
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0.413rem;

    h1 {
        font-family: 'Inter';
        font-size: 0.688rem;
        font-weight: 600;
        line-height: 0.825rem;
    }

    h2 {
        font-family: 'Inter';
        font-size: 0.413rem;
        margin-top: 0.625rem;
        font-weight: 600;
        line-height: 0.688rem;
    }

    p {
        font-family: 'Inter';
        margin-top: 0.313rem;

        @media screen and (max-width: 480px) {
            font-size: 9px;
        }
    }

    @media screen and (max-width: 480px) {
        line-height: 16px;
    }
`

export const ArticlePreviewImage = styled.img`
    height: 12.5rem;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
`
