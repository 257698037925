import styled from 'styled-components'
import { superLightSilver } from '../../../../styles/colors'

export const UserBlockContainer = styled.div`
    position: relative;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: baseline;
`

export const UserNameContainer = styled.div`
    position: relative;
    height: 100%;
    margin-left: 8px;

    @media (max-width: 480px) {
        display: none;
    }
`

export const ArrowContainer = styled.div`
    position: relative;
    height: 100%;
    margin-left: 8px;
`

export const IconContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 8px;
`

export const UserImageContainer = styled.div`
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: 24px;
    background: ${superLightSilver};
    text-align: center;

    display: flex;
    box-sizing: border-box;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
`
