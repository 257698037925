import styled from 'styled-components'
import { ButtonComponent, ButtonText } from '../styles'
import { orange } from '../../styles/colors'

export const ButtonIcon = styled.img`
    margin-right: 8px;
`

export const TransparentBorderButtonComponent = styled(ButtonComponent)`
    position: relative;
    display: flex;
    height: 48px;
    width: inherit;
    background: transparent;
    justify-content: center;
    padding: 12px 24px;
    box-sizing: border-box;
    align-items: center;
    margin: 0 auto;
    border: 1px solid;
    border-color: ${(props) => props.color};
    cursor: pointer;

    border-radius: 32px;

    &:hover {
        border-color: ${orange};
        background: white;
        color: ${orange};

        & ${ButtonText} {
            color: ${orange};
        }

        & svg {
            & path {
                fill: ${orange} !important;
            }
        }
    }

    &:disabled {
        border-color: ${(props) => props.color};
        background: transparent;
        & ${ButtonText} {
            color: ${(props) => props.color};
        }
    }
`
