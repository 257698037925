import React, { memo, useEffect, useRef } from 'react'
import { TextareaInput } from './styles'
import { FieldLabel } from '../styles'

const TextareaField = ({ label, value, changeEvent, placeholder, name = '', disabled = false, type = 'text' }) => {
    const textareaRef = useRef(null)

    useEffect(() => {
        adjustTextareaHeight()
    }, [value])

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current
        textarea.style.height = 'auto'
        textarea.style.height = '48px'
        textarea.style.height = `${textarea.scrollHeight}px`
    }

    return (
        <FieldLabel>
            {label}
            <TextareaInput
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                ref={textareaRef}
                value={value}
                onChange={changeEvent}
                name={name}
            />
        </FieldLabel>
    )
}

export default memo(TextareaField)
