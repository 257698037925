import { useDispatch, useSelector } from "react-redux";
import { selectArticleLanguages } from "../../../reducers/articles";
import { getLanguages } from "../../article/slice";
import { useEffect } from "react";

export const useArticleLanguages = () => {
  const dispatch = useDispatch()
  const articleLanguages = useSelector(selectArticleLanguages)
  useEffect(() => {
    if(!articleLanguages.length) {
        dispatch(getLanguages())
    }
  }, [])

  return articleLanguages
};
