import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES, resetStatus } from './reducer-helper'
import { paymentHistoryList } from '../components/settings/slice'
import { formatFullDate, formatShortDateWithYear } from '../utils/utils'

const initialState = {
    payments: [],
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const paymentHistorySlice = createSlice({
    name: 'paymentHistory',
    initialState,
    extraReducers(builder) {
        builder.addCase(paymentHistoryList.fulfilled, (state, action) => {
            state.payments = action.payload.map((payment) => {
                return Object.assign({}, payment, { createdDate: formatShortDateWithYear(payment.created*1000) })
            }).sort((a,b) => {
                return b.created - a.created
            })
            resetStatus(state)
        })
    },
    reducers: {
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
    },
})

const { reducer, actions } = paymentHistorySlice

const selectPaymentHistorySlice = (state) => state.paymentHistory

export const selectPaymentsList = (state) => selectPaymentHistorySlice(state).payments
export const selectRequestStatus = (state) => selectPaymentHistorySlice(state).status

export const { changeRequestStatus } = actions

export default reducer
