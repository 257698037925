import React, { memo, useCallback, useMemo } from 'react'
import { RegularText } from '../../../common/styles/text'
import { TabContainer, TabUnderline, TabsContainer } from './styles'
import { imageSelectionTabs } from './utils'
import StyledSVG from '../../../common/styled-svg/StyledSVG'

function Tabs({ activeTab, setActiveTab }) {
    const getIsActive = useCallback(
        (tab) => {
            return activeTab === tab.key
        },
        [activeTab],
    )

    const activeTabIndex = useMemo(() => {
        return imageSelectionTabs.findIndex((tab) => tab.key === activeTab)
    }, [activeTab])

    const underlineOffset = useMemo(() => {
        return (100 / imageSelectionTabs.length) * activeTabIndex
    }, [activeTabIndex])

    return (
        <TabsContainer>
            {imageSelectionTabs.map((tab) => (
                <TabContainer key={tab.key} onClick={() => setActiveTab(tab.key)} active={getIsActive(tab)}>
                    <StyledSVG src={tab.icon} />
                    <RegularText lineHeight={'24px'}>{tab.title}</RegularText>
                </TabContainer>
            ))}
            <TabUnderline offset={underlineOffset} />
        </TabsContainer>
    )
}

export default memo(Tabs)
