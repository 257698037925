import React, { memo, useEffect, useRef, useState } from 'react'
import {
    CurrentPlanDetailsContainer,
    PlanPriceTitleContainer,
    CurrentPlanTitleContainer,
    MainContainer,
    ManageSubscriptionContainer,
    PaymentBlockContainer,
    ManageSubscriptionDropdownContainer,
    BillRowContainer,
    HistoryBlockContainer,
} from './styles'
import { RegularText } from '../../common/styles/text'
import { black, naturalGray } from '../../common/styles/colors'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import SettingsIcon from '../../../assets/images/settings.svg'
import Dropdown from '../../common/dropdown/Dropdown'
import {
    selectCurrentSubscription,
    selectRequestStatus as selectSubscriptionRequestStatus,
} from '../../../reducers/subscription'
import {
    changeRequestStatus as changeUserRequestStatus,
    selectRequestStatus as selectUserRequestStatus,
} from '../../../reducers/user'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { useNavigate } from 'react-router'
import { selectPaymentInformation } from '../../../reducers/user'
import { NotificationManager } from 'react-notifications'
import { showPopup } from '../../../reducers/popup'
import { PopupComponentKeys } from '../../common/popup/Popup'
import PlanSelector from '../../credit/PlanSelector'
import { useCurrentSubscriptionProduct } from '../../common/hooks'
import { paymentHistoryList } from '../slice'
import { selectPaymentsList } from '../../../reducers/payment-history'
import { formatShortDateWithYear } from '../../../utils/utils'

function PlanAndBilling() {
    const wrapperRef = useRef(null)
    const dispatch = useDispatch()
    const subscriptionRequestStatus = useSelector(selectSubscriptionRequestStatus)
    const userRequestStatus = useSelector(selectUserRequestStatus)
    const payment = useSelector(selectPaymentInformation)
    const currentSubscription = useSelector(selectCurrentSubscription)
    const paymentsList = useSelector(selectPaymentsList)
    const currentSubscriptionProduct = useCurrentSubscriptionProduct()

    const [showManageDropdown, setManageDropdown] = useState(false)

    const cancelSubscriptionAction = async () => {
        if (!currentSubscriptionProduct || currentSubscriptionProduct.metadata.free === "true") {
            NotificationManager.error("You don't have any subscription to cancel.")
            setManageDropdown(false)
        } else if(currentSubscription?.cancel_at) {
            NotificationManager.error("Your subscription has already been canceled and will expire at the end of the subscription cycle.")
            setManageDropdown(false)
        } else {
            dispatch(showPopup({ component: PopupComponentKeys.CANCEL_SUBSCRIPTION }))
            setManageDropdown(false)
        }
    }

    useEffect(() => {
        dispatch(paymentHistoryList())
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setManageDropdown(false)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    const [subscriptionPrice, subscriptionPeriod] = (currentSubscriptionProduct?.description || '').split(' per ');

    let cancelationElement = '';
    if(currentSubscription?.cancel_at) {
        cancelationElement = (<RegularText lineHeight={'24px'} fontWeight={400}>
            Expires on {formatShortDateWithYear(currentSubscription.cancel_at*1000)}
        </RegularText>)
    } else if(currentSubscription?.canceled_at) {
        cancelationElement = (<RegularText lineHeight={'24px'} fontWeight={400}>
            Expired on {formatShortDateWithYear(currentSubscription.canceled_at*1000)}
        </RegularText>)
    } else if(currentSubscription?.current_period_end) {
        cancelationElement = (<RegularText lineHeight={'24px'} fontWeight={400}>
            Renews on {formatShortDateWithYear(currentSubscription.current_period_end*1000)}
        </RegularText>)
    }

    return (
        <>
            {(userRequestStatus === REQUEST_STATUSES.PENDING ||
                subscriptionRequestStatus === REQUEST_STATUSES.PENDING) && <Loading />}
            <MainContainer>
                <PaymentBlockContainer>
                    <div>
                        <RegularText
                            letterSpacing={'0.04em'}
                            fontWeight={400}
                            fontSize={'14px'}
                            lineHeight={'20px'}
                            color={naturalGray}
                        >
                            CURRENT PLAN
                        </RegularText>
                        {payment?.subscriptionId !== null && (
                            <CurrentPlanTitleContainer>
                                <RegularText fontSize={'40px'} lineHeight={'40px'} color={black}>
                                    {currentSubscriptionProduct && currentSubscriptionProduct.name}
                                </RegularText>
                            </CurrentPlanTitleContainer>
                        )}
                        <ManageSubscriptionContainer
                            onClick={(event) => {
                                event.preventDefault()
                                setManageDropdown(!showManageDropdown)
                            }}
                        >
                            <StyledSVG src={SettingsIcon} />
                            <RegularText lineHeight={'24px'} fontWeight={400} cursor={"pointer"}>
                                Manage Subscription
                            </RegularText>
                        </ManageSubscriptionContainer>
                        {showManageDropdown && (
                            <ManageSubscriptionDropdownContainer>
                                <Dropdown
                                    alignText="left"
                                    wrapperRef={wrapperRef}
                                    items={[
                                        // { name: 'Payment Information' },
                                        { name: 'Cancel Subscription', action: cancelSubscriptionAction },
                                    ]}
                                />
                            </ManageSubscriptionDropdownContainer>
                        )}
                    </div>
                    <CurrentPlanDetailsContainer>
                        {payment?.subscriptionId !== null && (
                            <>
                                <PlanPriceTitleContainer>
                                    <RegularText fontSize={'40px'} lineHeight={'48px'} color={black}>
                                        {subscriptionPrice}&nbsp;
                                    </RegularText>
                                    {subscriptionPeriod ? <RegularText lineHeight={'24px'} fontWeight={400}>
                                        per {subscriptionPeriod}
                                    </RegularText> : ''}
                                </PlanPriceTitleContainer>
                                {cancelationElement}
                            </>
                        )}
                    </CurrentPlanDetailsContainer>
                </PaymentBlockContainer>
                <RegularText fontSize={'32px'}>Upgrade your plan</RegularText>
                <PlanSelector />
                <RegularText fontSize={'32px'}>Billing History</RegularText>
                <HistoryBlockContainer>
                    {paymentsList?.map(payment => (
                        <BillRowContainer key={payment.id}>
                            <RegularText lineHeight={'24px'} fontWeight={400}>
                                {payment.createdDate}
                            </RegularText>
                            <RegularText lineHeight={'24px'} fontWeight={400}>
                                {payment.description || 'Purchase'}
                            </RegularText>
                            <RegularText lineHeight={'24px'} fontWeight={400}>
                                ${payment.amount/100}
                            </RegularText>
                            {/* <RegularText lineHeight={'24px'} fontWeight={400}>
                                View details
                            </RegularText> */}
                        </BillRowContainer>
                    ))}
                </HistoryBlockContainer>
            </MainContainer>
        </>
    )
}

export default memo(PlanAndBilling)
