import React, { memo } from 'react'
import { RegularText } from '../../common/styles/text'
import { PopupContent } from '../styles'
import { useDispatch } from 'react-redux'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { NotificationManager } from 'react-notifications'
import { ButtonContainer, ButtonsContainer, MainInformationContainer } from './styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import TransparentBorderButton from '../../common/button/transparent-border-button/TransparentBorderButton'
import { closePopup } from '../../../reducers/popup'
import { orange } from '../../common/styles/colors'
import { cancelSubscription } from '../../settings/slice'
import {
    changeRequestStatus as changeUserRequestStatus,
} from '../../../reducers/user'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'

function CancelSubscriptionPopup() {
    const dispatch = useDispatch()

    const cancelSubscriptionAction = async () => {
        dispatch(closePopup({ component: PopupComponentKeys.CANCEL_SUBSCRIPTION }))
        dispatch(changeUserRequestStatus({ status: REQUEST_STATUSES.PENDING }))
        try {
            dispatch(cancelSubscription())
            NotificationManager.info('Subscription canceled')
        } catch (err) {
            NotificationManager.error('Failed to cancel subscription. Please contact support.')
        }
    }

    const closePopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.CANCEL_SUBSCRIPTION }))
    }

    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    We're Sorry to See You Go
                </RegularText>
            </div>
            <MainInformationContainer>
                <RegularText lineHeight={'24px'} fontWeight={400}>
                    Are you sure you want to cancel your subscription?
                </RegularText>
            </MainInformationContainer>
            <ButtonsContainer>
                <ButtonContainer>
                    <TransparentBorderButton color={orange} action={cancelSubscriptionAction} text="Yes, Cancel" />
                </ButtonContainer>
                <ButtonContainer>
                    <SimpleButton action={closePopupAction} text="Keep Subscription" />
                </ButtonContainer>
            </ButtonsContainer>
        </PopupContent>
    )
}

export default memo(CancelSubscriptionPopup)
