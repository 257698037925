import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { memo } from 'react'
import SignIn from '../user/sign-in/SignIn'
import SignUp from '../user/sign-up/SignUp'
import ForgotPassword from '../user/forgot-password/ForgotPassword'
import ArticleList from '../article/list/ArticleList'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '../common/popup/Popup'
import Settings from '../settings/Settings'
import ArticleView from '../article/view/ArticleView'
import { NotificationContainer } from 'react-notifications'
import Credit from '../credit/Credit'
import ProtectedRoute from '../credit/ProtectedRoute'
import { useNavigate } from 'react-router'
import { clearRedirect } from '../../reducers/common'
import { initApp } from './slice'
import agent from '../../api/agent'
import Loading from '../common/loading/Loading'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_REDIRECT_URL, GOOGLE_CLIENT_ID, RECAPTCHA_KEY } from '../../configs'
import { ROUTES } from '../../utils/utils'
import CreditCheckoutStripe from '../credit/stripe/CheckoutStripe'
import SubscriptionCheckoutStripe from '../settings/pages/stripe/CheckoutStripe'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PlanSelection from '../user/sign-up/PlanSelection'
import ArticleOutline from '../article/view/ArticleOutline'
import { logout } from '../common/layouts/private-area/header/user-popover/slice'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const showPopup = useSelector((state) => state.popup.showPopup)
    const redirectTo = useSelector((state) => state.common.redirectTo)
    const appLoaded = useSelector((state) => state.common.appLoaded)
    const userError = useSelector((state) => state.user.error)

    useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo)
            dispatch(clearRedirect())
        }
    }, [redirectTo])

    useEffect(() => {
        if (userError === 'BAD_TOKEN') {
            dispatch(logout())
        }
    }, [userError])

    useEffect(() => {
        const token = window.localStorage.getItem('api-token')
        agent.setApiToken(token)
        dispatch(initApp())
    }, [])

    useEffect(() => {
        if (location.pathname !== ROUTES.MAIN) return

        const token = window.localStorage.getItem('api-token')
        const redirectTo = token ? ROUTES.ARTICLES : ROUTES.SIGN_IN

        navigate(redirectTo)
    }, [])

    if (!appLoaded) {
        return <Loading />
    }

    return (
        <>
            {showPopup && <Popup />}
            <NotificationContainer />
            <Suspense fallback={<p>Loading...</p>}>
                <Routes>
                    <Route
                        exact
                        path={ROUTES.SIGN_IN}
                        element={
                            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                                <GoogleOAuthProvider
                                    auto_select={false}
                                    redirect_uri={GOOGLE_REDIRECT_URL}
                                    clientId={GOOGLE_CLIENT_ID}
                                >
                                    <SignIn />
                                </GoogleOAuthProvider>
                            </GoogleReCaptchaProvider>
                        }
                    />
                    <Route
                        exact
                        path={ROUTES.SIGN_UP}
                        element={
                            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                                <GoogleOAuthProvider
                                    auto_select={false}
                                    redirect_uri={GOOGLE_REDIRECT_URL}
                                    clientId={GOOGLE_CLIENT_ID}
                                >
                                    <SignUp />
                                </GoogleOAuthProvider>
                            </GoogleReCaptchaProvider>
                        }
                    />
                    <Route
                        exact
                        path={ROUTES.SELECT_SUBSCRIPTION}
                        element={
                            <ProtectedRoute>
                                <PlanSelection />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        exact
                        path={ROUTES.FORGOT_PASSWORD}
                        element={
                            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                                <ForgotPassword />
                            </GoogleReCaptchaProvider>
                        }
                    />

                    <Route
                        exact
                        path={ROUTES.ARTICLES}
                        element={
                            <ProtectedRoute>
                                <ArticleList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.ARTICLE}
                        element={
                            <ProtectedRoute>
                                <ArticleView />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.ARTICLE_OUTLINE}
                        element={
                            <ProtectedRoute>
                                <ArticleOutline />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.SETTINGS}
                        element={
                            <ProtectedRoute>
                                <Settings />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.CREDIT}
                        element={
                            <ProtectedRoute>
                                <Credit />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.CREDIT_CHECKOUT_STRIPE}
                        element={
                            <ProtectedRoute>
                                <CreditCheckoutStripe />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={ROUTES.SUBSCRIPTION_CHECKOUT_STRIPE}
                        element={
                            <ProtectedRoute>
                                <SubscriptionCheckoutStripe />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </Suspense>
        </>
    )
}

export default memo(App)
