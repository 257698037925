import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES, resetStatus } from './reducer-helper'
import { creditPacksProductsList } from '../components/credit/slice'

const initialState = {
    availableProducts: [],
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const creditPacksSlice = createSlice({
    name: 'creditPacks',
    initialState,
    extraReducers(builder) {
        builder.addCase(creditPacksProductsList.fulfilled, (state, action) => {
            action.payload.sort((a, b) => {
                return a.metadata.credits - b.metadata.credits
            })
            state.availableProducts = action.payload
            resetStatus(state)
        })
    },
    reducers: {
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
    },
})

const { reducer, actions } = creditPacksSlice

const selectCreditPacksSlice = (state) => state.creditPacks

export const selectAvailableProducts = (state) => selectCreditPacksSlice(state).availableProducts
export const selectRequestStatus = (state) => selectCreditPacksSlice(state).status

export const { changeRequestStatus } = actions

export default reducer
