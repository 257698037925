import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup } from '../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../common/popup/Popup'
import { RegularText } from '../../../../common/styles/text'
import { black, white } from '../../../../common/styles/colors'
import { CustomButton, EditField, EditPopupContainer } from './styles'
import CompanyFolderDropdown from '../../CompanyFolderDropdown'

function MoveArticlePopup({ extraData }) {
    const dispatch = useDispatch()
    const [selectedCompanyUuid, setSelectedCompanyUuid] = useState(null)
    const [selectedFolderUuid, setSelectedFolderUuid] = useState(null)
    
    // This is necessary to ensure the dropdown's default value is tracked in the state
    const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)
    useEffect(() => {
        setSelectedCompanyUuid(activeCompanyUuid)
    }, [activeCompanyUuid])
    const activeFolderUuid = useSelector((state) => state.common.activeFolderUuid)
    useEffect(() => {
        setSelectedFolderUuid(activeFolderUuid)
    }, [activeFolderUuid])
    
    const closePopupAction = useCallback(() => {
        const close = () => dispatch(closePopup({ component: PopupComponentKeys.MOVE_ARTICLE }))
        if (extraData.handleClick) {
            extraData.handleClick({ companyUuid: selectedCompanyUuid, folderUuid: selectedFolderUuid }, close)
        } else {
            close()
        }
    }, [selectedCompanyUuid, selectedFolderUuid])

    const handleValueSelection = useCallback((key, uuid) => {
        if (key === 'companyUuid') {
            setSelectedCompanyUuid(uuid)
        } else {
            setSelectedFolderUuid(uuid)
        }
    }, [])

    return (
        <EditPopupContainer>
            <RegularText fontSize="24px" color={black}>
                {extraData.title}
            </RegularText>
            <div>
                <RegularText>{extraData.subTitle}</RegularText>
                <CompanyFolderDropdown
                    withoutTitle
                    handleSelectCompany={handleValueSelection}
                    companyUuid={selectedCompanyUuid}
                    folderUuid={selectedFolderUuid}
                />
            </div>
            <CustomButton onClick={closePopupAction}>
                <RegularText color={white}>{extraData.buttonText}</RegularText>
            </CustomButton>
        </EditPopupContainer>
    )
}

export default memo(MoveArticlePopup)
