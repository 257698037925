import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    companies: [], // { id: string, value: string }[]
}

const companiesSlice = createSlice({
    name: 'forms.companiesForm',
    initialState,
    reducers: {
        setCompanies: (state, action) => {
            state.companies = action.payload
        },
        addCompany: (state) => {
            state.companies.push({ id: `${Date.now()}`, value: '' })
        },
        changeCompanyValue: (state, action) => {
            const { id: companyId, value } = action.payload
            const index = state.companies.findIndex(({ id }) => id === companyId)

            if (index > -1) {
                state.companies[index].value = value
            }
        },
        deleteCompany: (state, action) => {
            const companyId = action.payload
            const index = state.companies.findIndex(({ id }) => id === companyId)

            if (index > -1) {
                state.companies.splice(index, 1)
            }
        },
    },
})

const selectCompaniesFormSlice = (state) => state.forms.companiesForm
export const selectCompaniesFormData = (state) => selectCompaniesFormSlice(state).companies
export const selectRequestStatus = (state) => selectCompaniesFormSlice(state).status
export const selectGlobalError = (state) => selectCompaniesFormSlice(state).globalError

const { reducer, actions } = companiesSlice

export const { setCompanies, addCompany, changeCompanyValue, deleteCompany } = actions

export default reducer
