import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, selectStep, changeRequestStatus, changeStep } from '../../../reducers/forms/sign-up-form'
import { useNavigate } from 'react-router'
import PublicArea from '../../common/layouts/public-area/PublicArea'
import { useLocation } from 'react-router-dom'
import { confirmEmail } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { ROUTES } from '../../../utils/utils'
import { selectRequestStatus } from '../../../reducers/forms/sign-up-form'
import Loading from '../../common/loading/Loading'
import EmailSentConfirmation from './EmailSentConfirmation'
import SinglePageSignUpForm from './SinglePageSignUpForm'

function SignUp() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const step = useSelector(selectStep)
    const search = useLocation().search
    const requestStatus = useSelector(selectRequestStatus)

    const confirmEmailToken = new URLSearchParams(search).get('confirmEmailToken')

    const syncHashAndStep = () => {
        const hashStep = Number.parseInt((window.location.hash || '1').replace('#step-', ''));
        if (!Number.isNaN(hashStep) && hashStep > 0 && hashStep <= 7) {
            dispatch(changeStep(hashStep));
        }
    }

    useEffect(() => {
        window.addEventListener("hashchange", syncHashAndStep, false);
        window.addEventListener("popstate", syncHashAndStep, false);
        window.addEventListener("pushstate", syncHashAndStep, false);
        return () => {
            dispatch(resetForm())
            window.removeEventListener("hashchange", syncHashAndStep)
            window.removeEventListener("popstate", syncHashAndStep)
            window.removeEventListener("pushstate", syncHashAndStep)
        }
    }, [])

    useEffect(() => {
        const hash = `#step-${step || 1}`;
        if(window.location.hash !== hash) {
            window.location.hash = hash;
        }
    }, [step]);

    useEffect(() => {
        if (confirmEmailToken !== null) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(confirmEmail({ confirmEmailToken: confirmEmailToken }))
        }
    }, [confirmEmailToken])

    return (
        <>
            {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
            <PublicArea headerRightButtonTitle="Sign in" headerRightButtonAction={() => navigate(ROUTES.SIGN_IN)}>
                {step === 1 && <SinglePageSignUpForm />}
                {step === 2 && <EmailSentConfirmation />}
            </PublicArea>
        </>
    )
}

export default memo(SignUp)
