import React, { memo } from 'react'
import { ArticleDesktopPathContainer, ArticleMobilePathContainer, ArticlePathContainer } from './styles'
import { RegularText } from '../../common/styles/text'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import ArrowDown from '../../../assets/images/arrow-down.svg'
import { darkGray } from '../../common/styles/colors'
import { useNavigate } from 'react-router'

function ArticlePath({ articleTitle }) {
    const navigate = useNavigate()

    return (
        <>
            <ArticleDesktopPathContainer>
                <ArticlePathContainer>
                    <RegularText fontWeight={400} color={darkGray}>
                        Company name
                    </RegularText>
                    <StyledSVG src={ArrowDown} />
                    <RegularText fontWeight={400} color={darkGray}>
                        Folder name
                    </RegularText>
                    <StyledSVG src={ArrowDown} />
                    <RegularText fontWeight={700} color={darkGray}>
                        {articleTitle}
                    </RegularText>
                </ArticlePathContainer>
            </ArticleDesktopPathContainer>
            <ArticleMobilePathContainer>
                <ArticlePathContainer onClick={() => navigate(-1)}>
                    <StyledSVG src={ArrowDown} />
                    <RegularText fontWeight={400} color={darkGray}>
                        Folder name
                    </RegularText>
                </ArticlePathContainer>
            </ArticleMobilePathContainer>
        </>
    )
}

export default memo(ArticlePath)
