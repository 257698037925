import React, { memo } from 'react'
import { ArticlePriceContainer, SubmitArticleButtonComponent } from './styles'
import { ButtonText } from '../../../common/button/styles'
import { white } from '../../../common/styles/colors'
import { RegularText } from '../../../common/styles/text'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import DatabaseIcon from '../../../../assets/images/database.svg'

const SubmitArticleButton = ({ text, action, price }) => {
    return (
        <SubmitArticleButtonComponent onClick={action} type="button">
            <ArticlePriceContainer>
                <RegularText fontWeight={400} color={white}>
                    Price:&nbsp;
                </RegularText>
                <StyledSVG height={12} width={12} src={DatabaseIcon} />
                <RegularText fontWeight={400} color={white}>
                    &nbsp; {price}
                </RegularText>
            </ArticlePriceContainer>
            <ButtonText>{text}</ButtonText>
        </SubmitArticleButtonComponent>
    )
}

export default memo(SubmitArticleButton)
