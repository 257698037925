import React, { memo, useCallback, useState } from 'react'
import { PopupContent } from '../../styles'
import Wrapper from '../../../common/layouts/Wrapper/Wrapper'
import { Center, LeftAlignedContainer } from '../../../common/layouts/layoutComponents'
import TransparentBorderButton from '../../../common/button/transparent-border-button/TransparentBorderButton'
import SimpleButton from '../../../common/button/simple-button/SimpleButton'
import { ArrowContainer, ImageAttributionContainer, MainImage } from './styles'
import Stack from '../../../common/layouts/Stack/Stack'
import { darkGray, orange } from '../../../common/styles/colors'
import { useDispatch } from 'react-redux'
import { closePopup } from '../../../../reducers/popup'
import { PopupComponentKeys } from '../../../common/popup/Popup'
import ArrowRight from '../../../../assets/images/arrow-right.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { RegularText } from '../../../common/styles/text'

function ImagePreviewPopup({ extraData }) {
    const [selectedImage, setSelectedImage] = useState(extraData.image)
    const dispatch = useDispatch()

    const closePopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.IMAGE_PREVIEW }))
    }

    const handleImageChange = useCallback(
        (imageShift) => {
            const currentImageIndex = extraData.imageSet.findIndex((image) => image.url === selectedImage.url)
            const updatedImageIndex = Math.min(
                Math.max(currentImageIndex + imageShift, 0),
                extraData.imageSet.length - 1,
            )

            setSelectedImage(extraData.imageSet[updatedImageIndex])
        },
        [selectedImage, extraData],
    )

    const handleImageUpdate = useCallback(() => {
        extraData.onSelect(selectedImage)
        dispatch(closePopup({ component: PopupComponentKeys.IMAGE_PREVIEW }))
    }, [selectedImage, extraData])

    return (
        <PopupContent>
            <Wrapper mt="24px">
                <Center>
                    <MainImage src={selectedImage.url} alt={selectedImage.altText} />
                </Center>
            </Wrapper>
            <ImageAttributionContainer>
                <RegularText constantSize fontSize="14px" color={darkGray}>
                    Photo provided by{' '}
                    <a target="_blank" href={selectedImage.attributionUrl}>
                        Pexels
                    </a>
                </RegularText>
            </ImageAttributionContainer>
            <Wrapper mt="32px">
                <LeftAlignedContainer>
                    <Stack spacing="12px">
                        <TransparentBorderButton text="Close" color={orange} action={closePopupAction} />
                        <SimpleButton text="Choose" action={handleImageUpdate} />
                    </Stack>
                </LeftAlignedContainer>
            </Wrapper>
            <ArrowContainer onClick={() => handleImageChange(1)}>
                <StyledSVG src={ArrowRight} />
            </ArrowContainer>
            <ArrowContainer reversed onClick={() => handleImageChange(-1)}>
                <StyledSVG src={ArrowRight} />
            </ArrowContainer>
        </PopupContent>
    )
}

export default memo(ImagePreviewPopup)
