import styled from 'styled-components'
import { ButtonComponent } from '../../../common/button/styles'

export const SubmitArticleButtonComponent = styled(ButtonComponent)`
    padding: 4px;
    align-items: center;
    height: 56px;
    border-radius: 32px;
    justify-content: start;
`

export const ArticlePriceContainer = styled.div`
    display: flex;
    align-content: start;
    align-self: center;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 32px;
    height: 100%;
    width: 50%;
    padding: 0 4px;
    margin-right: 16px;
`
