import React, { memo } from 'react'

const Link = ({ entityKey, contentState, children }) => {
    let { url } = contentState.getEntity(entityKey).getData();

    return (
        <a
            style={{ color: "blue", fontStyle: "italic" }}
            href={url}
            target="_blank"
            onClick={() => {window.location = url}}
        >
            {children}
        </a>
    );
};

export default memo(Link)