import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    ContentContainer,
    DropzoneContainer,
    DropzoneWrapper,
    HiglightedText,
    PreviewContainer,
    PreviewImage,
    TextContainer,
} from './styles'
import Dropzone from 'react-dropzone'
import { RegularText } from '../../../common/styles/text'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import UploadIcon from '../../../../assets/images/upload-round.svg'
import CloseIcon from '../../../../assets/images/close-icon.svg'
import { darkBlack, naturalGray, orange, redOrange } from '../../../common/styles/colors'
import Wrapper from '../../../common/layouts/Wrapper/Wrapper'
import SimpleButton from '../../../common/button/simple-button/SimpleButton'
import TransparentBorderButton from '../../../common/button/transparent-border-button/TransparentBorderButton'
import Stack from '../../../common/layouts/Stack/Stack'
import { useDispatch } from 'react-redux'
import { closePopup } from '../../../../reducers/popup'
import { PopupComponentKeys } from '../../../common/popup/Popup'
import useWindowDimensions from '../../../common/hooks/useWindowDimensions'
import { Center, LeftAlignedContainer } from '../../../common/layouts/layoutComponents'

function UploadedImageSelection({ onSelect }) {
    const { isMobile } = useWindowDimensions()

    const [uploadedImage, setUploadedImage] = useState(null)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const closePopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.IMAGE_SELECTION }))
    }

    const uploadImage = useCallback((file) => {
        const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)

        if (sizeInMB > 50) {
            setError(true)
            return
        } else {
            setError(false)
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (e) => {
            setUploadedImage(e.target.result)
        }
    }, [])

    const resetImage = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setUploadedImage(null)
    }, [])

    const handleImageUpdate = useCallback(() => {
        onSelect(uploadedImage)
        dispatch(closePopup({ component: PopupComponentKeys.IMAGE_SELECTION }))
    }, [uploadedImage, onSelect])

    return (
        <Wrapper>
            <Dropzone onDrop={(file) => uploadImage(file[0])}>
                {({ getRootProps, getInputProps }) => (
                    <DropzoneWrapper>
                        <DropzoneContainer {...getRootProps()} height={uploadedImage ? 'fit-content' : null}>
                            <input {...getInputProps()} />
                            {uploadedImage && (
                                <Stack width="100%" spacing="8px" direction="column">
                                    <PreviewContainer>
                                        <LeftAlignedContainer>
                                            <StyledSVG src={CloseIcon} onClick={resetImage} />
                                        </LeftAlignedContainer>
                                    </PreviewContainer>
                                    <PreviewImage src={uploadedImage} />
                                </Stack>
                            )}
                            {!uploadedImage && (
                                <ContentContainer>
                                    <StyledSVG src={UploadIcon} />
                                    <TextContainer>
                                        <RegularText fontWeight={500} color={darkBlack}>
                                            <HiglightedText>Click to upload</HiglightedText> or dag and drop
                                        </RegularText>
                                        <RegularText
                                            constantSize
                                            fontSize="14px"
                                            fontWeight={400}
                                            color={naturalGray}
                                            marginTop="6px"
                                        >
                                            Max image size 50 MB.
                                        </RegularText>
                                    </TextContainer>
                                    {error && (
                                        <Center>
                                            <Wrapper mt="12px">
                                                <RegularText color={redOrange} marginRight="16px">
                                                    Max size is 50MB
                                                </RegularText>
                                            </Wrapper>
                                        </Center>
                                    )}
                                </ContentContainer>
                            )}
                        </DropzoneContainer>
                    </DropzoneWrapper>
                )}
            </Dropzone>
            <Wrapper mt="32px">
                <LeftAlignedContainer>
                    <Stack spacing="12px">
                        <TransparentBorderButton text="Close" color={orange} action={closePopupAction} />
                        <SimpleButton text={isMobile ? 'Choose' : 'Add image'} action={handleImageUpdate} />
                    </Stack>
                </LeftAlignedContainer>
            </Wrapper>
        </Wrapper>
    )
}

export default memo(UploadedImageSelection)
