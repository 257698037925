import styled from 'styled-components'
import { black } from '../../../../../common/styles/colors'
import { TextInput } from '../../../../../common/form/styles'

export const TextWithInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
`

export const OutlineSectionContentItemContainer = styled.div`
    position: relative;
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 8px;

    & svg path {
        fill: ${black};
    }
`

export const OutlineSectionContentItemsList = styled.div`
    display: flex;
    flex-direction: column;

    & > div {
        &:nth-last-of-type(1) {
            & > div {
                margin-bottom: 0;
            }
        }
    }
`

export const ButtonContainer = styled.div`
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    column-gap: 8px;

    & svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        & rect {
            fill: #f9f9f9;
        }
    }
`

export const OutlineInput = styled(TextInput)`
    background: #f9f9f9;
    border: 2px solid #f1edeb;
    border-radius: 8px;
    height: 44px;
    margin: 0;
    padding: 10px 14px;
`

export const BackNavigationContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;

    & svg {
        width: 12px;
        height: 12px;
        transform: rotate(90deg);

        & path {
            fill: ${black};
        }
    }

    @media (max-width: 480px) {
        column-gap: 16px;
    }
`