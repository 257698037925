import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES, resetStatus } from './reducer-helper'
import { cancelSubscription, resumeSubscription, searchPromo, subscriptionDetails, subscriptionProductsList } from '../components/settings/slice'

const initialState = {
    currentSubscription: null,
    availableProducts: [],
    status: REQUEST_STATUSES.NOT_TRIGGERED,
    promo: {
        id: '',
        description: '',
        newPrice: '',
        skipPayment: false,
        error: null
    },
}

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    extraReducers(builder) {
        builder.addCase(subscriptionDetails.fulfilled, (state, action) => {
            state.currentSubscription = action.payload
            resetStatus(state)
        })
        builder.addCase(cancelSubscription.fulfilled, (state, action) => {
            state.currentSubscription = action.payload
            resetStatus(state)
        })
        builder.addCase(resumeSubscription.fulfilled, (state, action) => {
            state.currentSubscription = action.payload
            resetStatus(state)
        })
        builder.addCase(subscriptionProductsList.fulfilled, (state, action) => {
            // Temporary solution START
            const sortOrder = ['Free', '15$', '30$']

            action.payload.sort((a, b) => {
                const indexA = sortOrder.indexOf(a.name)
                const indexB = sortOrder.indexOf(b.name)
                return indexA - indexB
            })
            // Temporary solution END
            state.availableProducts = action.payload
            resetStatus(state)
        })
        builder.addCase(searchPromo.pending, (state) => {
            state.promo = initialState.promo
        })
        builder.addCase(searchPromo.fulfilled, (state, action) => {
            state.promo = Object.assign({ error: null }, action.payload)
        })
        builder.addCase(searchPromo.rejected, (state, action) => {
            state.promo = {
                id: '',
                description: '',
                newPrice: '',
                error: { message: action.payload.message }
            }
        })
    },
    reducers: {
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        clearPromo: (state) => {
            state.promo = initialState.promo
        }
    },
})

const { reducer, actions } = subscriptionSlice

const selectSubscriptionSlice = (state) => state.subscription

export const selectAvailableProducts = (state) => selectSubscriptionSlice(state).availableProducts
export const selectCurrentSubscription = (state) => selectSubscriptionSlice(state).currentSubscription
export const selectRequestStatus = (state) => selectSubscriptionSlice(state).status
export const selectPromo = (state) => selectSubscriptionSlice(state).promo

export const { changeRequestStatus, clearPromo } = actions

export default reducer
