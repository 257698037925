import React, { memo } from 'react'
import { BottomContainer, KeywordsContainer, StatusContainer } from '../styles'
import { CardSubtitleText, RegularText } from '../../../common/styles/text'
import { darkGray } from '../../../common/styles/colors'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import RefreshIcon from '../../../../assets/images/refresh.svg'
import { EditArticleMenu } from './EditArticleMenu'

function InQueueArticle({ article }) {
    return (
        <>
            <EditArticleMenu article={article} allowDelete={false} />
            <KeywordsContainer>
                <CardSubtitleText>KEYWORD PHRASE:</CardSubtitleText>
                <RegularText lineHeight="24px" fontWeight="400" color={darkGray}>
                    {article.keywords}
                </RegularText>
            </KeywordsContainer>
            <BottomContainer>
                <StatusContainer>
                    <StyledSVG color="#429D17" src={RefreshIcon} />
                    <RegularText fontSize="14px" lineHeight="24px" color="#429D17">
                        &nbsp; IN PROGRESS
                    </RegularText>
                </StatusContainer>
            </BottomContainer>
        </>
    )
}

export default memo(InQueueArticle)
