import styled, { css } from 'styled-components'
import { black, white } from '../../../common/styles/colors'
import { motion } from 'framer-motion'

export const SideMenuContainer = styled(motion.div)`
    height: calc(100vh - 6.25rem);
    position: fixed;
    width: 17.188rem;
    border-radius: 1rem;
    background: ${white};
    margin-left: -1.875rem;
    margin-top: -1.875rem;
    padding: 1rem 0.5rem;

    @media (max-width: 780px) {
        width: calc(100vw - 32px);
        height: calc(100vh - 126px);
        left: 8px;
        right: 8px;
        top: 86px;
        bottom: 8px;
        z-index: 100;
        margin: 0;
    }
`

export const CompaniesContainer = styled.div`
    margin-top: 2.75rem;
`

export const MenuSearchFieldContainer = styled.div`
    position: relative;
`

export const ClearInputContainer = styled.div`
    position: absolute;
    right: 0.688rem;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    background: #dad4cf;
    border-radius: 50%;
    padding: 0.25rem;
    cursor: pointer;

    & svg {
        width: 0.75rem;
        height: 0.75rem;
        & path {
            fill: ${white};
        }
    }
`

export const EditButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
    background: #f1f1f1;
    transition: 0.2s;
    width: 1.75rem;
    height: 1.75rem;

    & svg {
        transform: rotate(0) !important;
    }

    ${(props) =>
        props.showMenu &&
        css`
            opacity: 1;
        `}

    @media (max-width: 480px) {
        opacity: 1;
        background: none;
    }
`

export const EditButtonWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    ${(props) =>
        props.withoutMenu &&
        css`
            visibility: hidden;
        `}
`

export const ItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;

    &:hover ${EditButtonContainer} {
        opacity: 1;
    }
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.2s;

    & svg, & i {
        transition: 0.2s;
    }

    ${(props) =>
        props.isOpen &&
        css`
            & svg, & i {
                transform: rotate(180deg);
            }
        `}

    ${(props) =>
        props.active &&
        css`
            background: #ffefe6;

            & ${EditButtonContainer} {
                background: none;
            }
        `}
`

export const ContentItemContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 2.25rem;
    cursor: pointer;
    border-radius: 0.5rem;

    ${(props) =>
        props.active &&
        css`
            background: #ffefe6;

            & ${EditButtonContainer} {
                background: none;
            }
        `}
`

export const FolderListContainer = styled.div``

export const CompanyListContainer = styled.div`
    margin-top: 0.75rem;
`

export const SideManuWrapper = styled.div`
    z-index: 100;
`

export const AddCompanyContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.375rem;
    padding-left: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        & path {
            fill: ${black};
        }
    }
`

export const FilterOptionsContainer = styled.div`
    position: absolute;
    top: 2.5rem;
    z-index: 999;
    height: 100%;
    right: -70%;
    width: 12.5rem;

    @media (max-width: 480px) {
        width: 100px;
    }
`

export const SeeAllContainer = styled.div`
    padding-right: 0.5rem;
    cursor: pointer;
`
