import { useDispatch, useSelector } from "react-redux";
import { selectArticleLocations } from "../../../reducers/articles";
import { getLocations } from "../../article/slice";
import { useEffect } from "react";

export const useArticleLocations = () => {
  const dispatch = useDispatch()
  const articleLocations = useSelector(selectArticleLocations)
  useEffect(() => {
    if(!articleLocations.length) {
        dispatch(getLocations())
    }
  }, [])

  return articleLocations
};
