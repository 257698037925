import React, { memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    ButtonsContainer,
    MiddleFormContainer,
    MiddleFormSubtitleContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { H1Title, SubtitleText } from '../../common/styles/text'
import { ButtonBlock, FormContainer } from '../../common/styles/form-styles'
import TextField from '../../common/form/text-field/TextField'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import { FieldContainer } from '../../common/form/text-field/styles'
import {
    selectGlobalError,
    selectValidationErrors,
    changeEmailValue,
    changeRequestStatus,
    selectForgotPasswordFormData,
    setValidationError,
} from '../../../reducers/forms/forgot-password-form'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { emailRegex } from '../../../utils/utils'
import { requestChangePassword } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function EmailForm() {
    const dispatch = useDispatch()
    const { executeRecaptcha } = useGoogleReCaptcha();

    const forgotPasswordFormData = useSelector(selectForgotPasswordFormData)
    const globalError = useSelector(selectGlobalError)
    const validationErrors = useSelector(selectValidationErrors)

    const changeEmailField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'email', message: null }))
        dispatch(changeEmailValue({ email: event.target.value }))
    }

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
          return;
        }
    
        nextStep(await executeRecaptcha('forgotpassword'))
    }, [executeRecaptcha, forgotPasswordFormData]);

    const nextStep = (recaptcha) => {
        const { email } = forgotPasswordFormData

        if (emailRegex.test(email)) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(requestChangePassword({ email, recaptcha }))
        } else {
            dispatch(setValidationError({ field: 'email', message: 'Invalid email' }))
        }
    }

    return (
        <MiddleFormContainer marginTop="130px">
            <MiddleFormTitleContainer>
                <H1Title>Reset password</H1Title>
            </MiddleFormTitleContainer>
            <MiddleFormSubtitleContainer>
                <SubtitleText>An email has been sent with instructions to reset your password</SubtitleText>
            </MiddleFormSubtitleContainer>

            <ErrorMessage error={globalError} />

            <FormContainer onSubmit={handleReCaptchaVerify}>
                <fieldset>
                    <FieldContainer>
                        <fieldset>
                            <TextField
                                label={'Email'}
                                value={forgotPasswordFormData.email}
                                errorMessage={validationErrors.email}
                                changeEvent={changeEmailField}
                                placeholder={'Enter your email'}
                            />
                        </fieldset>
                    </FieldContainer>
                </fieldset>
                <ButtonsContainer>
                    <ButtonBlock>
                        <SimpleButton text="Send reset link" />
                    </ButtonBlock>
                </ButtonsContainer>
            </FormContainer>
        </MiddleFormContainer>
    )
}

const ReCaptcha = ({ onTokenChange, children }) => {
  
    return <>
    </>
};

export default memo(EmailForm)
