import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshCredits } from "../../settings/slice";

export const useCreditRefresher = () => {
    // TODO: Handle this better than just polling every 10 seconds
    const dispatch = useDispatch()
    useEffect(() => {
        const id = setInterval(() => dispatch(refreshCredits()), 10000)
        return () => clearInterval(id);
    }, [])
}