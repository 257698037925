import styled from 'styled-components'
import OutOfCreditBackground from '../../../../assets/images/out-of-credit-banner.svg'
import OutOfCreditMobileBackground from '../../../../assets/images/out-of-credit-mobile-banner.svg'
import { Link } from 'react-router-dom'
import { white } from '../../../common/styles/colors'

export const OutOfCreditBannerContainer = styled.div`
    position: absolute;
    top: 50px;
    height: 92px;
    right: 50px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: end;
    width: 315px;
    background-image: url(${OutOfCreditBackground});

    @media (max-width: 480px) {
        position: relative;
        width: 100%;
        right: 0;
        top: 40px;
        background-image: url(${OutOfCreditMobileBackground});
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
`

export const MessageContainer = styled.div`
    display: flex;
    margin-bottom: 20px;

    @media (max-width: 480px) {
        margin-bottom: 25px;
    }
`

export const OutOfCreditLinkContainer = styled(Link)`
    margin-left: 10px;
    font-family: 'Inter';
    font-weight: 500;
    color: ${white};
    font-size: 16px;
`
