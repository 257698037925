import styled from 'styled-components'

export const LoadingContainer = styled.div`
    position: ${(props) => props.position || 'fixed'};
    top: ${(props) => props.top || '0'};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;

    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden; /* Disable scrolling */
`
