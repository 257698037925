export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const Status = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failure',
}

export const ArticleStatus = {
    IN_QUEUE: 'in-queue',
    IN_PROGRESS: 'in-progress',
    REPROCESSING: 'reprocessing',
    PAUSED: 'paused',
    NOT_ENOUGH_CREDIT: 'not-enough-credit',
    READY: 'ready',
    DELETED: 'deleted',
    ERROR: 'generating_error'
}

export const CompanyStatus = {
    ACTIVE: 'active',
    DELETED: 'deleted',
}

export const ROUTES = {
    MAIN: '/',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    SELECT_SUBSCRIPTION: '/subscription/select',
    FORGOT_PASSWORD: '/forgot-password',
    ARTICLES: '/articles',
    ARTICLE: '/articles/:articleId',
    ARTICLE_OUTLINE: '/articles/outline/:articleId',
    SETTINGS: '/settings',
    CREDIT: '/credit',
    CREDIT_CHECKOUT_STRIPE: '/credit/checkout-stripe',
    SUBSCRIPTION_CHECKOUT_STRIPE: '/subscription/checkout-stripe',
}

export const settingsTabs = [
    { key: 'general', title: 'General' },
    { key: 'company', title: 'Company' },
    { key: 'plan_and_billing', title: 'Plan and Billing' },
]

export const CREDIT_PACKS = [
    {
        id: '1',
        credits: 100,
        price: 10,
        bonus: 0,
        isPopular: false,
    },
    {
        id: '2',
        credits: 500,
        price: 50,
        bonus: 50,
        isPopular: true,
    },
    {
        id: '3',
        credits: 1000,
        price: 100,
        bonus: 100,
        isPopular: false,
    },
]

export function formatShortDate(dateString) {
    const options = { month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
}

export function formatShortDateWithYear(dateString) {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
}

export function formatFullDate(dateString) {
    const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    };

    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
}

export const countWords = (str) => {
    str = str.trim()
    const words = str.split(/\s+/).filter(w => !!w)
    return words.length
}