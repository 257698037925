import React, { memo } from 'react'
import { BottomContainer, KeywordsContainer, StatusContainer } from '../styles'
import { CardSubtitleText, RegularText } from '../../../common/styles/text'
import { darkGray } from '../../../common/styles/colors'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import InfoIcon from '../../../../assets/images/info.svg'
import { EditArticleMenu } from './EditArticleMenu'

function ErrorArticle({ article }) {
    return (
        <>
            <EditArticleMenu article={article} />
            <KeywordsContainer>
                <CardSubtitleText>KEYWORD PHRASE:</CardSubtitleText>
                <RegularText lineHeight="1.5rem" fontWeight="400" color={darkGray}>
                    {article.keywords}
                </RegularText>
            </KeywordsContainer>
            <BottomContainer>
                <StatusContainer>
                    <StyledSVG color="#794b02" src={InfoIcon} />
                    <RegularText fontSize="0.875rem" lineHeight="1.5rem" color="#794b02">
                        &nbsp; Temporary Problem: We're Working on it!
                    </RegularText>
                </StatusContainer>
            </BottomContainer>
        </>
    )
}

export default memo(ErrorArticle)
