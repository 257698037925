import styled from 'styled-components'
import { redOrange, regularGray } from '../styles/colors'
import { TextArea } from 'semantic-ui-react'

export const TextAreaContainer = styled(TextArea)`
    display: block !important;
    min-width: 95% !important;
    max-width: 95% !important;
    width: 95% !important;
    border: 1px solid #f1edeb !important;
    border-radius: 8px !important;
    margin-top: 5px;
    padding: 15px !important;
    min-height: 0 !important;

    &:hover,
    &:focus-visible,
    &:focus {
        outline: none;
        box-shadow: none;
        border: solid 2px ${redOrange} !important;

        .menu {
            left: -1px;
            border: solid 2px ${redOrange} !important;
        }
    }

    .visible.transition {
        display: block !important;
        visibility: visible !important;
    }
`

export const FieldLabel = styled.label`
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${regularGray};

    &:hover {
        cursor: pointer;
    }
`