import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../api/userAgent'
import { PaymentAgent } from '../../api/paymentAgent'
import { CompanyAgent } from '../../api/companyAgent'

export const updateProfile = createAsyncThunk('user/profile/update', async ({ firstName, lastName }, thunkApi) => {
    try {
        const response = await UserAgent.updateProfile(firstName, lastName)

        return { ...response, firstName: firstName, lastName: lastName }
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const subscriptionProductsList = createAsyncThunk(
    'payment/subscription/products/list',
    async (undefined, thunkApi) => {
        try {
            const response = await PaymentAgent.subscriptionProductsList()

            return response
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)

export const subscriptionDetails = createAsyncThunk(
    'payment/subscription/details',
    async ({ subscriptionId }, thunkApi) => {
        try {
            const response = await PaymentAgent.subscriptionDetails(subscriptionId)

            return response
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)

export const cancelSubscription = createAsyncThunk('payment/subscription/cancel', async (undefined, thunkApi) => {
    try {
        const response = await PaymentAgent.cancelSubscription()

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})


export const resumeSubscription = createAsyncThunk('payment/subscription/resume', async (undefined, thunkApi) => {
    try {
        const response = await PaymentAgent.resumeSubscription()

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const searchPromo = createAsyncThunk('payment/promo/search', async ({ promoCode, productId }, thunkApi) => {
    try {
        const response = await PaymentAgent.searchPromo(promoCode, productId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const updateSubscription = createAsyncThunk('payment/subscriptions', async ({ promoId, subscriptionId }, thunkApi) => {
    try {
        const response = await PaymentAgent.updateSubscription(subscriptionId, promoId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const subscribeFreePlan = createAsyncThunk(
    'payment/subscription/subscription-free-plan',
    async ({ productId }, thunkApi) => {
        try {
            const response = await PaymentAgent.subscribeFreePlan(productId)

            return response
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)

export const createCompany = createAsyncThunk('company/create', async (payload, thunkApi) => {
    try {
        const response = await CompanyAgent.create(payload)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const updateCompany = createAsyncThunk('company/update', async ({ companyId, name }, thunkApi) => {
    try {
        const response = await CompanyAgent.update(companyId, { name })

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const deleteCompany = createAsyncThunk('company/delete', async (companyId, thunkApi) => {
    try {
        const response = await CompanyAgent.delete(companyId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const paymentHistoryList = createAsyncThunk(
    'payment/history/list',
    async (undefined, thunkApi) => {
        try {
            const response = await PaymentAgent.paymentHistoryList()

            return response
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)

export const refreshCredits = createAsyncThunk(
    'payment/credits/refresh',
    async (undefined, thunkApi) => {
        try {
            const response = await PaymentAgent.refreshCredits()

            return response
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)