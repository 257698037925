import React, { memo } from 'react'
import { ArticlePreviewWrapper, ActionContainer, BottomContainer, KeywordsContainer, RenewArticleButton, StatusContainer } from '../styles'
import { CardSubtitleText, RegularText } from '../../../common/styles/text'
import { black, darkGray, vividRed } from '../../../common/styles/colors'
import { PopupComponentKeys } from '../../../common/popup/Popup'
import { showPopup } from '../../../../reducers/popup'
import { useDispatch } from 'react-redux'
import { getArticles, resumeArticle } from '../../slice'
import { NotificationManager } from 'react-notifications'
import { EditArticleMenu } from './EditArticleMenu'

function PausedArticle({ article }) {
    const dispatch = useDispatch()

    const cancelPopup = (event) => {
        event.stopPropagation()
        dispatch(
            showPopup({ component: PopupComponentKeys.CANCEL_ARTICLE, extraData: { articleId: article.id } }),
        )
    }

    const handleRenewArticle = async (event) => {
        event.stopPropagation()

        try {
            await dispatch(resumeArticle(article.id)).unwrap()
            await dispatch(getArticles())

            NotificationManager.info('Article resumed')
        } catch (err) {
            NotificationManager.error('Article resuming failed')
        }
    }

    return (
        <>
            <EditArticleMenu article={article} />
            <KeywordsContainer>
                <CardSubtitleText>KEYWORDS PHRASE:</CardSubtitleText>
                <RegularText lineHeight="24px" fontWeight="400" color={darkGray}>
                    {article.keywords}
                </RegularText>
            </KeywordsContainer>
            <RenewArticleButton onClick={handleRenewArticle}>Resume Creation</RenewArticleButton>
            <BottomContainer>
                <StatusContainer>
                    <RegularText fontSize="14px" lineHeight="24px" color={vividRed}>
                        PAUSED
                    </RegularText>
                </StatusContainer>
                <ActionContainer onClick={cancelPopup}>
                    <RegularText lineHeight="24px" color={black}>
                        Cancel
                    </RegularText>
                </ActionContainer>
            </BottomContainer>
        </>
    )
}

export default memo(PausedArticle)
