import React, { memo } from 'react'
import { StackComponent } from './styles'

function Stack({ children, ...rest }) {
    return (
        <StackComponent {...rest}>
            {children}
        </StackComponent>
    )
}

export default memo(Stack)
