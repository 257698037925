import { request } from './agent'

export const PaymentAgent = {
    subscriptionProductsList: () => {
        return request.get('/payment/subscriptions/products/list')
    },
    createCreditPaymentIntent: (productId) => {
        return request.post('/payment/credit/payment-intent/create', { productId })
    },
    createSubscription: (productId) => {
        return request.post('/payment/subscriptions/create', { productId })
    },
    confirmCreditPaymentIntent: (paymentIntentId) => {
        return request.post('/payment/credit/payment-intent/confirm', { paymentIntentId })
    },
    subscriptionDetails: (subscriptionId) => {
        return request.get(`/payment/subscriptions/${subscriptionId}`)
    },
    cancelSubscription: () => {
        return request.post(`/payment/subscriptions/cancel`)
    },
    resumeSubscription: () => {
        return request.post(`/payment/subscriptions/resume`)
    },
    subscribeFreePlan: (productId) => {
        return request.post(`/payment/subscriptions/subscribe-free-plan`, { productId })
    },
    creditPackProductsList: () => {
        return request.get('/payment/credit/products/list')
    },
    paymentHistoryList: () => {
        return request.get('/payment/history/list')
    },
    refreshCredits: () => {
        return request.get('/init')
    },
    searchPromo: (promoCode, productId) => {
        return request.get(`/payment/promo/search?promoCode=${promoCode}&productId=${productId}`)
    },
    updateSubscription: (subscriptionId, promoId) => {
        return request.put(`/payment/subscriptions/${subscriptionId}`, { stripePromotionId: promoId })
    }
}
