import React, { memo, useEffect } from 'react'
import { LoadingContainer } from './styles'
import Spinner from 'react-svg-spinner'
import { orange } from '../styles/colors'

const Loading = ({ position, top }) => {
    useEffect(() => {
        document.body.classList.toggle('no-scroll', true)
        return () => {
            document.body.classList.toggle('no-scroll', false)
        }
    }, [])

    return (
        <LoadingContainer position={position} top={top}>
            <Spinner color={orange} width={'32px'} height={'32px'} />
        </LoadingContainer>
    )
}

export default memo(Loading)
