import { createSlice } from '@reduxjs/toolkit'
import { changePassword } from '../components/settings/change-password-popup/slice'

const initialState = {
    showPopup: false,
    component: null,
    extraData: {},
}

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    extraReducers(builder) {
        builder.addCase(changePassword.fulfilled, (state) => {
            state.showPopup = false
            state.component = null
        })
    },
    reducers: {
        showPopup(state, action) {
            state.showPopup = true
            state.extraData = action.payload.extraData
            state.styles = action.payload.styles
            state.component = action.payload.component
        },
        closePopup(state) {
            state.showPopup = false
            state.component = null
        },
    },
})

const { reducer, actions } = popupSlice

export const { showPopup, closePopup } = actions

export default reducer
