import React, { memo } from 'react'
import { ErrorContainer, ErrorMessage as ErrorMessageText } from './styles'

function ErrorMessage({ error }) {
    if (!error || Object.keys(error).length === 0) {
        return null
    }
    return (
        <ErrorContainer>
            <ErrorMessageText>{error.message}</ErrorMessageText>
        </ErrorContainer>
    )
}

export default memo(ErrorMessage)
