import styled from 'styled-components'
import { black, darkGray, naturalGray, white } from '../../../common/styles/colors'

export const FoldersContainer = styled.div`
    margin-bottom: ${props => props.margin};

    @media (max-width: 480px) {
        padding: 0 16px;
        margin-bottom: 0;
    }
`

export const CompanyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.063rem solid #d4d0cd;
    padding-bottom: 1rem;
    margin-bottom: 1.25rem;

    @media (max-width: 480px) {
        padding-bottom: 12px;
        padding-top: 16px;
    }
`

export const OptionButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    height: 3rem;
    background: #e7e2de;
    border-radius: 1.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    outline: none;
    cursor: pointer;

    & svg {
        width: 1.5rem;
        height: 1.5rem;

        & path {
            fill: ${black};
        }
    }

    @media (max-width: 480px) {
        width: 48px;
        height: 48px;
        padding: 0;
        border-radius: 50%;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    column-gap: 0.5rem;
`

export const FolderContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 1rem;
    border: 0.063rem solid #d6d3d2;
    background: ${white};
    padding: 0.75rem;
    position: relative;
    cursor: pointer;

    & svg path {
        fill: #dad4cf;
    }
`

export const FoldersListWrapper = styled.div`
    @media (max-width: 480px) {
        display: none;
    }
`

export const FoldersListContainer = styled.div`
    display: flex;
    column-gap: 1.25rem;
    row-gap: 1.25rem;
    flex-wrap: wrap;
    margin-top: 1rem;
`

export const ContetnContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-right: 1.5rem;
    min-width: 10.813rem;

    & svg {
        width: 2.5rem;
        height: 2.5rem;
    }
`

export const ThreeDotsContainer = styled.div`
    cursor: pointer;

    & svg {
        width: 1rem;
        height: 1rem;

        path {
            fill: ${naturalGray};
        }
    }
`

export const BackNavigation = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.875rem;
    cursor: pointer;

    & svg {
        height: 0.813rem;
        width: 0.813rem;
        transform: rotate(-90deg);

        & path {
            fill: ${darkGray};
        }
    }
`
