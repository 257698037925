import { combineReducers, configureStore } from '@reduxjs/toolkit'
import signUpFormReducer from '../reducers/forms/sign-up-form'
import signInFormReducer from '../reducers/forms/sign-in-form'
import forgotPasswordReducer from '../reducers/forms/forgot-password-form'
import createArticleReducer from '../reducers/forms/create-article-form'
import changePasswordReducer from '../reducers/forms/change-password-form'
import companyNamesReducer from '../reducers/forms/companies-form'
import profileReducer from '../reducers/forms/profile-form'
import popupReducer from '../reducers/popup'
import articlesReducer from '../reducers/articles'
import userReducer from '../reducers/user'
import commonReducer from '../reducers/common'
import subscriptionReducer from '../reducers/subscription'
import creditPacksReducer from '../reducers/credit-packs'
import paymentHistoryReducer from '../reducers/payment-history'
import { localStorageMiddleware } from './middleware'

export function makeStore(preloadedState) {
    return configureStore({
        reducer: {
            common: commonReducer,
            popup: popupReducer,
            user: userReducer,
            articles: articlesReducer,
            subscription: subscriptionReducer,
            creditPacks: creditPacksReducer,
            paymentHistory: paymentHistoryReducer,
            forms: combineReducers({
                signInForm: signInFormReducer,
                signUpForm: signUpFormReducer,
                forgotPasswordForm: forgotPasswordReducer,
                createArticleForm: createArticleReducer,
                changePasswordForm: changePasswordReducer,
                companiesForm: companyNamesReducer,
                profileForm: profileReducer,
            }),
        },
        //todo: parameterize dev config
        devTools: true,
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
    })
}

const store = makeStore()

export default store
