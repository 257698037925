import React, { memo, useCallback } from 'react'
import { CheckboxItemContainer, DropdownContainer, IconContainer, ItemContainer } from './styles'
import StyledSVG from '../styled-svg/StyledSVG'
import { RegularText } from '../styles/text'
import Checkbox from '../switch/Checkbox'

const CheckboxDropdown = ({ wrapperRef, items, alignText = 'center', selectedItems, onSelect }) => {
    return (
        <DropdownContainer ref={wrapperRef}>
            {items.map((item, index) => (
                <CheckboxItemContainer alignText={alignText} key={index}>
                    <Checkbox isChecked={(selectedItems || []).includes(item.name)} onChange={() => onSelect(item.name)} />
                    <RegularText fontWeight={500}>{item.name}</RegularText>
                </CheckboxItemContainer>
            ))}
        </DropdownContainer>
    )
}

export default CheckboxDropdown
