import styled from 'styled-components'

export const HeaderContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #282828;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
`

export const LeftContentContainer = styled.div`
    position: relative;
    margin-left: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    @media (max-width: 480px) {
        div {
            font-size: 22px !important;
        }

        & svg {
            width: 34px;
        }
    }
`

export const RightContentContainer = styled.div`
    position: relative;
    padding-right: 2.5rem;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: row;

    @media (max-width: 480px) {
        button {
            width: 84px;
            height: 32px;
            padding: 4px 12px;
        }
    }
`

export const LogoTitle = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 2.142rem;
    line-height: 2.625rem;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-left: 0.125rem;
`
