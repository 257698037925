import React, { memo, useMemo, useState } from 'react'
import { RegularText } from '../../common/styles/text'
import { FormContainer } from '../../common/styles/form-styles'
import { FieldContainer } from '../../common/form/text-field/styles'
import TextareaField from '../../common/form/textarea-field/TextareaField'
import TextField from '../../common/form/text-field/TextField'
import IconField from '../../common/form/icon-field/IconField'
import UrlIcon from '../../../assets/images/url.svg'
import {
    CheckboxContainer,
    OptionalInputsCollapseHeaderContainer,
    OptionalInputsTitleContainer,
    PopupContent,
    SeparateLineContainer,
} from '../styles'
import { darkGray, naturalGray } from '../../common/styles/colors'
import { useArticleLocations } from '../../common/hooks/useArticleLocations'
import { useArticleLanguages } from '../../common/hooks/useArticleLanguages'
import Checkbox from '../../common/switch/Checkbox'

function ParametersPopup({ extraData }) {
    const [currentArticle] = useState(extraData.article)
    const articleLocations = useArticleLocations();
    const articleLocation = useMemo(() => (
        articleLocations.find((location) => location.code === currentArticle?.parameters?.locationId)
    ), [currentArticle, articleLocations]);
    const additionalKeywords = useMemo(() => (
        (currentArticle?.parameters?.additionalKeywords || '').split(', ').map(k => k.slice(1,-1))
    ), [currentArticle]);
    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    Parameters
                </RegularText>
            </div>
            <div>
                <FormContainer>
                    <FieldContainer>
                        <TextareaField
                            value={currentArticle.keywords ?? ''}
                            label="Keyword Phrase"
                            placeholder="Add keyword phrase"
                            disabled
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <TextField
                            value={currentArticle.parameters.companyName ?? ''}
                            label="Company Name"
                            placeholder="Add company name"
                            disabled
                        />
                    </FieldContainer>
                    <SeparateLineContainer />
                    <>
                        <OptionalInputsCollapseHeaderContainer>
                            <OptionalInputsTitleContainer>
                                <RegularText color={darkGray} fontWeight={500}  marginRight="10px">
                                    Optional inputs
                                </RegularText>
                                <RegularText color={naturalGray} fontWeight={500} fontSize="14px" lineHeight="18px">
                                    Title, Word Count, Links, Company Name
                                </RegularText>
                            </OptionalInputsTitleContainer>
                        </OptionalInputsCollapseHeaderContainer>
                        <>
                            {/* <FieldContainer>
                                <TextareaField
                                    value={currentArticle.parameters.articleTitle ?? ''}
                                    label="Article Title"
                                    placeholder="Enter the title or we will create it automatically"
                                    disabled
                                />
                            </FieldContainer> */}
                            <FieldContainer>
                                <TextField
                                    value={currentArticle.parameters.words ?? ''}
                                    label="Target Word Count"
                                    placeholder="Default is 1000 words"
                                    disabled
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    value={articleLocation?.name ?? ''}
                                    label="Target Geo Location"
                                    disabled
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    value={currentArticle?.parameters?.language?.name ?? ''}
                                    label="Language"
                                    disabled
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    value={currentArticle.parameters.tone ?? ''}
                                    label="Tone of Article"
                                    placeholder="Tone of article"
                                    disabled
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    value={currentArticle.parameters.callToActionTitle ?? ''}
                                    label="Call to Action"
                                    disabled
                                />
                                {currentArticle.parameters.callToActionLink && <IconField
                                    value={currentArticle.parameters.callToActionLink ?? ''}
                                    icon={UrlIcon}
                                    placeholder="URL"
                                    disabled
                                />}
                            </FieldContainer>
                            <FieldContainer>
                                {(additionalKeywords ?? []).map((keyword, index) => (
                                    <TextField
                                        key={index}
                                        label={index === 0 ? 'Additional Keywords' : null}
                                        value={keyword}
                                        disabled
                                    />
                                ))}
                            </FieldContainer>
                            <FieldContainer>
                                <TextField
                                    value={currentArticle.parameters.additionalDetails ?? ''}
                                    label="Additional Details"
                                    disabled
                                />
                            </FieldContainer>
                            <FieldContainer>
                                {(currentArticle.parameters.links ?? []).map((link, index) => (
                                    <IconField
                                        key={index}
                                        label={index === 0 ? 'Link' : null}
                                        value={link}
                                        icon={UrlIcon}
                                        placeholder="URL"
                                        disabled
                                    />
                                ))}
                            </FieldContainer>
                            <CheckboxContainer>
                                <Checkbox disabled isChecked={currentArticle.parameters.autoHumanize} />
                                <RegularText fontWeight={500}>Auto-humanize?</RegularText>
                            </CheckboxContainer>
                        </>
                    </>
                </FormContainer>
            </div>
        </PopupContent>
    )
}

export default memo(ParametersPopup)
