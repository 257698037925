import { createSlice } from '@reduxjs/toolkit'
import { initApp } from '../components/app/slice'
import { ROUTES, Status } from '../utils/utils'
import { googleAuth, signIn } from '../components/user/sign-in/slice'
import { logout } from '../components/common/layouts/private-area/header/user-popover/slice'
import { confirmEmail, signUp } from '../components/user/sign-up/slice'
import { confirmNewPassword, requestChangePassword } from '../components/user/forgot-password/slice'
import { cancelSubscription, refreshCredits, subscribeFreePlan } from '../components/settings/slice'
import { getArticle, getArticles } from '../components/article/slice'

const initialState = {
    appLoaded: false,
    status: Status.IDLE,
    redirectTo: undefined,
    error: null,
    articlePrice: 0,
    isProcessingRequest: false,
    burgerMenuOpen: false,
    activeCompanyUuid: null,
    activeFolderUuid: null,
    searchText: '',
    searchTags: [],
}

function success(state) {
    state.appLoaded = true
    state.status = Status.SUCCESS
    delete state.errors
}

function failed(state) {
    state.appLoaded = true
    state.status = Status.FAILURE
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        clearRedirect(state) {
            delete state.redirectTo
        },
        toggleBurgerMenu(state) {
            state.burgerMenuOpen = !state.burgerMenuOpen
        },
        selectActiveCompanyUuid(state, action) {
            state.activeCompanyUuid = action.payload.activeCompanyUuid
            state.searchText = ''
            state.searchTags = []
        },
        selectActiveFolderUuid(state, action) {
            state.activeFolderUuid = action.payload.activeFolderUuid
            state.searchText = ''
            state.searchTags = []
        },
        updateSearchText(state, action) {
            state.searchText = action.payload.searchText
        },
        updateSearchTags(state, action) {
            state.searchTags = action.payload.searchTags
        },
    },
    extraReducers(builder) {
        builder.addCase(initApp.fulfilled, (state, action) => {
            success(state)
            state.articlePrice = action.payload.articlePrice
            const activeCompanies = action.payload.user.companies.filter((company) => company.status === 'active')

            const params = new URL(window.location).searchParams
            const companyUuid = params.get('company')

            const selectedCompany = activeCompanies.find((company) => company.uuid === companyUuid)
            if (selectedCompany?.uuid) {
                state.activeCompanyUuid = selectedCompany.uuid
            } else {
                state.activeCompanyUuid = activeCompanies?.[0]?.uuid
            }
        })
        builder.addCase(refreshCredits.fulfilled, (state, action) => {
            success(state)
            state.articlePrice = action.payload.articlePrice
        })
        builder.addCase(initApp.rejected, failed)
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.redirectTo = ROUTES.ARTICLES
        })
        builder.addCase(confirmEmail.fulfilled, (state, action) => {
            state.redirectTo = ROUTES.SELECT_SUBSCRIPTION
        })
        builder.addCase(subscribeFreePlan.fulfilled, (state, action) => {
            if (location.pathname === ROUTES.SIGN_UP) {
                state.redirectTo = ROUTES.ARTICLES
            }
        })
        builder.addCase(googleAuth.fulfilled, (state, action) => {
            state.redirectTo = ROUTES.ARTICLES
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            state.redirectTo = ROUTES.SIGN_IN
        })
        builder.addCase(confirmNewPassword.fulfilled, (state, action) => {
            state.redirectTo = ROUTES.SIGN_IN
        })
        builder.addCase(cancelSubscription.fulfilled, (state, action) => {
            state.redirectTo = ROUTES.SETTINGS
        })
        builder.addCase(getArticles.pending, (state, action) => {
            state.isProcessingRequest = !action.meta.arg || !action.meta.arg.silent
        })
        builder.addCase(signUp.pending, (state) => {
            state.isProcessingRequest = true
        })
        builder.addCase(signUp.fulfilled, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(signUp.rejected, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(requestChangePassword.pending, (state) => {
            state.isProcessingRequest = true
        })
        builder.addCase(requestChangePassword.fulfilled, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(requestChangePassword.rejected, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(getArticles.fulfilled, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(getArticles.rejected, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(getArticle.pending, (state) => {
            state.isProcessingRequest = true
        })
        builder.addCase(getArticle.fulfilled, (state) => {
            state.isProcessingRequest = false
        })
        builder.addCase(getArticle.rejected, (state) => {
            state.isProcessingRequest = false
        })
        builder.addMatcher(
            (action) => /common\/.*\/pending/.test(action.type),
            (state) => {
                state.status = Status.LOADING
            },
        )
    },
})

const { reducer, actions } = commonSlice

export const {
    clearRedirect,
    toggleBurgerMenu,
    selectActiveCompanyUuid,
    selectActiveFolderUuid,
    updateSearchText,
    updateSearchTags,
} = actions

export default reducer
