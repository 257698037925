import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    AddCompanyContainer,
    ClearInputContainer,
    CompaniesContainer,
    MenuSearchFieldContainer,
    SeeAllContainer,
    SideManuWrapper,
    SideMenuContainer,
} from './styles'
import SearchField from '../../../common/form/search-field/SearchField'
import { RegularText } from '../../../common/styles/text'
import { black, naturalGray } from '../../../common/styles/colors'
import CompaniesList from './CompaniesList'
import useWindowDimensions from '../../../common/hooks/useWindowDimensions'
import AddIcon from '../../../../assets/images/add.svg'
import CloseIcon from '../../../../assets/images/close-icon.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'
import { useUpdateValuePopup } from './update-value-popup/hooks/useUpdateValuePopup'
import FilterMenu from './filters/FilterMenu'
import { selectActiveCompanyUuid, selectActiveFolderUuid, updateSearchText } from '../../../../reducers/common'
import { createCompany } from '../../../settings/slice'
import { initApp } from '../../../app/slice'
import { NotificationManager } from 'react-notifications'
import Stack from '../../../common/layouts/Stack/Stack'

function SideMenu() {
    const dispatch = useDispatch()
    const companies = useSelector((state) => state.user.companies)
    const burgerMenuOpen = useSelector((state) => state.common.burgerMenuOpen)
    const searchText = useSelector((state) => state.common.searchText)
    const searchTags = useSelector((state) => state.common.searchTags)
    const [filterMenuOpen, setFilterMenuOpen] = useState(false)
    const { isMobile } = useWindowDimensions()
    const openPopup = useUpdateValuePopup()

    const activeCompanies = useMemo(() => {
        return companies.filter((company) => company?.status === 'active')
    }, [companies])

    const isOpen = useMemo(() => {
        document.body.style.overflow = 'visible'
        if (!isMobile) {
            return true
        } else if (burgerMenuOpen) {
            document.body.style.overflow = 'hidden'
            return true
        }
        return false
    }, [isMobile, burgerMenuOpen])

    const handleNewCompanyCreation = useCallback(() => {
        openPopup({
            title: 'Add Company',
            subTitle: 'Create a name',
            buttonText: 'Create',
            handleClick: (value, close) => {
                dispatch(createCompany({ companyName: value }))
                    .unwrap()
                    .then(() => {
                        NotificationManager.info('Company created')
                    })
                    .catch(() => {
                        NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
                    })
                close()
            },
        })
    }, [openPopup])

    const showAllArticles = useCallback(() => {
        dispatch(
            selectActiveCompanyUuid({
                activeCompanyUuid: null,
            }),
        )
        dispatch(
            selectActiveFolderUuid({
                activeFolderUuid: null,
            }),
        )
    }, [])

    useEffect(() => {
        if (searchText && filterMenuOpen) {
            setFilterMenuOpen(false)
        } else if (searchTags && filterMenuOpen) {
            setFilterMenuOpen(false)
        }
    }, [searchText, searchTags])

    return (
        <SideManuWrapper>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <SideMenuContainer>
                            <FilterMenu isOpen={filterMenuOpen} setIsOpen={setFilterMenuOpen} />
                            <MenuSearchFieldContainer>
                                <SearchField
                                    value={searchText}
                                    changeEvent={(value) => dispatch(updateSearchText({ searchText: value }))}
                                    width="100%"
                                    onFocus={() => setFilterMenuOpen(true)}
                                />
                                {searchText && (
                                    <ClearInputContainer onClick={() => dispatch(updateSearchText({ searchText: '' }))}>
                                        <StyledSVG src={CloseIcon} />
                                    </ClearInputContainer>
                                )}
                            </MenuSearchFieldContainer>
                            <CompaniesContainer>
                                <Stack justify="space-between" width="100%">
                                    <RegularText constantSize color={naturalGray} fontSize="0.875rem">
                                        COMPANIES
                                    </RegularText>
                                    <SeeAllContainer onClick={showAllArticles}>
                                        <RegularText constantSize color={naturalGray} fontSize="0.875rem">
                                            See all
                                        </RegularText>
                                    </SeeAllContainer>
                                </Stack>
                            </CompaniesContainer>
                            <CompaniesList companyData={companies} />
                            <AddCompanyContainer onClick={handleNewCompanyCreation}>
                                <StyledSVG src={AddIcon} />
                                <RegularText color={black} fontWeight={600}>
                                    Add company
                                </RegularText>
                            </AddCompanyContainer>
                        </SideMenuContainer>
                    </motion.div>
                )}
            </AnimatePresence>
        </SideManuWrapper>
    )
}

export default memo(SideMenu)
