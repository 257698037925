import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import AccordionContentItem from './AccordionContentItem'
import { FolderListContainer } from './styles'
import AddFolderItem from './AddFolderItem'
import { useFolders } from '../../hooks/useFolders'
import { useSelector } from 'react-redux'

function CompaniesList({ companyUuid, handleFolderSelection, handleCompanySelection, withoutOptions }) {
    const companies = useSelector((state) => state.user.companies)
    const { getFoldersList } = useFolders()

    const foldersList = useMemo(
        () => companies.find((company) => company.uuid === companyUuid)?.folders || [],
        [companies],
    )

    useEffect(() => {
        if (!companyUuid) return
        const fetchFolders = async () => {
            await getFoldersList(companyUuid)
        }
        fetchFolders()
    }, [companyUuid])

    const getFolderList = useCallback(() => {
        return foldersList.map((folder, index) => (
            <AccordionContentItem
                key={index}
                companyUuid={companyUuid}
                folder={folder}
                handleCompanySelection={handleCompanySelection}
                handleFolderSelection={handleFolderSelection}
                withoutOptions={withoutOptions}
            />
        ))
    }, [foldersList, companyUuid, handleFolderSelection, handleCompanySelection, withoutOptions])

    return (
        <FolderListContainer>
            <>
                {getFolderList()}
                <AddFolderItem companyUuid={companyUuid} />
            </>
        </FolderListContainer>
    )
}

export default memo(CompaniesList)
