import styled, { css } from 'styled-components'
import { black, naturalGray, regularGray, redOrange, white } from './colors'

export const RegularText = styled.p`
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'inherit')};
    letter-spacing: ${(props) => (props.letterSpacing ? props.letterSpacing : 'inherit')};
    color: ${(props) => (props.color ? props.color : regularGray)};
    ${(props) => (props.marginRight ? `margin-right: ${props.marginRight};` : '')}
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : '')}
    ${(props) => (props.cursor ? `cursor: ${props.cursor};` : '')}
    ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
    
    font-family: 'Inter';

    ${(props) =>
        !props.constantSize &&
        css`
            @media (max-width: 1220px) {
                font-size: ${(props) =>
                    props.fontSize ? parseInt(props.fontSize, 10) * (props.keepFontSize ? 1 : 0.9) + 'px' : '16px'};
            }

            @media (max-width: 768px) {
                font-size: ${(props) =>
                    props.fontSize ? parseInt(props.fontSize, 10) * (props.keepFontSize ? 1 : 0.8) + 'px' : '16px'};
            }

            @media (max-width: 480px) {
                font-size: ${(props) =>
                    props.fontSize ? parseInt(props.fontSize, 10) * (props.keepFontSize ? 1 : 0.6) + 'px' : '16px'};
            }
        `}
`

export const H1Title = styled.h1`
    font-size: 2rem;
    color: ${black};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 48px;

    @media (max-width: 480px) {
        font-size: ${(props) => props.mobileFontSize};
    }
`

export const H2White = styled.h1`
    font-size: 24px;
    color: ${white};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
`

export const SubtitleText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${(props) => props.color || naturalGray};
`

export const ErrorText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: ${redOrange};
`

export const CardSubtitleText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.12em;
    color: ${naturalGray};
`
