import styled from 'styled-components'

export const PopupContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
`

export const PopupBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.75;
    transition: opacity 275ms ease;

    &:hover {
        cursor: pointer;
        opacity: 0.65;
    }
`

// export const PopupContent = styled.div`
//     position: absolute;
//     width: ${(props) => (props.width ? props.width : '644px')};
//     max-height: 800px;
//     overflow: auto;
//     background: #fdfdfd;
//     border-radius: 24px;
// `

export const PopupContent = styled.div`
    position: absolute;
    max-height: ${(props) => props.maxHeight};
    overflow: ${({ overflow }) => overflow || 'auto'};
    background: #fdfdfd;
    border-radius: 24px;
    /* Стилі для компонента PopupContent */
    width: ${({ width }) => width}; /* Значення ширини за замовчуванням */

    @media (max-width: 480px) {
        width: ${({ mobileWidth }) => mobileWidth || '95%'}; /* Адаптована ширина для мобільних пристроїв */
        max-height: 800px;
    }
`
export const CloseButtonContainer = styled.div`
    position: absolute;
    right: 29px;
    top: 29px;
    height: 24px;
    z-index: 9;
`

export const CloseButton = styled.button`
    position: relative;
    padding: 0;
    margin: 0;
    background: none;
    border: none;

    transition: opacity 275ms ease;

    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
`

export const CloseButtonIcon = styled.img`
    position: relative;
`
