import styled from 'styled-components'
import { lightRedColor } from '../styles/colors'

export const ErrorContainer = styled.div`
    position: relative;
    margin: 14px 0 0 0;
    top: 10px;
    padding: 12px 24px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    background: ${lightRedColor};
`

export const ErrorMessage = styled.p`
    position: relative;
    font-size: 14px;
    color: #ffffff;
`
