import React, { memo } from 'react'
import { EditableSectionContainer, OutlineSectionContentContainer } from './styles'
import SectionTitle from './components/SectionTitle'
import TextWithInput from './components/outline-components/TextWithInput'
import { useState } from 'react'
import { useEffect } from 'react'
import OutlineSectionContent from './components/outline-components/OutlineSectionContent'

function OutlineSection({ isTitle, index, content, onDelete }) {
    const [subtitle, setSubtitle] = useState('')
    const [targetWordCount, setTargetWordCount] = useState('')

    useEffect(() => {
        if (content?.subtitle) {
            setSubtitle(content.subtitle || '')
        }
        if (content?.words) {
            setTargetWordCount(content.words || '')
        }
    }, [content])

    return (
        <EditableSectionContainer>
            <SectionTitle isTitle={isTitle} index={index} onDelete={onDelete} />
            <OutlineSectionContentContainer>
                <TextWithInput
                    title="Subtitle"
                    value={subtitle}
                    setValue={setSubtitle}
                    placeholder="Enter the title or we will create it automatically "
                />
                <OutlineSectionContent content={content?.content} />
                <TextWithInput
                    title="Target word count"
                    value={targetWordCount}
                    setValue={setTargetWordCount}
                    placeholder="Default is 500 words"
                />
            </OutlineSectionContentContainer>
        </EditableSectionContainer>
    )
}

export default memo(OutlineSection)
