import styled from 'styled-components'
import { white } from '../../styles/colors'
import { TextInput } from '../styles'

export const IconContainer = styled.div`
    position: absolute;
    left: 15px;
    z-index: 2;
`

export const DynamicTextInput = styled(TextInput)`
    width: ${(props) => (props.width ? props.width : '100%')};
    background: ${(props) => (props.background ? props.background : white)};
    padding: ${(props) => (props.withIcon ? '10px 14px 10px 45px' : '10px 14px')};
    border-radius: 8px;
`

export const RemoveIconContainer = styled.div`
    margin-right: 10px;
    margin-top: 5px;
`

export const RequiredFieldContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const OptionalFieldContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const AddFieldsContainer = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 10px;
    right: 0;
    position: absolute;
`

export const OptionalFieldLabelContainer = styled.div`
    margin-top: 20px;
`
