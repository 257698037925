import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import SearchField from '../../../common/form/search-field/SearchField'
import Wrapper from '../../../common/layouts/Wrapper/Wrapper'
import { MOCKED_IMAGES } from './utils'
import { GalleryImage, ImageGallery, ResponsiveContainer } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup, showPopup } from '../../../../reducers/popup'
import { PopupComponentKeys } from '../../../common/popup/Popup'
import { getArticleImages } from '../../slice'
import { selectArticle } from '../../../../reducers/articles'
import { throttle } from 'throttle-debounce'
import Skeleton from 'react-loading-skeleton'
import useWindowDimensions from '../../../common/hooks/useWindowDimensions'

function StockImageSelection({ onSelect }) {
    const article = useSelector(selectArticle)
    const [selectedImage, setSelectedImage] = useState(null)
    const [featuredImages, setFeaturedImages] = useState([])
    const [inputValue, setInputValue] = useState(article.history[0]?.featuredPhotoQuery)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const { isMobile } = useWindowDimensions()

    const switchPopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.IMAGE_SELECTION }))
        dispatch(
            showPopup({
                component: PopupComponentKeys.IMAGE_PREVIEW,
                styles: { overflow: 'visible' },
                extraData: { imageSet: featuredImages, image: selectedImage, onSelect },
            }),
        )
    }

    const imageComponents = useMemo(() => {
        return featuredImages.map((image, index) => (
            <GalleryImage key={index} src={image.url} alt={image.altText} onClick={() => setSelectedImage(image)} />
        ))
    }, [featuredImages])

    const throttleGetUpdatedImages = throttle(2000, (value) => {
        dispatch(getArticleImages(encodeURI(value)))
            .unwrap()
            .then((data) => {
                setFeaturedImages(data.slice(0, isMobile ? 10 : 9))
            })
            .finally(() => setLoading(false))
    })

    const onChange = useCallback(
        (value) => {
            setLoading(true)
            setInputValue(value)
            throttleGetUpdatedImages(value || article.history[0]?.featuredPhotoQuery)
        },
        [article],
    )

    useEffect(() => {
        if (!article) return
        dispatch(getArticleImages(encodeURI(article.history[0]?.featuredPhotoQuery)))
            .unwrap()
            .then((data) => setFeaturedImages(data.slice(0, isMobile ? 10 : 9)))
            .finally(() => setLoading(false))
    }, [article, isMobile])

    useEffect(() => {
        if (selectedImage) {
            switchPopupAction()
        }
    }, [selectedImage])

    return (
        <Wrapper>
            <ResponsiveContainer>
                <SearchField width="100%" height="48px" value={inputValue} changeEvent={(value) => onChange(value)} />
                <Wrapper>
                    {loading ? (
                        <Skeleton
                            count={9}
                            width={isMobile ? '48%' : '32%'}
                            height={141}
                            inline={true}
                            containerClassName="skeleton-container"
                        />
                    ) : (
                        <ImageGallery>{imageComponents}</ImageGallery>
                    )}
                </Wrapper>
            </ResponsiveContainer>
        </Wrapper>
    )
}

export default memo(StockImageSelection)
