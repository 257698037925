import React, { memo, useMemo } from 'react'
import { RegularText } from '../../common/styles/text'
import { PopupContent } from '../styles'
import { useDispatch } from 'react-redux'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { ErrorMessagesContainer, ErrorPopupButtonContainer, MainInformationContainer } from './styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import ReferenceIcon from '../../../assets/images/reference.svg'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import { showPopup } from '../../../reducers/popup'
import { redOrange } from '../../common/styles/colors'

function CsvFormatErrorPopup({ extraData }) {
    const dispatch = useDispatch()

    const handleOpenCsvInstruction = async () => {
        dispatch(showPopup({ component: PopupComponentKeys.UPLOAD_CSV_INSTRUCTION }))
    }

    const errorText = useMemo(() => {
        return extraData.errorMessages.map((error) => (
            <RegularText color={redOrange} key={error.index}>
                Error in row {error.index + 1}: {error.message}
            </RegularText>
        ))
    }, [extraData])

    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    CSV format error
                </RegularText>
            </div>
            <MainInformationContainer>
                <RegularText lineHeight="24px" fontWeight={400}>
                    There is something wrong with your CSV file. Edit the CSV and please try again
                </RegularText>
                <ErrorMessagesContainer direction="column" spacing="6px">
                    {errorText}
                </ErrorMessagesContainer>
            </MainInformationContainer>
            <ErrorPopupButtonContainer>
                <SimpleButton
                    action={handleOpenCsvInstruction}
                    text={
                        <>
                            Open CSV instructions <StyledSVG src={ReferenceIcon} margin="0 0 0 8px" />
                        </>
                    }
                    margin={0}
                />
            </ErrorPopupButtonContainer>
        </PopupContent>
    )
}

export default memo(CsvFormatErrorPopup)
