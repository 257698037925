import React, { memo } from 'react'
import { RegularText } from '../common/styles/text'
import { TabContainer, TabsContainer } from './styles'
import { black } from '../common/styles/colors'
import { settingsTabs } from '../../utils/utils'

function Tabs({ activeTab, setActiveTab }) {
    return (
        <TabsContainer>
            {settingsTabs.map((tab) => (
                <TabContainer key={tab.key} onClick={() => setActiveTab(tab.key)} active={activeTab === tab.key}>
                    <RegularText lineHeight={'24px'} color={black}>
                        {tab.title}
                    </RegularText>
                </TabContainer>
            ))}
        </TabsContainer>
    )
}

export default memo(Tabs)
