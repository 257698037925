import styled, { css } from 'styled-components'
import { white } from '../styles/colors'

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
`

export const MethodsListTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;

    & svg {
        width: 24px;
        height: 24px;
        transition: 0.2s;

        ${(props) =>
            props.isOpen &&
            css`
                transform: rotate(180deg);
            `}
    }
`

export const AccordionWrapper = styled.div`
    width: 500px;
    margin-top: 16px;
`

export const SavedPaymentMethodsWrapper = styled.div`
    margin-bottom: 16px;
`

export const SavedPaymentMethodsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const SavedPaymentMethodContainer = styled.div`
    width: 452px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    background: ${white};
    margin: 8px 0;
    padding: 16px 24px;
    border-radius: 8px;
    cursor: pointer;
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
`

export const MethodInfoContainer = styled.div``
