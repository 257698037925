import styled from 'styled-components'
import OutOfCreditBackground from '../../assets/images/out-of-credit-button.svg'
import { Link } from 'react-router-dom'
import { white } from '../common/styles/colors'

export const PopupContent = styled.div`
    padding: 42px;

    @media (max-width: 480px) {
        padding: 16px;
        & form {
            width: 100%;
        }
    }
`

export const SeparateLineContainer = styled.div`
    border: 1px dashed #d5d0cd;
    box-sizing: border-box;
`

export const OptionalInputsCollapseHeaderContainer = styled.div`
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const OptionalInputsTitleContainer = styled.div`
    display: flex;
    height: 40px;
    justify-content: space-between;
`

export const OutOfCreditContainer = styled.div`
    margin-top: 40px;
    height: 56px;
    background-image: url(${OutOfCreditBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
`

export const OutOfCreditLinkContainer = styled(Link)`
    margin-left: 10px;
    font-family: 'Inter';
    font-weight: 500;
    color: ${white};
    font-size: 16px;
`

export const ButtonsContainer = styled.div`
    position: relative;
    padding-top: 20px;
    width: 210px;
    margin: 0 auto;
`

export const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    margin-top: 24px;
`
export const LabelParenthetic = styled.span`
    margin-left: 5px;
    color: rgba(0,0,0,.75);
    font-size: .9em;
`
