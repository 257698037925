import React, { memo, useEffect, useMemo, useState } from 'react'
import PrivateArea from '../common/layouts/private-area/PrivateArea'
import {
    AmountContainer,
    AmountOptionContainer,
    AmountOptionsContainer,
    AutoPaymentContainer,
    BackButtonContainer,
    BonusContainer,
    ButtonContainer,
    CreditContainer,
    CurrentCreditAmountContainer,
    CurrentCreditContainer,
    CurrentCreditTitleContainer,
    IncreaseCreditContainer,
    IncreaseCreditHeaderContainer,
    IncreaseCreditTitleContainer,
    MostPopularAmountOptionContainer,
    MostPopularMarkOptionContainer,
    MostPopularOptionContainer,
    SeparateLine,
    SwitchContainer,
} from './styles'
import { RegularText } from '../common/styles/text'
import StyledSVG from '../common/styled-svg/StyledSVG'
import BackIcon from '../../assets/images/back.svg'
import DatabaseIcon from '../../assets/images/database.svg'
import { orange, white } from '../common/styles/colors'
import SimpleButton from '../common/button/simple-button/SimpleButton'
import ToggleSwitch from '../common/switch/ToggleSwitch'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CREDIT_PACKS, ROUTES } from '../../utils/utils'
import { selectPaymentInformation } from '../../reducers/user'
import { selectAvailableProducts as selectAvailableCreditPackProducts } from '../../reducers/credit-packs'
import { useCurrentSubscriptionProduct } from '../common/hooks'
import { creditPacksProductsList } from './slice'

const BASE_CREDIT_COST_CENTS = 10
function Credit() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const payment = useSelector(selectPaymentInformation)
    const creditPacks = useSelector(selectAvailableCreditPackProducts)
    const currentSubscriptionProduct = useCurrentSubscriptionProduct()
    const [credits, setCredits] = useState(0)
    const discountLevel = useMemo(() => (
        currentSubscriptionProduct?.metadata?.discount_percent || 0
    ), [currentSubscriptionProduct])
    const availableCreditPacks = useMemo(() => (creditPacks || []).filter(c => {
        return c.metadata.discount_percent == discountLevel
    }).map(c => {
        const price = c.default_price.unit_amount / 100;
        const costPerCreditCents = c.default_price.unit_amount / Number.parseInt(c.metadata.credits || 0)
        const discountPercent = Math.round(100*(1 - (costPerCreditCents / BASE_CREDIT_COST_CENTS)))
        return Object.assign({}, c, { price, discountPercent })
    }), [creditPacks, discountLevel]);

    useEffect(() => {
        if (payment) {
            setCredits(payment.credits)
        }
    }, [payment])

    useEffect(() => {
        dispatch(creditPacksProductsList())
    }, [])

    const handleToggle = (isChecked) => {
        console.log('Switch is toggled:', isChecked)
    }

    const addCreditAction = (productId, creditAmount, price) => {
        navigate(ROUTES.CREDIT_CHECKOUT_STRIPE, { state: { productId, creditAmount, price } })
    }

    return (
        <PrivateArea>
            <CreditContainer>
                <BackButtonContainer onClick={() => navigate(-1)}>
                    <StyledSVG src={BackIcon} />
                    <RegularText>&nbsp;&nbsp;Back</RegularText>
                </BackButtonContainer>
                <CurrentCreditContainer>
                    <CurrentCreditTitleContainer>
                        <RegularText fontSize={'14px'} color={'rgba(255, 255, 255, 0.6);'} letterSpacing={'0.02em'}>
                            CURRENT AMOUNT
                        </RegularText>
                    </CurrentCreditTitleContainer>
                    <CurrentCreditAmountContainer>
                        <RegularText fontSize={'40px'} lineHeight={'48px'} color={white}>
                            Your Credits
                        </RegularText>
                        <AmountContainer>
                            <StyledSVG width={30} height={30} src={DatabaseIcon} />
                            <RegularText fontSize={'40px'} lineHeight={'48px'} color={white}>
                                &nbsp; {credits}
                            </RegularText>
                        </AmountContainer>
                    </CurrentCreditAmountContainer>
                </CurrentCreditContainer>
                <IncreaseCreditHeaderContainer>
                    <RegularText fontSize={'32px'} lineHeight={'40px'}>
                        Top Up Credits
                    </RegularText>
                </IncreaseCreditHeaderContainer>
                <IncreaseCreditContainer>
                    <IncreaseCreditTitleContainer>
                        <RegularText fontSize={'24px'} lineHeight={'32px'}>
                            Choose Amount
                        </RegularText>
                    </IncreaseCreditTitleContainer>
                    <AmountOptionsContainer>
                        {availableCreditPacks.map((creditPack, i) =>
                            i === 1 ? (
                                <MostPopularOptionContainer key={creditPack.id}>
                                    <MostPopularMarkOptionContainer>
                                        <RegularText fontSize="14px" lineHeight="16px" color="#FF6A17">
                                            MOST POPULAR
                                        </RegularText>
                                    </MostPopularMarkOptionContainer>
                                    <MostPopularAmountOptionContainer>
                                        <div>
                                            <RegularText fontSize="28px" lineHeight="32px" fontWeight={700}>
                                                {creditPack.metadata.credits} Credits
                                            </RegularText>
                                            <BonusContainer>
                                                <RegularText lineHeight="24px" fontWeight={400} color="#8B8581">
                                                    {creditPack.discountPercent > 0 ? `${creditPack.discountPercent}% off` : 'No discount'}
                                                </RegularText>
                                            </BonusContainer>
                                        </div>
                                        <div>
                                            <RegularText lineHeight="24px">Price: ${creditPack.price}</RegularText>
                                        </div>
                                        <SimpleButton
                                            width="80%"
                                            action={() => addCreditAction(creditPack.id, creditPack.metadata.credits, creditPack.price)}
                                            text="Purchase"
                                        />
                                    </MostPopularAmountOptionContainer>
                                </MostPopularOptionContainer>
                            ) : (
                                <AmountOptionContainer key={creditPack.id}>
                                    <div>
                                        <RegularText fontSize="28px" lineHeight="32px" fontWeight={700}>
                                            {creditPack.metadata.credits} Credits
                                        </RegularText>
                                        <BonusContainer>
                                            <RegularText lineHeight="24px" fontWeight={400} color="#8B8581">
                                                {creditPack.discountPercent > 0 ? `${creditPack.discountPercent}% off` : 'No discount'}
                                            </RegularText>
                                        </BonusContainer>
                                    </div>
                                    <div>
                                        <RegularText lineHeight="24px">Price: ${creditPack.price}</RegularText>
                                    </div>
                                    <SimpleButton
                                        width="80%"
                                        action={() => addCreditAction(creditPack.id, creditPack.metadata.credits, creditPack.price)}
                                        text="Purchase"
                                    />
                                </AmountOptionContainer>
                            ),
                        )}
                    </AmountOptionsContainer>
                    {/* <SeparateLine />
                    <AutoPaymentContainer>
                        <RegularText fontSize={'24px'} lineHeight={'32px'}>
                            Choose amount
                        </RegularText>
                        <RegularText lineHeight={'24px'} fontWeight={400}>
                            Auto payment adds <b>100</b> for <b>$ 10</b> automatically when your balance small
                        </RegularText>
                        <SwitchContainer>
                            <ToggleSwitch isChecked={false} onChange={handleToggle} />
                        </SwitchContainer>
                    </AutoPaymentContainer> */}

                    {/* <ButtonContainer>
                        <SimpleButton color={white} action={() => {}} text="Top up" />
                    </ButtonContainer> */}
                </IncreaseCreditContainer>
            </CreditContainer>
        </PrivateArea>
    )
}

export default memo(Credit)
