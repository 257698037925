
export const parseCsvToArray = (str) => {
    const rows = str.replace(/\r/, '').replace(/("[^"\n]*)\r?\n(?!(([^"]*"){2})*[^"]*$)/g, '$1 ').split(/\n/)

    const [headerRow, ...valueRows] = rows

    const keys = getAllColumns(headerRow).map(sanitizeKey)

    const result = valueRows.reduce((res, row) => {
        const values = getAllColumns(sanitizeValue(row)) // split all by comas except values in double quotes

        if (values.length !== keys.length) return res

        const data = convertKeyValuesToObject(keys, values)

        return [...res, data]
    }, [])

    return result
}

const CSV_ROW_REGEX = /(?:,"|^")(""|[\w\W]*?)(?=",|"$)|(?:,(?!")|^(?!"))([^,]*?)(?=$|,)|(\r\n|\n)/g
const getAllColumns = (rowText) => {
    rowText = rowText.trim()
    if(!rowText) return []
    
    const values = []
    let match
    while(match = CSV_ROW_REGEX.exec(rowText)) {
        values.push(match[1] || match[2] || match[3] || '')
    }
    return values
}


// convert array of keys and array of values into object { [key1]: value1... }
function convertKeyValuesToObject(keys, values) {
    return keys.reduce((res, key, index) => {
        const value = values[index]

        if (shouldBeParsedAsArray(value, key)) {
            // for case with links
            res[key] = value.replace(/[" ]/g, '').split(',').filter(v => !!v)
        } else {
            res[key] = value
        }
        return res
    }, {})
}

// remove asterisks and values in brackets
function sanitizeKey(string) {
    return string.replace(/[*]| \([^)]*\)|\(s\)/g, '')
}

// remove /r
function sanitizeValue(string) {
    return string.replace(/\r/, '')
}

// check if string value is wrapper in double quotes
function shouldBeParsedAsArray(str, key) {
    return /^".*"$/.test(str) || key.toLowerCase() === 'links'
}
