import React, { memo } from 'react'
import { ContentContainer, MainContent } from './styles'
import Header from './header/Header'
import { useSelector } from 'react-redux'
import Loading from '../../loading/Loading'

const PrivateArea = ({ children }) => {
    const showLoading = useSelector((state) => state.common.isProcessingRequest)
    return (
        <ContentContainer>
            <Header />
            <MainContent>
                {showLoading ? <Loading position="absolute" /> : ''}
                {children}
            </MainContent>
        </ContentContainer>
    )
}

export default memo(PrivateArea)
