import React, { memo, useState } from 'react'
import PrivateArea from '../common/layouts/private-area/PrivateArea'
import { RegularText } from '../common/styles/text'
import { ContentContainer, HeaderContainer } from './styles'
import { black } from '../common/styles/colors'
import Tabs from './Tabs'
import General from './pages/General'
import Company from './pages/Company'
import PlanAndBilling from './pages/PlanAndBilling'
import { useCreditRefresher } from '../common/hooks/useCreditRefresher'
import { useHash } from '../common/hooks/useHash'

function Settings() {
    useCreditRefresher()
    const [activeTab, setActiveTab] = useHash({ defaultHash: 'general' })

    const tabsContent = {
        general: <General />,
        company: <Company />,
        plan_and_billing: <PlanAndBilling />,
    }

    const correctedActiveTab = !tabsContent[activeTab] ? 'general' : activeTab
    return (
        <PrivateArea>
            <HeaderContainer>
                <RegularText fontSize={'32px'} lineHeight={'40px'} color={black}>
                    Settings
                </RegularText>
            </HeaderContainer>
            <Tabs activeTab={correctedActiveTab} setActiveTab={setActiveTab} />
            <ContentContainer>{tabsContent[correctedActiveTab]}</ContentContainer>
        </PrivateArea>
    )
}

export default memo(Settings)
