import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { confirmNewPassword, requestChangePassword } from '../../components/user/forgot-password/slice'
import { updateProfile } from '../../components/settings/slice'
import { Status } from '../../utils/utils'

const initialState = {
    profile: {
        firstName: '',
        lastName: '',
    },
    validationErrors: {},
    globalError: null,
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

function success(state) {
    state.status = Status.SUCCESS
    delete state.globalError
}

const profileFormSlice = createSlice({
    name: 'forms.profileForm',
    initialState,
    extraReducers(builder) {
        builder.addCase(updateProfile.rejected, failed)
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            success(state)
        })
    },
    reducers: {
        changeFirstNameValue: (state, action) => {
            state.profile.firstName = action.payload.firstName
        },
        changeLastNameValue: (state, action) => {
            state.profile.lastName = action.payload.lastName
        },
        setProfile: (state, action) => {
            state.profile.firstName = action.payload.firstName
            state.profile.lastName = action.payload.lastName
        },
        resetError: (state) => {
            state.globalError = null
            state.validationErrors = {}
        },
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        setValidationError: (state, action) => {
            state.validationErrors[[action.payload.field]] = action.payload.message
        },
        resetForm: () => initialState,
    },
})

const selectProfileFormSlice = (state) => state.forms.profileForm
export const selectProfileFormData = (state) => selectProfileFormSlice(state).profile
export const selectRequestStatus = (state) => selectProfileFormSlice(state).status
export const selectSignInGlobalError = (state) => selectProfileFormSlice(state).globalError
export const selectValidationErrors = (state) => selectProfileFormSlice(state).validationErrors

const { reducer, actions } = profileFormSlice

export const {
    changeFirstNameValue,
    changeLastNameValue,
    resetError,
    setProfile,
    setValidationError,
    changeRequestStatus,
    resetForm,
} = actions

export default reducer
