import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ForgotPasswordLinkContainer = styled.div`
    position: relative;
    display: flex;
    margin-top: 32px;
    justify-content: center;
    width: 100%;
`

export const ForgotPasswordLink = styled(Link)`
    color: #282828;
    font-size: 16px;
    font-weight: 600;
`
