const ERROR_MESSAGES = {
    keywordPhraseErrorText: 'Keyword phrase is empty or exceeds 300 characters.',
    companyErrorText: 'Invalid company selected. Please choose a valid company.',
    wordCountErrorText: 'Word count must be between 500 and 3000 words.',
    articleToneErrorText: 'Invalid tone specified. Please choose a tone from the approved list.',
    callToActionLengthErrorText: 'Call-to-action (CTA) exceeds 300 characters. Please limit to 300 characters.',
    callToActionNoUrlErrorText:
        'A Call-to-action (CTA) has been specified, but a corresponding CTA URL is missing. Please provide a valid CTA URL to accompany the CTA.',
    callToActionUrlErrorText: 'Call-to-action (CTA) URL is empty or exceeds 500 characters.',
    additionalDetailsErrorText: 'Additional Details exceeds 500 characters.',
    additionalKeywordsErrorText: 'Additional Keywords exceed 500 characters total.',
    optionalLinksAmountErrorText: 'Exceeded the maximum limit of optional links. Please provide no more than 20 links.',
    optionalLinksLengthErrorText:
        'One or more optional links exceed the maximum length of 500 characters. Please shorten the links so that each is under 500 characters.',
}

const POSSIBLE_TONES = [
    'professional',
    'fun',
    'friendly',
    'instructive',
    'persuasive',
    'conversational',
    'inspirational',
    'authoritative',
    'urgent',
]

export const validateArticlePayload = (article) => {
    switch (true) {
        case !article.keywords || article.keywords.length > 300:
            return ERROR_MESSAGES.keywordPhraseErrorText
        case !article.companyUuid:
            return ERROR_MESSAGES.companyErrorText
        case article.words !== null && !(500 <= article.words && article.words <= 3000):
            return ERROR_MESSAGES.wordCountErrorText
        case article.tone && !POSSIBLE_TONES.includes(article.tone):
            return ERROR_MESSAGES.articleToneErrorText
        case article.callToActionTitle && article.callToActionTitle.length > 300:
            return ERROR_MESSAGES.callToActionLengthErrorText
        case article.callToActionTitle && !article.callToActionLink:
            return ERROR_MESSAGES.callToActionNoUrlErrorText
        case article.callToActionLink && article.callToActionLink.length > 500:
            return ERROR_MESSAGES.callToActionUrlErrorText
        case article?.links?.length > 20:
            return ERROR_MESSAGES.optionalLinksAmountErrorText
        case article?.links?.length > 0 && !(article?.links || []).every(link => link.length <= 500):
            return ERROR_MESSAGES.optionalLinksLengthErrorText
        case article.additionalDetails && article.additionalDetails.length > 500:
            return ERROR_MESSAGES.additionalDetailsErrorText
        case article.additionalKeywords && article.additionalKeywords.length > 500:
            return ERROR_MESSAGES.additionalKeywordsErrorText
        
        return null
    }
}
