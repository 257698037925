import React, { memo, useMemo } from 'react'
import SelectboxField from '../../common/form/selectbox/SelectboxField'
import CompaniesList from './side-menu/CompaniesList'
import { useSelector } from 'react-redux'
import { CompanyStatus } from '../../../utils/utils'
import FolderIcon from '../../../assets/images/folder.svg'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import { CompanyFolderDropdownValueContainer } from './styles'

function CompanyFolderDropdown({ handleSelectCompany, companyUuid, folderUuid, withoutTitle }) {
    const userCompanies = useSelector((state) => state.user.companies)
    const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)

    const companies = useMemo(
        () => userCompanies.filter(({ status }) => status === CompanyStatus.ACTIVE),
        [userCompanies],
    )

    const companyDropdownValue = useMemo(() => {
        if (companyUuid) {
            const currentCompany = companies.find((company) => company.uuid === companyUuid)
            let valueString = currentCompany?.name
            let folderValueString

            if (folderUuid) {
                folderValueString = (currentCompany?.folders || []).find((folder) => folder.uuid === folderUuid)?.name
            }

            return (
                <CompanyFolderDropdownValueContainer>
                    {valueString}
                    {folderValueString ? ',' : null}
                    {folderValueString && (
                        <CompanyFolderDropdownValueContainer>
                            <StyledSVG src={FolderIcon} />
                            {folderValueString}
                        </CompanyFolderDropdownValueContainer>
                    )}
                </CompanyFolderDropdownValueContainer>
            )
        }

        return companies.find((company) => company?.uuid === activeCompanyUuid)?.name
    }, [companyUuid, folderUuid, companies, activeCompanyUuid])

    return (
        <SelectboxField
            label={!withoutTitle && <>Company and Folder</>}
            useDefaultValue
            selectedValue={companyDropdownValue}
            changeValueEvent={(value, fieldName) => handleSelectCompany(fieldName, value)}
            options={companies}
            customDropdownComponent={
                <CompaniesList
                    withoutOptions
                    companyData={companies}
                    handleCompanySelection={(uuid) => handleSelectCompany('companyUuid', uuid)}
                    handleFolderSelection={(uuid) => handleSelectCompany('folderUuid', uuid)}
                />
            }
        />
    )
}

export default memo(CompanyFolderDropdown)
