import { useDispatch, useSelector } from "react-redux";
import { selectAvailableProducts, selectCurrentSubscription } from "../../reducers/subscription";
import { memo, useEffect, useMemo, useState } from "react";
import {
    changeRequestStatus as changeSubscriptionRequestStatus
} from '../../reducers/subscription'
import {
    changeRequestStatus as changeUserRequestStatus,
    selectRequestStatus as selectUserRequestStatus,
} from '../../reducers/user'
import { cancelSubscription, resumeSubscription, subscribeFreePlan, subscriptionDetails, subscriptionProductsList } from "../settings/slice";
import {
    PlansListContainer,
    PlanInformationContainer,
    PlanHeaderContainer,
    PlanDescriptionContainer,
    PlanDetailsContainer,
    PlanDetailContainer,
    PlanButtonContainer,
} from './styles'
import CheckIcon from '../../assets/images/check.svg'
import { REQUEST_STATUSES } from "../../reducers/reducer-helper";
import { RegularText } from "../common/styles/text";
import StyledSVG from "../common/styled-svg/StyledSVG";
import TransparentBorderButton from "../common/button/transparent-border-button/TransparentBorderButton";
import SimpleButton from "../common/button/simple-button/SimpleButton";
import { black, darkGray, naturalGray, orange, white } from '../common/styles/colors'
import { useNavigate } from "react-router";
import { ROUTES } from "../../utils/utils";
import { useCurrentSubscriptionProduct } from "../common/hooks";
import { showPopup } from "../../reducers/popup";
import { PopupComponentKeys } from "../common/popup/Popup";
import { NotificationManager } from "react-notifications";

function PlanSelector () {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const availableProducts = useSelector(selectAvailableProducts)
    const currentSubscriptionProduct = useCurrentSubscriptionProduct()
    const currentSubscription = useSelector(selectCurrentSubscription)

    const subscribe = async (productId, period, price) => {
        navigate(ROUTES.SUBSCRIPTION_CHECKOUT_STRIPE, { state: { productId, period, price } })
    }

    const resumeSubscriptionAction = async (subscriptionId) => {
        dispatch(changeSubscriptionRequestStatus({ status: REQUEST_STATUSES.PENDING }))
        dispatch(resumeSubscription({ subscriptionId }))
    }

    const subscribeFreePlanAction = async (productId) => {
        if(location.pathname !== ROUTES.SELECT_SUBSCRIPTION) {
            if(currentSubscription?.cancel_at) {
                NotificationManager.error("Your subscription has already been canceled and will expire at the end of the subscription cycle.")
            } else if(currentSubscription && currentSubscriptionProduct.metadata.free === "false") {
                dispatch(showPopup({ component: PopupComponentKeys.CANCEL_SUBSCRIPTION }))
            }
        } else {
            navigate(ROUTES.ARTICLES)
        }
    }

    const filteredProducts = useMemo(() => (
        availableProducts
            .slice()
            .filter(p => p.active)
            .sort((a,b) => Number.parseInt(a.metadata?.sort || 0) - Number.parseInt(b.metadata?.sort || 0))
            .slice(0, 3)
    ), [availableProducts])

    return (<PlansListContainer>
        {filteredProducts.length > 0 &&
            filteredProducts.map((product, i) => {
                const isCurrentPlan = location.pathname !== ROUTES.SELECT_SUBSCRIPTION && currentSubscriptionProduct && currentSubscriptionProduct.id === product.id;
                const isOdd = i % 2 === 0;
                const isFree = product.metadata.free === 'true';
                const [title, subtitle] = product.metadata?.title?.split('\n');
                const [price, period] = product.description?.split(' per ');
                const features = product.metadata?.features?.split('\n');
                let planButton = (<SimpleButton
                    background={black}
                    color={white}
                    action={() => isFree ? subscribeFreePlanAction(product.id) : subscribe(product.id, title, price)}
                    text="Choose"
                />)
                if(isCurrentPlan) {
                    planButton = currentSubscription?.cancel_at ? (
                        <TransparentBorderButton
                            action={() => resumeSubscriptionAction(currentSubscription.id)}
                            color={isOdd ? '#282828' : white}
                            text="Resume?"
                        />
                    ) : (
                        <TransparentBorderButton
                            disabled={true}
                            color={isOdd ? '#282828' : white}
                            text="Current plan"
                        />
                    )
                }
                return (
                    <PlanInformationContainer color={isOdd ? undefined : orange} key={product.id}>
                        <PlanHeaderContainer>
                            <RegularText fontSize={'24px'} lineHeight={'32px'} fontWeight={700} color={isOdd ? undefined : white}>
                                {title}
                            </RegularText>
                            <RegularText lineHeight={'24px'} fontWeight={400} color={isOdd ? darkGray : 'rgba(255, 255, 255, 0.8)'}>
                                {subtitle}
                            </RegularText>
                        </PlanHeaderContainer>
                        <PlanDescriptionContainer>
                            {isFree ? (
                                <RegularText fontSize={'32px'} lineHeight={'40px'}>
                                    Free
                                </RegularText>
                            ) : (<>
                                <RegularText fontSize={'32px'} lineHeight={'48px'} color={isOdd ? undefined : white}>
                                    {price}&nbsp;
                                </RegularText>
                                <RegularText lineHeight={'24px'} fontWeight={400} color={isOdd ? undefined : white}>
                                    per {period}
                                </RegularText>
                            </>)}
                            <PlanDetailsContainer>
                                {features.map(feature => (
                                    <PlanDetailContainer key={feature}>
                                        <StyledSVG color={isOdd ? undefined : white} src={CheckIcon} />
                                        <RegularText color={isOdd ? undefined : white} lineHeight={'24px'} fontWeight={400}>
                                            &nbsp;&nbsp;{feature}
                                        </RegularText>
                                    </PlanDetailContainer>
                                ))}
                            </PlanDetailsContainer>
                        </PlanDescriptionContainer>
                        <PlanButtonContainer>
                            {planButton}
                        </PlanButtonContainer>
                    </PlanInformationContainer>
                )
            })}
    </PlansListContainer>);
}

export default memo(PlanSelector)