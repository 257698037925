import React, { memo } from 'react'
import { ActionContainer, BottomContainer, HeaderContainer, KeywordsContainer, StatusContainer } from '../styles'
import { CardSubtitleText, ErrorText, RegularText } from '../../../common/styles/text'
import { black, darkGray, vividRed } from '../../../common/styles/colors'
import { PopupComponentKeys } from '../../../common/popup/Popup'
import { showPopup } from '../../../../reducers/popup'
import { useDispatch } from 'react-redux'
import { EditArticleMenu } from './EditArticleMenu'

function NotEnoughCreditArticle({ article }) {
    const dispatch = useDispatch()

    const cancelPopup = (event) => {
        event.stopPropagation()
        dispatch(
            showPopup({ component: PopupComponentKeys.CANCEL_ARTICLE, extraData: { articleId: article.id } }),
        )
    }

    return (
        <>
            <EditArticleMenu article={article} allowDelete={false} />
            <HeaderContainer>
                <ErrorText>Not enough credits</ErrorText>
            </HeaderContainer>
            <KeywordsContainer>
                <CardSubtitleText>KEYWORD PHRASE:</CardSubtitleText>
                <RegularText lineHeight="24px" fontWeight="400" color={darkGray}>
                    {article.keywords}
                </RegularText>
            </KeywordsContainer>
            <BottomContainer>
                <StatusContainer>
                    <RegularText fontSize="14px" lineHeight="24px" color={vividRed}>
                        PAUSED
                    </RegularText>
                </StatusContainer>
                <ActionContainer onClick={cancelPopup}>
                    <RegularText lineHeight="24px" color={black}>
                        Cancel
                    </RegularText>
                </ActionContainer>
            </BottomContainer>
        </>
    )
}

export default memo(NotEnoughCreditArticle)
