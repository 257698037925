import { request } from './agent'

export const ArticleAgent = {
    get: (articleId) => request.get(`/article/${articleId}`),
    getList: () => request.get('/article/list'),
    create: (data) => request.post('/article/create', data),
    move: (articleId, data) => request.post(`/article/${articleId}/move-to-folder`, data),
    resume: (articleId) => request.post(`/article/${articleId}/regenerate`),  //todo: refactor it
    regenerate: (articleId) => request.post(`/article/${articleId}/regenerate`), //todo: refactor it
    delete: (articleId) => request.post(`/article/${articleId}/delete`),
    humanize: (articleId) => request.post(`/article/${articleId}/humanize`),
    pause: (articleId) => request.post(`/article/${articleId}/pause`),
    createBulk: (articles) => request.post(`/article/bulk/create`, { articles }),
    getFeaturedImages: (query) => request.get(`/article/photos?query=${query}`),
    setFeaturedImage: (articleId, data) => request.post(`/article/${articleId}/set-featured-photo`, data),
    getLocationList: () => request.get('/article/locations/list'),
    getLanguageList: () => request.get('/article/languages/list'),
}
