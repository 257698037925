import React, { memo, useCallback, useMemo } from 'react'
import { DetailsContainer, MethodInfoContainer, SavedPaymentMethodContainer } from './styles'
import Checkbox from '../switch/Checkbox'
import { RegularText } from '../styles/text'

function SavedPaymentMethodItem({ id, methodTitle, card, cardType, isSelected, setSelected }) {
    const handleClick = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            setSelected(id)
        },
        [isSelected, setSelected, id],
    )

    return (
        <SavedPaymentMethodContainer onClick={handleClick}>
            <Checkbox isChecked={isSelected} />
            <DetailsContainer>
                <RegularText>{methodTitle}</RegularText>
                <MethodInfoContainer>
                    <RegularText>{cardType}</RegularText>
                    <RegularText>{card}</RegularText>
                </MethodInfoContainer>
            </DetailsContainer>
        </SavedPaymentMethodContainer>
    )
}

export default memo(SavedPaymentMethodItem)
