import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './components/app/App'
import store from './app/store'

import './assets/styles/main.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/icon.min.css'

createRoot(document.getElementById('root')).render(
    <>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </>,
)
