import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LogInLinkContainer = styled.div`
    position: relative;
    display: flex;
    margin-top: 32px;
    justify-content: center;
    width: 100%;
    align-items: baseline;
`

export const LogInLink = styled(Link)`
    color: #282828;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
`

export const ValidationRulesContainer = styled.div`
    margin-top: 12px;
`

export const RuleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
`
export const EulaText = styled.div`
    height: 300px;
    overflow-y: auto;
    margin-bottom: 25px;

    p {
        margin: 10px;
    }

    ul {
        list-style-type: disc;
        margin-left: 40px;
    }
`

export const EulaWarningText = styled.div`
    color: rgba(0,0,0, .75);
    font-size: .85em;
    margin-top: .75em;
`