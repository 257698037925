import agent from '../api/agent'
import { googleAuth, signIn } from '../components/user/sign-in/slice'
import { logout } from '../components/common/layouts/private-area/header/user-popover/slice'
import { confirmEmail } from '../components/user/sign-up/slice'
import { tagEvent } from '../utils/tagEvent'

const localStorageMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case signIn.fulfilled.type:
        case confirmEmail.fulfilled.type:
        case googleAuth.fulfilled.type:
            window.localStorage.setItem('api-token', action.payload.apiToken)
            agent.setApiToken(action.payload.apiToken)
            tagEvent('login')
            break

        case logout.fulfilled.type:
            window.localStorage.removeItem('api-token')
            agent.setApiToken(undefined)
            break
    }

    return next(action)
}

export { localStorageMiddleware }
