import React, { memo, useMemo, useState } from 'react'
import { CollapsedParametersContainer, CollapseParametersContainer, LinkContainer, ParametersContainer } from './styles'
import { RegularText } from '../../common/styles/text'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import ArrowDownIcon from '../../../assets/images/arrow-down.svg'
import UrlIcon from '../../../assets/images/url.svg'
import ArrowUpIcon from '../../../assets/images/arrow-up.svg'
import { black, darkGray, naturalGray } from '../../common/styles/colors'
import { useArticleLocations } from '../../common/hooks/useArticleLocations'
import { useArticleLanguages } from '../../common/hooks/useArticleLanguages'

function ParametersDetails({ currentArticle }) {
    const [parametersShow, setParametersShow] = useState(false)
    const articleLocations = useArticleLocations();
    const articleLocation = useMemo(() => (
        articleLocations.find((location) => location.code === currentArticle?.parameters?.locationId)
    ), [currentArticle, articleLocations]);
    const additionalKeywords = useMemo(() => (
        (currentArticle?.parameters?.additionalKeywords || '').split(', ').map(k => k.slice(1,-1))
    ), [currentArticle]);

    return (
        <ParametersContainer>
            <CollapseParametersContainer onClick={() => setParametersShow(!parametersShow)}>
                <RegularText>Parameters</RegularText>
                {parametersShow ? (
                    <StyledSVG src={ArrowUpIcon} />
                ) : (
                    <StyledSVG color={black} width={12} height={9} src={ArrowDownIcon} />
                )}
            </CollapseParametersContainer>
            {parametersShow && (
                <CollapsedParametersContainer>
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Keyword Phrase
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.keywords}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Company Name
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.companyName || ''}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    {/* <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Article title
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.articleTitle || ''}
                        </RegularText>
                    </div>
                    <br />
                    <br /> */}
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Target Word Count
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.words || ''}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Target Geo Location
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {articleLocation?.name || ''}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Language
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle?.parameters?.language?.name ?? ''}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Tone of Article
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.tone || ''}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Call to Action
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.callToActionTitle || ''}
                        </RegularText>
                        <br />
                        {currentArticle.parameters?.callToActionLink && <LinkContainer>
                            <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                                <StyledSVG src={UrlIcon} />
                                &ensp; {currentArticle.parameters?.callToActionLink || ''}
                            </RegularText>
                        </LinkContainer>}
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Additional Keywords
                        </RegularText>
                        <br />
                        {additionalKeywords.length > 0 &&
                            additionalKeywords.map((keyword, index) => (
                                <div key={index}>
                                    <LinkContainer key={index}>
                                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                                            {keyword}
                                        </RegularText>
                                    </LinkContainer>
                                    <br />
                                </div>
                            ))}
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Additional Details
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.additionalDetails || ''}
                        </RegularText>
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Links
                        </RegularText>
                        <br />
                        {currentArticle.parameters?.links !== undefined &&
                            currentArticle.parameters?.links.length > 0 &&
                            currentArticle.parameters.links.map((link, index) => (
                                <div key={index}>
                                    <LinkContainer key={index}>
                                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                                            <StyledSVG src={UrlIcon} />
                                            &ensp; {link}
                                        </RegularText>
                                    </LinkContainer>
                                    <br />
                                </div>
                            ))}
                    </div>
                    <br />
                    <br />
                    <div>
                        <RegularText lineHeight={'24px'} fontWeight={500} color={naturalGray}>
                            Auto-humanize?
                        </RegularText>
                        <br />
                        <RegularText lineHeight={'24px'} fontWeight={400} color={darkGray}>
                            {currentArticle.parameters?.autoHumanize ? 'Yes' : 'No'}
                        </RegularText>
                    </div>
                </CollapsedParametersContainer>
            )}
        </ParametersContainer>
    )
}

export default memo(ParametersDetails)
