import { useDispatch, useSelector } from "react-redux";
import { useDropdown } from "../../../common/hooks/useDropdown";
import { showPopup } from "../../../../reducers/popup";
import { PopupComponentKeys } from "../../../common/popup/Popup";
import { moveArticle } from "../../slice";
import { moveArticleInFolder } from "../../../../reducers/user";
import { moveArticleInCompany } from "../../../../reducers/articles";
import { NotificationManager } from "react-notifications";
import { useCallback, useMemo } from "react";
import { EditButtonContainer } from "../styles";
import StyledSVG from "../../../common/styled-svg/StyledSVG";
import ThreeDots from '../../../../assets/images/three-dots.svg'

export const EditArticleMenu = ({ article, allowDelete=true }) => {
  const dispatch = useDispatch()
  const companies = useSelector((state) => state.user.companies)
  const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)
  const activeFolderUuid = useSelector((state) => state.common.activeFolderUuid)
  const cancelPopup = () => {
    dispatch(
        showPopup({ component: PopupComponentKeys.CANCEL_ARTICLE, extraData: { articleId: article.id, isCreatedArticle: true } }),
    )
  }

  const getCurrentActiveFolderUuid = useCallback(
    (articleObject) => {
        if (activeFolderUuid) {
            return activeFolderUuid
        }
        const companyObject = companies.find((company) => company?.uuid === getCurrentActiveCompanyUuid(articleObject))
        const folderObject = (companyObject?.folders || []).find((folder) =>
            folder.articles.some((article) => article.uuid === articleObject?.id),
        )

        return folderObject?.uuid
    },
    [activeCompanyUuid, activeFolderUuid, companies],
  )
  const getCurrentActiveCompanyUuid = useCallback(
    (article) => {
        if (activeCompanyUuid) {
            return activeCompanyUuid
        }
        return article?.company?.uuid
    },
    [activeCompanyUuid],
  )
  const editOptions = useMemo(
    () => [
        {
            id: 'move-option',
            name: 'Move Article',
            action: (e) => {
                e.preventDefault()
                e.stopPropagation()
                dispatch(
                    showPopup({
                        styles: { overflow: 'visible' },
                        component: PopupComponentKeys.MOVE_ARTICLE,
                        extraData: {
                            title: 'Move Article',
                            subTitle: 'Company and Folder',
                            buttonText: 'Move',
                            handleClick: ({ companyUuid, folderUuid }, close) => {
                                const originalFolderUuid = getCurrentActiveFolderUuid(article)
                                const originalCompanyUuid = getCurrentActiveCompanyUuid(article)
                                if(companyUuid === originalCompanyUuid && folderUuid === originalFolderUuid) {
                                    close()
                                    return
                                }

                                dispatch(
                                    moveArticle({
                                        articleId: article?.id,
                                        data: {
                                            companyUuid,
                                            folderUuid,
                                        },
                                    }),
                                )
                                    .unwrap()
                                    .then(() => {
                                        if (folderUuid || originalFolderUuid) {
                                            dispatch(
                                                moveArticleInFolder({
                                                    originalCompanyUuid,
                                                    originalFolderUuid,
                                                    newCompanyUuid: companyUuid,
                                                    newFolderUuid: folderUuid,
                                                    articleId: article?.id,
                                                    article
                                                }),
                                            )
                                        }
                                        dispatch(
                                            moveArticleInCompany({
                                                companyUuid,
                                                articleId: article?.id,
                                            }),
                                        )
                                        NotificationManager.info('The article was moved successfully')
                                    })
                                    .catch(() => {
                                        NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
                                    })
                                close()
                            },
                        },
                    }),
                )
            },
        },
        allowDelete && {
            id: 'delete-option',
            name: 'Delete Article',
            action: (e) => {
                e.preventDefault()
                e.stopPropagation()
                cancelPopup()
            },
        },
    ],
    [activeCompanyUuid, activeFolderUuid, article, getCurrentActiveFolderUuid],
  )
  const { generateDropdown, toggleDropdown, showOptions } = useDropdown(editOptions, {
    width: '12.5rem',
    top: '1.875rem',
    left: '-0.625rem',
  })
  const handleClick = useCallback((e) => {
      e.stopPropagation()
      toggleDropdown()
  }, [])

  return <EditButtonContainer showMenu={showOptions} onClick={handleClick}>
      <StyledSVG src={ThreeDots} />
      {generateDropdown()}
  </EditButtonContainer>
};