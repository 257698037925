import React, { memo, useCallback, useRef, useState } from 'react'
import { EnterInfoPopupContainer } from '../../styles'
import SimpleButton from '../../../../../common/button/simple-button/SimpleButton'
import { TextInput } from '../../../../../common/form/styles'
import { useClickAway } from 'react-use'

const EnterInfoPopup = ({ isOpen, setIsOpen, onClick, ...rest }) => {
    const [inputValue, setInputValue] = useState('')
    const containerRef = useRef(null)

    useClickAway(containerRef, () => {
        setIsOpen(false)
    })

    const handleClick = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(false)
        onClick(inputValue)
    }, [onClick, inputValue, isOpen])

    return (
        <div ref={containerRef}>
            {isOpen && (
                <EnterInfoPopupContainer>
                    <TextInput value={inputValue} onChange={(e) => setInputValue(e.target.value)} {...rest} />
                    <SimpleButton text="Add" action={handleClick} />
                </EnterInfoPopupContainer>
            )}
        </div>
    )
}

export default memo(EnterInfoPopup)
