import React, { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { PaymentTitleContainer } from '../styles'
import { RegularText } from '../../common/styles/text'
import { useSelector } from 'react-redux'
import { selectPromo } from '../../../reducers/subscription'

function PaymentTitle() {
    const location = useLocation()
    const promo = useSelector(selectPromo)
    const title = useMemo(() => {
        if(location.state.creditAmount) {
            return `${location.state.creditAmount} credits`
        }
        else {
            return `${location.state.period} subscription`
        }
    }, [location])

    const price = useMemo(() => {
        const currentPrice = promo.newPrice === 0 ? promo.newPrice : promo.newPrice || location.state.price
        if(`${currentPrice}`.includes('$')) {
            return currentPrice
        }
        return `$${currentPrice.toFixed ? currentPrice.toFixed(2) : currentPrice}`
    }, [location, promo])

    return (
        <PaymentTitleContainer>
            <RegularText fontSize="24px">
                Product: {title}
            </RegularText>
            <RegularText fontSize="24px">
                Price: {price}
            </RegularText>
            {promo.description ? <RegularText fontSize="24px">
                Promo: {promo.description}
            </RegularText> : ''}
        </PaymentTitleContainer>
    )
}

export default memo(PaymentTitle)
