import styled, { css } from 'styled-components'
import { naturalGray } from '../../common/styles/colors'
import { TransparentBorderButtonComponent } from '../../common/button/transparent-border-button/styles'
import { white } from '../../common/styles/colors'

export const ArticleContainer = styled.div`
    position: relative;
    padding: 40px 40px 40px 40px;
    display: flex;

    @media (max-width: 820px) {
        padding: 0px 8px 8px;
        flex-direction: column;
    }
`

export const ActionContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.2s;

    & svg {
        padding-right: 4px;
        path {
        fill: ${white};
        }
    }

    @media (max-width: 820px) {
        opacity: 1;

        & button p {
            font-size: 12px;
        }
    }
`

export const ImageWrapper = styled.div`
    position: relative;
    margin-bottom: 40px;

    &:hover ${ActionContainer} {
        opacity: 1;
    }
`

export const ArticleImage = styled.img`
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    max-height: 400px;
    object-fit: cover;
`

export const ImageAttributionContainer = styled.div`
    margin-bottom: -22px;
    margin-top: 8px;

    & p {
        text-align: end;
    }

    & a {
        color: #42a5f5;
    }
`

export const OutlineSectionListWrapper = styled.div`
    padding: 0 172px 0 132px;
    border-right: 1px solid #d4d0cd;

    @media (max-width: 1135px) {
        padding: 0 40px 0 0;
    }

    @media (max-width: 820px) {
        padding: 0px;
        border-right: none;
    }
`

export const OutlineSectionNavigationContainer = styled.div`
    position: absolute;
    left: 32px;
    top: 100px;
    cursor: pointer;
    z-index: 10;
`

export const ReprocessingLayoutContainer = styled.div`
    background-color: white;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    opacity: 0.8;
    border-radius: 16px;
`
export const ReprocessBlockContainer = styled.div`
    display: flex;
    justify-content: space-around;
    top: 10px;
    margin: 0 auto;
    position: absolute;
    align-items: center;
    padding: 8px 24px;
    width: 170px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: #8b8581;
    border-radius: 12px;
`

export const ArticleContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const ArticleHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 267px);
    padding: 16px;
    border-bottom: 1px solid #efebe8;
    margin-bottom: 24px;
    margin-left: -150px;

    @media (max-width: 480px) {
        width: calc(100% - 32px);
        margin-left: 0;
    }
`

export const ArticlePathContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;

    & p {
        margin-bottom: 0 !important;
        max-width: 241px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
    }

    & svg {
        width: 12px;
        height: 12px;
        transform: rotate(-90deg);

        & path {
            fill: ${naturalGray};
        }
    }

    @media (max-width: 480px) {
        column-gap: 16px;
    }
`

export const ArticleDesktopPathContainer = styled.div`
    @media (max-width: 480px) {
        display: none;
    }
`

export const ArticleMobilePathContainer = styled.div`
    display: none;

    & svg {
        transform: rotate(90deg);
    }

    @media (max-width: 480px) {
        display: block;
    }
`

export const ArticleEditButton = styled(TransparentBorderButtonComponent)`
    width: fit-content;
    height: 40px;
    background: #f9f9f9;
    border: 1px solid #f1edeb;
    margin: 0 0 0 auto;
    column-gap: 8px;
    padding: 12px 18px;

    & svg {
        width: 21px;
        height: 21px;
    }
`

export const ArticleTextContainer = styled.div`
    position: relative;
    background-color: white;
    width: 80%;
    border-radius: 16px;
    padding: 56px 150px 128px 159px;
    margin-right: 20px;

    @media (max-width: 820px) {
        width: fit-content;
        margin: 10px 0 10px;
        padding: 10px;
    }

    h1 {
        font-size: 1.75em;
        margin-bottom: 0.5em;
    }

    h2 {
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-size: 1.4em;
    }

    h3 {
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-size: 1.05em;
    }

    p:not(:last-child),
    ul,
    ol {
        margin-bottom: 1em;
    }
`

export const ArticleSettingsButtonToCollapseMobileContainer = styled.div`
    position: fixed;
    height: 70px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    background: rgba(239, 235, 232, 1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid rgba(212, 208, 205, 1);
    border-bottom: none;
    border-left: none;
    border-right: none;
    z-index: 99;

    @media (min-width: 820px) {
        display: none;
    }
`

export const MobileParametersPopupBackgroundContainer = styled.div`
    background: black;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
`
export const ArticleSettingsCollapsedMobileContainer = styled.div`
    background: rgba(239, 235, 232, 1);
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    z-index: 9999;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`

export const MobileHeaderParametersPopupContainer = styled.div`
    padding: 24px 16px;
`
export const MobileParametersPopupContentContainer = styled.div`
    padding: 24px 16px;
`
export const ArticleSettingsContainer = styled.div`
    position: relative;
    width: 20%;
    height: 100%;
    margin: 10px 10px 10px;

    ${(props) =>
        props.marginTop &&
        css`
            margin-top: ${(props) => props.marginTop};
        `}

    @media (max-width: 820px) {
        display: ${(props) => (props.showOnMobile ? 'block' : 'none')};
    }
`

export const OutlineArticleSettingsContainer = styled(ArticleSettingsContainer)`
    @media (max-width: 820px) {
        width: auto;
        margin-top: 32px;
    }
`

export const ButtonContainer = styled.div`
    width: 100%;
`

export const SaveChangesButtonContainer = styled.div`
    & svg {
        display: none;
    }

    & button {
        margin: 0;

        & p {
            text-wrap: nowrap;
        }
    }

    @media (max-width: 480px) {
        & svg {
            display: block;
            width: 32px;
            height: 32px;
        }

        & button {
            display: none;
        }
    }
`

export const HumanizeContainer = styled.div`
    background: rgba(255, 106, 23, 0.05);
    border: 1px solid #ff6a17;
    border-radius: 12px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`

export const ReprocessContainer = styled.div`
    background: rgba(255, 106, 23, 0.05);
    border: 1px solid #ff6a17;
    border-radius: 12px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`

export const ReprocessTitleContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 18px;
    text-align: center;
    justify-content: center;
`

export const HumanizeTitleContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 18px;
    text-align: center;
    justify-content: center;
`

export const IconContainer = styled.div`
    margin-right: 10px;
`

export const ReprocessCostContainer = styled.div`
    background-color: white;
    display: flex;
    align-self: center;
    justify-content: space-around;
    width: 60px;
    align-items: center;
    border-radius: 24px;
    height: 20px;
    margin-bottom: 10px;
    padding: 6px 10px;
`

export const WordsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #d5d0cd;
`

export const ReprocessingHistoryContainer = styled.div`
    padding: 32px 0 0 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d5d0cd;
`

export const CollapseReprocessingHistoryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: baseline;
`

export const CollapsedReprocessingHistoryContainer = styled.div`
    padding: 10px 0;
    position: relative;
`

export const HistoryBlockContainer = styled.div`
    cursor: pointer;
    display: flex;
    margin-bottom: 30px;
`

export const HistoryMarkerContainer = styled.div`
    position: relative;
`

export const HistoryInfoContainer = styled.div`
    position: relative;
    margin-left: 15px;
`

export const LineContainer = styled.div`
    position: absolute;
    width: 1px;
    left: 6.5px;
    height: 50px;
    margin-top: -50px;
    background-color: #ff6a17;
`

export const ParametersContainer = styled.div`
    padding: 32px 0 0 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d5d0cd;
`

export const CollapseParametersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 50px;
`

export const CollapsedParametersContainer = styled.div`
    padding: 0 0 30px 0;
`

export const LinkContainer = styled.div`
    display: flex;
    align-content: center;
    align-items: baseline;
    display: flex;
    word-break: break-all;
`

export const ArticleNotFoundListContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
