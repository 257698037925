import React, { memo } from 'react'
import { ContentContainer, MainContent } from './styles'
import Header from './header/Header'
import { Content460 } from '../../styles/styles'
import { HeaderContainer } from '../header-styles'
import Loading from '../../loading/Loading'
import { useSelector } from 'react-redux'

const PublicArea = ({ headerRightButtonTitle, headerRightButtonAction, children }) => {
    const showLoading = useSelector((state) => state.common.isProcessingRequest)
    
    return (
        <ContentContainer>
            <HeaderContainer>
                <Header rightButtonTitle={headerRightButtonTitle} rightButtonAction={headerRightButtonAction} />
            </HeaderContainer>
            <MainContent>
                {showLoading ? <Loading position="absolute" /> : ''}
                    <Content460>{children}</Content460>
            </MainContent>
        </ContentContainer>
    )
}

export default memo(PublicArea)
