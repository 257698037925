import React, { memo, useEffect } from 'react'
import { RegularText } from '../../common/styles/text'
import { FieldContainer } from '../../common/form/text-field/styles'
import { FormContainer } from '../../common/styles/form-styles'
import { PopupContent } from './styles'
import TextField from '../../common/form/text-field/TextField'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeConfirmPasswordValue,
    changeOldPasswordValue,
    changeNewPasswordValue,
    selectChangePasswordFormData,
    selectRequestStatus,
    selectGlobalError,
    selectValidationErrors,
    setValidationError,
    changeRequestStatus,
    resetError,
    resetForm,
} from '../../../reducers/forms/change-password-form'
import { ButtonContainer } from '../pages/styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { changePassword } from './slice'

function ChangePasswordPopup() {
    const dispatch = useDispatch()
    const changePasswordFormData = useSelector(selectChangePasswordFormData)
    const requestStatus = useSelector(selectRequestStatus)
    const globalError = useSelector(selectGlobalError)
    const validationErrors = useSelector(selectValidationErrors)

    useEffect(() => {
        return () => {
            dispatch(resetForm())
        }
    }, [])

    const changeOldPasswordField = (event) => {
        event.preventDefault()
        dispatch(changeOldPasswordValue({ oldPassword: event.target.value }))
    }

    const changeNewPasswordField = (event) => {
        event.preventDefault()
        dispatch(changeNewPasswordValue({ newPassword: event.target.value }))
    }

    const changeConfirmPasswordField = (event) => {
        event.preventDefault()
        dispatch(changeConfirmPasswordValue({ confirmPassword: event.target.value }))
    }

    const changePasswordAction = (event) => {
        event.preventDefault()
        dispatch(resetError())

        const { oldPassword, newPassword, confirmPassword } = changePasswordFormData

        const validateField = (fieldName, fieldValue, regex, errorMessage) => {
            if (!regex.test(fieldValue)) {
                dispatch(setValidationError({ field: fieldName, message: errorMessage }))
                return false
            }
            return true
        }

        let validForm = true
        //todo: add full validation
        validForm = validateField('oldPassword', oldPassword, /.+/, 'Empty password') && validForm
        validForm = validateField('newPassword', newPassword, /.+/, 'Empty password') && validForm
        validForm = validateField('confirmPassword', confirmPassword, /.+/, 'Empty confirm password') && validForm

        if (validForm) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(changePassword({ oldPassword, newPassword, confirmPassword }))
        }
    }

    return (
        <>
            {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}

            <PopupContent>
                <div>
                    <RegularText lineHeight="32px" fontSize="24px">
                        Change password
                    </RegularText>
                </div>
                <div>
                    <ErrorMessage error={globalError} />

                    <FormContainer onSubmit={changePasswordAction}>
                        <fieldset>
                            <FieldContainer>
                                <fieldset>
                                    {/*todo: add validation*/}
                                    <TextField
                                        label={'Current password'}
                                        value={changePasswordFormData.oldPassword}
                                        errorMessage={validationErrors.oldPassword}
                                        changeEvent={changeOldPasswordField}
                                        placeholder={'Enter your password'}
                                        type={'password'}
                                    />
                                </fieldset>
                            </FieldContainer>
                            <FieldContainer>
                                <fieldset>
                                    {/*todo: add validation*/}
                                    <TextField
                                        label={'New password'}
                                        value={changePasswordFormData.newPassword}
                                        errorMessage={validationErrors.newPassword}
                                        changeEvent={changeNewPasswordField}
                                        placeholder={'Enter your password'}
                                        type={'password'}
                                    />
                                </fieldset>
                            </FieldContainer>
                            <FieldContainer>
                                <fieldset>
                                    {/*todo: add validation*/}
                                    <TextField
                                        label={'Confirm password'}
                                        value={changePasswordFormData.confirmPassword}
                                        errorMessage={validationErrors.confirmPassword}
                                        changeEvent={changeConfirmPasswordField}
                                        placeholder={'Confirm your password'}
                                        type={'password'}
                                    />
                                </fieldset>
                            </FieldContainer>
                        </fieldset>
                        <ButtonContainer>
                            <SimpleButton text="Change & Save password" />
                        </ButtonContainer>
                    </FormContainer>
                </div>
            </PopupContent>
        </>
    )
}

export default memo(ChangePasswordPopup)
