import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { orange, white } from '../styles/colors'
import CheckMark from '../../../assets/images/check-mark.svg'
import StyledSVG from '../styled-svg/StyledSVG'

const CheckboxContainer = styled.label`
    position: relative;
    cursor: pointer;
`

const CheckboxMark = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.2s;

    & svg {
        width: 16px;
        height: 16px;
    }

    ${(props) =>
        props.checked &&
        css`
            opacity: 1;
        `}
`

const CheckboxInput = styled.input`
    box-sizing: border-box;
    width: 26px;
    height: 26px;
    margin: 0;
    padding: 0;
    border-radius: ${(props) => props.borderRadius || '50%'};
    border: 1px solid ${(props) => props.borderColor || '#d9d9d9'};
    background: ${white};
    appearance: none;
    outline: none;
    transition: 0.2s;

    &:checked {
        background: ${(props) => props.color || orange};
    }
`

const Checkbox = ({ isChecked, onChange, ...rest }) => {
    const [checked, setChecked] = useState(isChecked)

    const handleToggle = () => {
        setChecked(!checked)
        if (onChange) {
            onChange(!checked)
        }
    }

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])

    return (
        <CheckboxContainer>
            <CheckboxInput type="checkbox" checked={checked} onChange={handleToggle} {...rest} />
            <CheckboxMark checked={checked}>
                <StyledSVG src={CheckMark} />
            </CheckboxMark>
        </CheckboxContainer>
    )
}

export default Checkbox
