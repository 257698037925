import styled from 'styled-components'

export const FieldsInRowContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;

    @media (max-width: 480px) {
        flex-direction: column;
    }
`
export const FieldContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 24px;
`

export const ShortFieldContainer = styled.div`
    position: relative;
    width: 48%;
    margin-bottom: 24px;

    @media (max-width: 480px) {
        width: 100%;
    }
`
