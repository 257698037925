import { useDispatch, useSelector } from "react-redux"
import { changeRequestStatus, selectRequestStatus, selectAvailableProducts, selectCurrentSubscription } from "../../../reducers/subscription"
import { selectPaymentInformation } from "../../../reducers/user"
import { useEffect, useState } from "react"
import { subscriptionDetails, subscriptionProductsList } from "../../settings/slice"
import { REQUEST_STATUSES } from "../../../reducers/reducer-helper"

export const useCurrentSubscriptionProduct = () => {
    const dispatch = useDispatch()
    const requestStatus = useSelector(selectRequestStatus)
    const availableProducts = useSelector(selectAvailableProducts)
    const currentSubscription = useSelector(selectCurrentSubscription)
    const payment = useSelector(selectPaymentInformation)
    const [currentSubscriptionProduct, setCurrentSubscriptionProduct] = useState(null)

    useEffect(() => {
        if(requestStatus !== REQUEST_STATUSES.PENDING) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(subscriptionProductsList())
            if (payment?.subscriptionId !== null) {
                dispatch(subscriptionDetails({ subscriptionId: payment?.subscriptionId }))
            }
        }
    }, [])

    useEffect(() => {
        if (currentSubscription) {
            const currentProduct = availableProducts.find(
                (availableProduct) => availableProduct.id === currentSubscription.plan.product,
            )
            setCurrentSubscriptionProduct(currentProduct)
        }
    }, [currentSubscription, availableProducts])

    useEffect(() => {
        if (payment?.subscriptionId !== null) {
            dispatch(subscriptionDetails({ subscriptionId: payment?.subscriptionId }))
        } else {
            setCurrentSubscriptionProduct(null)
        }
    }, [payment])

    return currentSubscriptionProduct
}