import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAgent } from '../../api/appAgent'

export const initApp = createAsyncThunk('common/app-loading', async (undefined, thunkApi) => {
    try {
        const response = await AppAgent.init()

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})
