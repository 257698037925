import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import EditableSection from './EditableSection'
import { EditableSectionsListContainer } from './styles'
import AddEditableSections from './components/AddEditableSections'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import OutlineSection from './OutlineSection'

function EditableSectionsList({ sectionsContent, sectionType }) {
    const [sectionContentList, setSectionContentList] = useState([])
    const [dragInProgress, setDragInProgress] = useState(false)

    const CurrentSection = useMemo(() => (sectionType === 'outline' ? OutlineSection : EditableSection), [sectionType])

    useEffect(() => {
        if (!sectionsContent) return []
        else if (typeof sectionsContent === 'string') {
            const contentWithoutStyles = sectionsContent.split('</style>')[1]
            const sections = contentWithoutStyles.split('<h2>').map((el) => '<h2>' + el)

            setSectionContentList(sections)
        }
        else {
            setSectionContentList(sectionsContent)
        }
    }, [sectionsContent])

    const addSection = useCallback(
        (index) => {
            const newSectionContentList = [...sectionContentList]
            newSectionContentList.splice(index + 1, 0, '<h2></h2>')
            setSectionContentList(newSectionContentList)
        },
        [sectionContentList],
    )

    const deleteSection = useCallback(
        (index) => {
            const newSectionContentList = [...sectionContentList]
            newSectionContentList.splice(index, 1)
            setSectionContentList(newSectionContentList)
        },
        [sectionContentList],
    )

    const onDragEnd = useCallback(
        (result) => {
            setDragInProgress(false)
            const newSectionContentList = [...sectionContentList]
            const [removed] = newSectionContentList.splice(result.source.index, 1)
            newSectionContentList.splice(result.destination.index, 0, removed)
            setSectionContentList(newSectionContentList)
        },
        [sectionContentList],
    )

    const onDragStart = useCallback(() => {
        setDragInProgress(true)
    }, [])

    const editableSections = useMemo(() => {
        return sectionContentList.map((content, index) => (
            <>
                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <CurrentSection
                                onDelete={() => deleteSection(index)}
                                key={index}
                                index={index + 1}
                                content={content}
                            />
                            {index !== sectionContentList.length - 1 && (
                                <div style={{ opacity: dragInProgress ? 0 : 1, transition: '0.2s' }}>
                                    <AddEditableSections onAdd={() => addSection(index)} />
                                </div>
                            )}
                        </div>
                    )}
                </Draggable>
            </>
        ))
    }, [sectionContentList, dragInProgress])

    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <EditableSectionsListContainer>{editableSections}</EditableSectionsListContainer>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default memo(EditableSectionsList)
