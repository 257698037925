import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import Dropdown from '../dropdown/Dropdown'
import { useClickAway } from 'react-use'

export const useDropdown = (options, styles = {}) => {
    const [showOptions, setShowOptions] = useState(false)
    const [closed, setClosed] = useState(false)
    const containerRef = useState(null)
    useClickAway(containerRef, () => {
        if (!showOptions) return
        setShowOptions(false)
        setClosed(true)
    })

    const toggleDropdown = useCallback(() => {
        if (closed) {
            setClosed(false)
            return
        }

        if (!showOptions) {
            setShowOptions(true)
        } else {
            setShowOptions(false)
        }
    }, [showOptions, closed])

    useEffect(() => {
        if (closed && !showOptions) {
            setTimeout(() => {
                setClosed(false)
            }, 500)
        }
    }, [closed, showOptions])

    const generateDropdown = (customDropdown) => {
        return (
            <div ref={containerRef}>
                <AnimatePresence>
                    {showOptions && (
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            transition={{ duration: 0.1 }}
                            ref={containerRef}
                            style={{
                                position: 'absolute',
                                zIndex: 99,
                                height: '100%',
                                width: styles.width || '12.5rem',
                                top: styles.top || 0,
                                right: styles.right || null,
                                left: styles.left || null,
                            }}
                        >
                            {customDropdown ? <>{customDropdown}</> : <Dropdown items={options} />}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        )
    }

    return { generateDropdown, toggleDropdown, showOptions }
}
