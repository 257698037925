import React, { memo } from 'react'
import { RegularText } from '../../../../../common/styles/text'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import ArrowIcon from '../../../../../../assets/images/arrow-down.svg'
import { darkGray } from '../../../../../common/styles/colors'
import { BackNavigationContainer } from './styles'
import { useNavigate } from 'react-router'

function BackNavigation({ title }) {
    const navigate = useNavigate()

    return (
        <BackNavigationContainer onClick={() => navigate(-1)}>
            <StyledSVG src={ArrowIcon} />
            <RegularText fontSize="14px" fontWeight={400} color={darkGray}>{title}</RegularText>
        </BackNavigationContainer>
    )
}

export default memo(BackNavigation)
