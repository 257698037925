import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const requestChangePassword = createAsyncThunk('user/forgot-password/request', async ({ email, recaptcha }, thunkApi) => {
    try {
        return await UserAgent.forgotPasswordRequest(email, recaptcha)
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const confirmNewPassword = createAsyncThunk(
    'user/forgot-password/confirm',
    async ({ confirmChangePasswordToken, password, confirmPassword }, thunkApi) => {
        try {
            return await UserAgent.forgotPasswordConfirm(confirmChangePasswordToken, password, confirmPassword)
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)
