import { createSlice, current } from '@reduxjs/toolkit'
import { ArticleStatus } from '../utils/utils'
import ArticlePreview from '../assets/images/article-preview2.svg'
import {getArticle, getArticles, getLanguages, getLocations, humanizeArticle, regenerateArticle} from '../components/article/slice'
import { MOCKED_IMAGES } from '../components/article/view/image-edit-popup/utils'

const initialState = {
    article: null,
    articles: [],
    locations: [],
    languages: [],
    editorState: null,
    setEditorState: null,
}

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        clearArticle(state) {
            state.article = initialState.article
        },
        reprocessArticle(state, action) {
            const articleIndex = state.articles.findIndex((article) => article.id === action.payload.id)
            if (articleIndex > -1) {
                state.articles[articleIndex].status = ArticleStatus.REPROCESSING
            }
        },
        completeArticleGeneration(state, action) {
            const articleIndex = state.articles.findIndex((article) => article.id === action.payload.id)
            if (articleIndex > -1) {
                state.articles[articleIndex].status = ArticleStatus.READY
            }
        },
        cancelArticle(state, action) {
            const articleIndex = state.articles.findIndex((article) => article.id === action.payload.id)
            if (articleIndex > -1) {
                state.articles.splice(articleIndex, 1)
            }
        },
        updateEditorState(state, action) {
            state.editorState = action.payload.editorState
        },
        updateSetEditorState(state, action) {
            state.setEditorState = action.payload.setEditorState
        },
        updateArticleImage(state, action) {
            const articleIndex = state.articles.findIndex((article) => article.id === action.payload.id)
            if (articleIndex > -1) {
                state.articles[articleIndex].articleImage = action.payload.image
            }
        },
        moveArticleInCompany: (state, actions) => {
            const articles = [...current(state).articles]
            console.log(actions.payload.companyUuid)
            const articleIndex = articles.findIndex((article) => article.id === actions.payload.articleId)

            const currentArticleObject = { ...articles[articleIndex] }
            currentArticleObject.company = {...currentArticleObject.company}
            currentArticleObject.company.uuid = actions.payload.companyUuid
            articles[articleIndex] = currentArticleObject

            state.articles = articles
        },
    },
    extraReducers(builder) {
        builder
            .addCase(regenerateArticle.fulfilled, (state) => {
                state.article = {
                    ...state.article,
                    status: ArticleStatus.REPROCESSING,
                }
            })
            .addCase(getLocations.fulfilled, (state, action) => {
                const { payload: locations } = action
                state.locations = locations
            })
            .addCase(getLanguages.fulfilled, (state, action) => {
                const { payload: languages } = action
                state.languages = languages
            })
            .addCase(getArticle.fulfilled, (state, action) => {
                const { payload: article } = action

                state.article = {
                    id: article.uuid,
                    status: article.status,
                    error: null,
                    keywords: article.keywords,
                    preview: ArticlePreview,
                    articleText: '',
                    articleTitle: article.articleTitle,
                    articleImage: MOCKED_IMAGES[0],
                    history: article.history,
                    createdTime: article.createdTime,
                    company: article.company,
                    parameters: {
                        companyName: article.company.name,
                        articleTitle: article.articleTitle,
                        words: article.words,
                        tone: article.tone,
                        callToActionTitle: article.callToAction.title,
                        callToActionLink: article.callToAction.link,
                        links: article.links ?? [],
                        language: article.language,
                        locationId: article.locationId,
                        additionalKeywords: article.additionalKeywords,
                        additionalDetails: article.additionalDetails,
                        autoHumanize: article.autoHumanize,
                    },
                }
            })
            .addCase(humanizeArticle.fulfilled, (state, action) => {
                const { payload: article } = action

                state.article = {
                    id: article.uuid,
                    status: article.status,
                    error: null,
                    keywords: article.keywords,
                    preview: ArticlePreview,
                    articleText: '',
                    articleTitle: article.articleTitle,
                    articleImage: MOCKED_IMAGES[0],
                    history: article.history,
                    createdTime: article.createdTime,
                    company: article.company,
                    parameters: {
                        companyName: article.company.name,
                        articleTitle: article.articleTitle,
                        words: article.words,
                        tone: article.tone,
                        callToActionTitle: article.callToAction.title,
                        callToActionLink: article.callToAction.link,
                        links: article.links ?? [],
                        language: article.language,
                        locationId: article.locationId,
                        additionalKeywords: article.additionalKeywords,
                        additionalDetails: article.additionalDetails,
                        autoHumanize: article.autoHumanize,
                    },
                }
            })
            .addCase(getArticles.fulfilled, (state, action) => {
                state.articles = action.payload.map((article) => ({
                    id: article.uuid,
                    status: article.status,
                    error: null,
                    keywords: article.keywords,
                    preview: ArticlePreview,
                    articleTitle: article.articleTitle,
                    articleText: '',
                    articleImage: MOCKED_IMAGES[0],
                    history: article.history,
                    createdTime: article.createdTime,
                    company: article.company,
                    parameters: {
                        companyName: article.company.name,
                        articleTitle: article.articleTitle,
                        words: article.words,
                        tone: article.tone,
                        callToActionTitle: article.callToAction.title,
                        callToActionLink: article.callToAction.link,
                        links: article.links ?? [],
                        language: article.language,
                        locationId: article.locationId,
                        additionalKeywords: article.additionalKeywords,
                        additionalDetails: article.additionalDetails,
                        autoHumanize: article.autoHumanize,
                    },
                }))
            })
    },
})

const { reducer, actions } = articlesSlice
const selectArticlesSlice = (state) => state.articles
export const selectArticle = (state) => selectArticlesSlice(state).article
export const selectArticles = (state) => selectArticlesSlice(state).articles
export const selectArticleLocations = (state) => selectArticlesSlice(state).locations
export const selectArticleLanguages = (state) => selectArticlesSlice(state).languages

export const { clearArticle, updateEditorState, updateSetEditorState, updateArticleImage, moveArticleInCompany } =
    actions

export default reducer
