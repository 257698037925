import styled from 'styled-components'
import { naturalGray } from '../../../common/styles/colors'

export const EditableSectionContainer = styled.div`
    background-color: white;
    border-radius: 16px;
    min-height: 215px;
    width: 100%;
    margin: 8px 0;

    & p,
    h1,
    h2,
    span {
        font-family: 'Inter';
        line-height: 24px;
    }

    & .sectionHeading {
        font-size: 24px !important;
        font-weight: 600;
    }

    & .sectionDescription span {
        font-size: 16px;
    }
`

export const EditableSectionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #efebe8;
    margin-bottom: 24px;

    & svg {
        cursor: pointer;
    }
`

export const TitleLeftContent = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
`

export const OutlineTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
    margin-bottom: -28px;
    margin-top: 8px;
`

export const OutlineSectionContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 0 62px 32px;

    @media (max-width: 820px) {
        padding: 0 24px 24px;
    }
`

export const EditableSectionContentContainer = styled.div`
    padding: 0 150px 32px;

    @media (max-width: 820px) {
        padding: 0 24px 24px;
    }
`

export const EditableSectionsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 52px;
`

export const RichTextTooltipContainer = styled.div`
    display: flex;
    column-gap: 8px;
`

export const RichTextEditorButton = styled.button`
    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    & svg rect {
        fill: white;
    }
`

export const EditableSectionWithHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 24px;

    @media (max-width: 820px) {
        margin-right: 0;
    }
`

export const EditableSectionHeader = styled.div`
    position: absolute;
    height: 56px;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 147px;
    padding: 4px 40px;
    box-shadow: 0px 4px 20px 0px #00000026;
    background: white;

    @media screen and (max-width: 480px) {
        padding: 4px 14px;
        overflow: scroll;
        column-gap: 21px;
    }
`

export const EditorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    cursor: text;
`

export const AddSectionContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;

    & button {
        width: fit-content;
        padding: 12px 16px;
        height: 41px;

        & p {
            text-wrap: nowrap;
            text-transform: uppercase;
        }

        & svg {
            width: 18px;
            height: 18px;

            & rect {
                fill: none;
            }

            & path {
                fill: ${naturalGray};
            }
        }

        &:hover {
            background: none;
            border-color: #dad4cf;

            & svg path {
                fill: ${naturalGray} !important;
            }
        }
    }
`

export const AddSectionSeparator = styled.div`
    width: 100%;
    height: 1px;
    background: #dad4cf;
`

export const EnterInfoPopupContainer = styled.div`
    position: absolute;
    height: fit-content;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    row-gap: 24px;
    background: white;
    border-radius: 24px;
    padding: 24px;
    z-index: 100;
    transform: translate(-50%, 0);
    border: 1px solid #dad4cf;

    & button {
        height: 41px;
    }
`
