import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { EditableSectionContainer, EditableSectionContentContainer, EditorsContainer } from './styles'
import SectionTitle from './components/SectionTitle'
import { RegularText } from '../../../common/styles/text'
import { DefaultDraftBlockRenderMap, Editor, EditorState, RichUtils, convertToRaw } from 'draft-js'
import { useDispatch } from 'react-redux'
import { updateEditorState, updateSetEditorState } from '../../../../reducers/articles'
import Media from './components/Toolbar.js/Media'
import { naturalGray } from '../../../common/styles/colors'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'

function EditableSection({ isTitle, index, content, onDelete }) {
    // const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(content)))
    const [titleEditorState, setTitleEditorState] = useState(EditorState.createEmpty())
    const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty())
    const [currentEditorStateKey, setCurrentEditorStateKey] = useState(null)
    const sectionRef = useRef(null)
    const editorRef = useRef(null)
    const dispatch = useDispatch()

    const editorState = useMemo(() => {
        if (currentEditorStateKey === 'title') {
            return titleEditorState
        }
        return descriptionEditorState
    }, [currentEditorStateKey, titleEditorState, descriptionEditorState])

    const setEditorState = useMemo(() => {
        if (currentEditorStateKey === 'title') {
            return setTitleEditorState
        }
        return setDescriptionEditorState
    }, [currentEditorStateKey, setTitleEditorState, setDescriptionEditorState])

    // useEffect(() => {
    //     if (!sectionRef?.current) return

    //     const handleClick = () => {
    //         dispatch(updateEditorState({ editorState }))
    //         dispatch(updateSetEditorState({ setEditorState }))
    //     }

    //     sectionRef.current.addEventListener('click', handleClick)

    //     return () => {
    //         if (sectionRef?.current) {
    //             sectionRef.current.removeEventListener('click', handleClick)
    //         }
    //     }
    // }, [editorState])

    useEffect(() => {
        const separator = content.includes('<h1>') ? '</h1>' : '</h2>'

        const titleContent = content.split(separator)[0] + separator
        const descriptionContent = content.split(separator)[1]

        setTitleEditorState(EditorState.createWithContent(stateFromHTML(titleContent)))
        setDescriptionEditorState(EditorState.createWithContent(stateFromHTML(descriptionContent || '')))
    }, [content])

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            setEditorState(newState)
            return true
        }
        return false
    }

    const mediaBlockRenderer = (block) => {
        if (block.getType() === 'atomic') {
            return {
                component: Media,
                editable: false,
            }
        }
        return null
    }

    const myBlockStyleFn = (contentBlock, onlyHeading) => {
        const type = contentBlock.getType()

        switch (type) {
            case 'header-one':
                return 'sectionHeading'
            case 'header-two':
                return 'sectionHeading'
            case 'unstyled':
                return onlyHeading ? 'sectionHeading' : 'sectionDescription'
            default:
                break
        }
    }

    const titleBlockRenderMap = new Map([
        [
            'unstyled',
            {
                element: 'h2',
            },
        ],
    ])

    return (
        <EditableSectionContainer ref={sectionRef}>
            <SectionTitle isTitle={isTitle} index={index} onDelete={onDelete} />
            <EditableSectionContentContainer>
                <EditorsContainer>
                    <Editor
                        ref={editorRef}
                        handleKeyCommand={handleKeyCommand}
                        editorState={titleEditorState}
                        blockRendererFn={mediaBlockRenderer}
                        blockStyleFn={(contentBlock) => myBlockStyleFn(contentBlock, true)}
                        blockRenderMap={titleBlockRenderMap}
                        onChange={(newEditorState) => {
                            setCurrentEditorStateKey('title')
                            dispatch(updateEditorState({ editorState: newEditorState }))
                            dispatch(updateSetEditorState({ setEditorState: setTitleEditorState }))
                            setTitleEditorState(newEditorState)
                        }}
                        placeholder={
                            <RegularText color={naturalGray} fontSize="24px">
                                Enter your title...
                            </RegularText>
                        }
                    />
                    <Editor
                        ref={editorRef}
                        handleKeyCommand={handleKeyCommand}
                        editorState={descriptionEditorState}
                        blockRendererFn={mediaBlockRenderer}
                        blockStyleFn={myBlockStyleFn}
                        onChange={(newEditorState) => {
                            setCurrentEditorStateKey('description')
                            dispatch(updateEditorState({ editorState: newEditorState }))
                            dispatch(updateSetEditorState({ setEditorState: setDescriptionEditorState }))
                            setDescriptionEditorState(newEditorState)
                        }}
                        placeholder={
                            <RegularText color={naturalGray} fontWeight={400}>
                                Type the content which should display in this section.
                            </RegularText>
                        }
                    />
                </EditorsContainer>
            </EditableSectionContentContainer>
        </EditableSectionContainer>
    )
}

export default memo(EditableSection)
