import React, { memo, useState } from 'react'
import { PopupContent } from '../../styles'
import Tabs from './Tabs'
import { getTabsContent, imageSelectionTabs, tabsContent } from './utils'
import { RegularText } from '../../../common/styles/text'

function ImageSelectionPopup({extraData}) {
    const [activeTab, setActiveTab] = useState(imageSelectionTabs[0].key)

    return (
        <PopupContent>
            <div>
                <RegularText constantSize lineHeight="32px" fontSize="24px" fontWeight={600}>
                    Choose the image
                </RegularText>
            </div>
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {getTabsContent({onSelect: extraData.onSelect})[activeTab]}
        </PopupContent>
    )
}

export default memo(ImageSelectionPopup)
