import { createAsyncThunk } from '@reduxjs/toolkit'

import { ArticleAgent } from '../../api/articleAgent'

export const getLocations = createAsyncThunk('article/locations/list', async (undefined, thunkApi) => {
    try {
        const response = await ArticleAgent.getLocationList()

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const getLanguages = createAsyncThunk('article/languages/list', async (undefined, thunkApi) => {
    try {
        const response = await ArticleAgent.getLanguageList()

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const getArticleImages = createAsyncThunk('article/get/some', async (query, thunkApi) => {
    try {
        const response = await ArticleAgent.getFeaturedImages(query)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const setArticleImage = createAsyncThunk('article/set/image', async ({articleId, data}, thunkApi) => {
    try {
        const response = await ArticleAgent.setFeaturedImage(articleId, data)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const getArticle = createAsyncThunk('article/get/one', async (articleId, thunkApi) => {
    try {
        const response = await ArticleAgent.get(articleId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const getArticles = createAsyncThunk('article/get/list', async (undefined, thunkApi) => {
    try {
        const response = await ArticleAgent.getList()

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const createArticle = createAsyncThunk('article/create', async (payload, thunkApi) => {
    try {
        const response = await ArticleAgent.create(payload)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const moveArticle = createAsyncThunk('article/move-to-folder', async ({articleId, data}, thunkApi) => {
    try {
        const response = await ArticleAgent.move(articleId, data)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const createArticles = createAsyncThunk('article/bulk/create', async (payload, thunkApi) => {
    try {
        const response = await ArticleAgent.createBulk(payload)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const resumeArticle = createAsyncThunk('article/resume', async (articleId, thunkApi) => {
    try {
        const response = await ArticleAgent.resume(articleId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const regenerateArticle = createAsyncThunk('article/regenerate', async (articleId, thunkApi) => {
    try {
        const response = await ArticleAgent.regenerate(articleId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const deleteArticle = createAsyncThunk('article/delete', async (articleId, thunkApi) => {
    try {
        const response = await ArticleAgent.delete(articleId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const humanizeArticle = createAsyncThunk('article/humanize', async (articleId, thunkApi) => {
    try {
        const response = await ArticleAgent.humanize(articleId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const pauseArticle = createAsyncThunk('article/pause', async (articleId, thunkApi) => {
    try {
        const response = await ArticleAgent.pause(articleId)

        return response
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})
