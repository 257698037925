import React, { memo } from 'react'
import { IconContainer, IconFieldContainer, IconTextInput } from './styles'
import StyledSVG from '../../styled-svg/StyledSVG'
import { FieldLabel } from '../styles'

const IconField = ({ value, label, icon, disabled, placeholder, name, changeEvent }) => {
    return (
        <>
            {label && <FieldLabel>{label}</FieldLabel>}
            <IconFieldContainer>
                <IconContainer>
                    <StyledSVG src={icon} />
                </IconContainer>
                <IconTextInput
                    disabled={disabled}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    onChange={changeEvent}
                />
            </IconFieldContainer>
        </>
    )
}

export default memo(IconField)
