import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStep, resetForm, selectRequestStatus, changeStep } from '../../../reducers/forms/forgot-password-form'
import EmailForm from './EmailForm'
import PasswordForm from './PasswordForm'
import PublicArea from '../../common/layouts/public-area/PublicArea'
import { useNavigate } from 'react-router'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../../utils/utils'
import EmailSentConfirmation from './EmailSentConfirmation'

function ForgotPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const step = useSelector(selectStep)
    // const requestStatus = useSelector(selectRequestStatus)

    const search = useLocation().search
    const confirmChangePasswordToken = new URLSearchParams(search).get('confirmChangePasswordToken')

    useEffect(() => {
        return () => {
            dispatch(resetForm())
        }
    }, [])

    useEffect(() => {
        if (confirmChangePasswordToken !== null) {
            dispatch(changeStep(3))
        }
    }, [confirmChangePasswordToken])

    return (
        <>
            {/* {requestStatus === REQUEST_STATUSES.PENDING && <Loading />} */}
            <PublicArea headerRightButtonTitle="Sign in" headerRightButtonAction={() => navigate(ROUTES.SIGN_IN)}>
                {step === 1 && <EmailForm />}
                {step === 2 && <EmailSentConfirmation />}
                {step === 3 && <PasswordForm confirmChangePasswordToken={confirmChangePasswordToken} />}
            </PublicArea>
        </>
    )
}

export default memo(ForgotPassword)
