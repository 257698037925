import { request } from './agent'

export const UserAgent = {
    signIn: (email, password, recaptcha) => {
        return request.post('/user/auth/sign-in', { email, password }, { recaptcha })
    },
    signUp: (email, password, confirmPassword, firstName, lastName, companyName, recaptcha) => {
        return request.post('/user/auth/sign-up', {
            email,
            password,
            confirmPassword,
            firstName,
            lastName,
            companyName,
        }, { recaptcha })
    },
    googleAuth: (accessCode) => {
        return request.post('/user/auth/google', { accessCode })
    },
    confirmEmail: (confirmEmailToken) => {
        return request.post('/user/auth/confirm-email', { confirmEmailToken })
    },
    forgotPasswordRequest: (email, recaptcha) => {
        return request.post('/user/forgot-password/request', { email }, { recaptcha })
    },
    forgotPasswordConfirm: (confirmChangePasswordToken, password, confirmPassword) => {
        return request.post('/user/forgot-password/confirm', { confirmChangePasswordToken, password, confirmPassword })
    },
    logOut: () => {
        return request.post('/user/logout')
    },
    updateProfile: (firstName, lastName) => {
        return request.post('/user/profile/update', { firstName, lastName })
    },
    changePassword: (oldPassword, newPassword, confirmPassword) => {
        return request.post('/user/account/change-password', { oldPassword, newPassword, confirmPassword })
    },
}
