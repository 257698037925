import React, { memo } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { ButtonContainer, ButtonsContainer, MainInformationContainer } from './styles'
import { closePopup } from '../../../../reducers/popup'
import { PopupComponentKeys } from '../../../common/popup/Popup'
import { RegularText } from '../../../common/styles/text'
import TransparentBorderButton from '../../../common/button/transparent-border-button/TransparentBorderButton'
import SimpleButton from '../../../common/button/simple-button/SimpleButton'
import { PopupContent } from '../../styles'
import { orange } from '../../../common/styles/colors'
import {regenerateArticle} from "../../slice";
import {NotificationManager} from "react-notifications";
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../../utils/utils'

function ReprocessConfirmationPopup({ extraData }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const confirmAction = async () => {
        try {
            dispatch(closePopup({ component: PopupComponentKeys.REPROCESS_CONFIRMATIONS }))
            await dispatch(regenerateArticle(extraData.articleId)).unwrap()
            NotificationManager.info('Article regeneration started')
            navigate(ROUTES.ARTICLES)
        } catch (err) {
            console.log(err,'err')
            NotificationManager.error('Article submission failed')
            console.error(err)
        }
    }

    const closePopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.REPROCESS_CONFIRMATIONS }))
    }

    const priceValue = useSelector((state) => state.common.articlePrice)

    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    Confirmation
                </RegularText>
            </div>
            <MainInformationContainer>
                <RegularText lineHeight={'24px'} fontWeight={400}>
                    {`Would you like to reprocess the article? It will cost ${priceValue} credits`}
                </RegularText>
            </MainInformationContainer>
            <ButtonsContainer>
                <ButtonContainer>
                    <SimpleButton action={confirmAction} text="Yes" />
                </ButtonContainer>
                <ButtonContainer>
                    <TransparentBorderButton color={orange} action={closePopupAction} text="Cancel" />
                </ButtonContainer>
            </ButtonsContainer>
        </PopupContent>
    )
}

export default memo(ReprocessConfirmationPopup)
