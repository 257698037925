import React, { memo, useMemo } from 'react'
import TextWithInput from './TextWithInput'
import { OutlineSectionContentItemsList } from './styles'
import OutlineSectionContentItem from './OutlineSectionContentItem'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

function OutlineSectionContent({ content }) {
    const [contentList, setContentList] = useState([])
    const [dragInProgress, setDragInProgress] = useState(false)

    const updateContentList = useCallback(
        (value, index) => {
            const newContentList = [...contentList]
            newContentList[index] = value
            setContentList(newContentList)
        },
        [contentList],
    )

    const onDelete = useCallback(
        (index) => {
            const newContentList = [...contentList]
            newContentList.splice(index, 1)
            setContentList(newContentList)
        },
        [contentList],
    )

    const onAdd = useCallback(
        (index) => {
            const newContentList = [...contentList]
            newContentList.splice(index + 1, 0, '')
            setContentList(newContentList)
        },
        [contentList],
    )

    const onDragEnd = useCallback(
        (result) => {
            setDragInProgress(false)
            const newContentList = [...contentList]
            const [removed] = newContentList.splice(result.source.index, 1)
            newContentList.splice(result.destination.index, 0, removed)
            setContentList(newContentList)
        },
        [contentList],
    )

    const onDragStart = useCallback(() => {
        setDragInProgress(true)
    }, [])

    const contentItemsList = useMemo(() => {
        return contentList.map((item, index) => (
            <Draggable draggableId={`draggable-outline-${index}`} index={index}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <OutlineSectionContentItem
                            key={index}
                            value={item}
                            setValue={(value) => updateContentList(value, index)}
                            withIcon={contentList.length > 1}
                            isLast={index === contentList.length - 1}
                            onDelete={() => onDelete(index)}
                            onAdd={() => onAdd(index)}
                        />
                    </div>
                )}
            </Draggable>
        ))
    }, [contentList])

    useEffect(() => {
        setContentList(content || [''])
    }, [content])

    return (
        <TextWithInput
            title="Content"
            customInputComponent={
                <div style={{marginBottom: dragInProgress ? "44px" : 0}}>
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-outline">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <OutlineSectionContentItemsList>{contentItemsList}</OutlineSectionContentItemsList>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            }
        />
    )
}

export default memo(OutlineSectionContent)
