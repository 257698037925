import React, { memo } from 'react'
import { LinkContainer, NavigationButton, UserPopoverContainer } from './styles'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { logout } from './slice'
import { ROUTES } from '../../../../../../utils/utils'

function UserPopover({ wrapperRef }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const logoutAction = () => {
        dispatch(logout())
    }

    return (
        <UserPopoverContainer ref={wrapperRef}>
            <LinkContainer>
                <NavigationButton onClick={() => navigate('/settings')}>Settings</NavigationButton>
            </LinkContainer>
            <LinkContainer>
                <NavigationButton onClick={logoutAction}>Log Out</NavigationButton>
            </LinkContainer>
        </UserPopoverContainer>
    )
}

export default memo(UserPopover)
