import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const changePassword = createAsyncThunk(
    'user/account/change-password',
    async ({ oldPassword, newPassword, confirmPassword }, thunkApi) => {
        try {
            return await UserAgent.changePassword(oldPassword, newPassword, confirmPassword)
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)
