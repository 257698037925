import React, { memo, useCallback, useEffect } from 'react'
import PublicArea from '../../common/layouts/public-area/PublicArea'
import { H1Title, RegularText, SubtitleText } from '../../common/styles/text'
import {
    ButtonsContainer,
    LineContainer,
    MiddleFormContainer,
    MiddleFormSubtitleContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import TextField from '../../common/form/text-field/TextField'
import { FieldContainer } from '../../common/form/text-field/styles'
import { ButtonBlock, FormContainer } from '../../common/styles/form-styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import { ForgotPasswordLink, ForgotPasswordLinkContainer } from './styles'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import LineOrIcon from '../../../assets/images/or-line.svg'
import SocialButtonIcon from '../../../assets/images/social-button.svg'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeEmailValue,
    changePasswordValue,
    changeRequestStatus,
    resetError,
    resetForm,
    selectRequestStatus,
    selectSignInFormData,
    selectSignInGlobalError,
    selectValidationErrors,
    setValidationError,
} from '../../../reducers/forms/sign-in-form'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { useGoogleLogin } from '@react-oauth/google'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { googleAuth, signIn } from './slice'
import Loading from '../../common/loading/Loading'
import { emailRegex, ROUTES } from '../../../utils/utils'
import { selectUser } from '../../../reducers/user'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function SignIn() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { executeRecaptcha } = useGoogleReCaptcha()

    const signInFormData = useSelector(selectSignInFormData)
    const requestStatus = useSelector(selectRequestStatus)
    const globalError = useSelector(selectSignInGlobalError)
    const validationErrors = useSelector(selectValidationErrors)
    const user = useSelector(selectUser)

    useEffect(() => {
        if (user) {
            navigate(ROUTES.ARTICLES)
        }
    }, [user])

    useEffect(() => {
        return () => {
            dispatch(resetForm())
        }
    }, [])

    const changeEmailField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'email', message: null }))
        dispatch(changeEmailValue({ email: event.target.value }))
    }

    const changePasswordField = (event) => {
        event.preventDefault()
        dispatch(changePasswordValue({ password: event.target.value }))
    }

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
          return;
        }
    
        signInAction(await executeRecaptcha('signin'))
    }, [executeRecaptcha, signInFormData]);

    const signInAction = (recaptcha) => {
        dispatch(resetError())
        const { email, password } = signInFormData
        if (emailRegex.test(email)) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(signIn({ email, password, recaptcha }))
        } else {
            dispatch(setValidationError({ field: 'email', message: 'Invalid email' }))
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(googleAuth({ accessCode: codeResponse.code }))
        },
        flow: 'code',
    })

    return (
        <>
            {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
            <PublicArea headerRightButtonTitle="Sign up" headerRightButtonAction={() => navigate(ROUTES.SIGN_UP)}>
                <MiddleFormContainer>
                    <MiddleFormTitleContainer>
                        <H1Title>Log in to your account</H1Title>
                    </MiddleFormTitleContainer>
                    <MiddleFormSubtitleContainer>
                        <SubtitleText>Welcome! Please enter your details.</SubtitleText>
                    </MiddleFormSubtitleContainer>

                    <ErrorMessage error={globalError} />

                    <FormContainer onSubmit={handleReCaptchaVerify}>
                        <fieldset>
                            <FieldContainer>
                                <fieldset>
                                    <TextField
                                        autoFocus
                                        label={'Email'}
                                        errorMessage={validationErrors.email}
                                        value={signInFormData.email}
                                        changeEvent={changeEmailField}
                                        placeholder={'Enter your email'}
                                    />
                                </fieldset>
                            </FieldContainer>

                            <FieldContainer>
                                <fieldset>
                                    {/*todo: add validation*/}
                                    <TextField
                                        label={'Password'}
                                        value={signInFormData.password}
                                        changeEvent={changePasswordField}
                                        placeholder={'Enter your password'}
                                        type={'password'}
                                    />
                                </fieldset>
                            </FieldContainer>
                        </fieldset>
                        <ButtonsContainer>
                            <ButtonBlock>
                                <SimpleButton text="Log in" />
                            </ButtonBlock>
                            {/* <LineContainer>
                                <StyledSVG src={LineOrIcon} />
                            </LineContainer>
                            <StyledSVG src={SocialButtonIcon} onClick={googleLogin} /> */}
                        </ButtonsContainer>
                    </FormContainer>
                    <ForgotPasswordLinkContainer>
                        <RegularText>Forgot password? &ensp; </RegularText>
                        <ForgotPasswordLink to={ROUTES.FORGOT_PASSWORD}>Reset</ForgotPasswordLink>
                    </ForgotPasswordLinkContainer>
                </MiddleFormContainer>
            </PublicArea>
        </>
    )
}

export default memo(SignIn)
