import React, { memo } from 'react'
import { OutlineInput, TextWithInputContainer } from './styles'
import { RegularText } from '../../../../../common/styles/text'

function TextWithInput({ title, value, setValue, customInputComponent }) {
    return (
        <TextWithInputContainer>
            <RegularText>{title}</RegularText>
            {customInputComponent ? (
                <>{customInputComponent}</>
            ) : (
                <OutlineInput value={value} onChange={(e) => setValue(e.target.value)} />
            )}
        </TextWithInputContainer>
    )
}

export default memo(TextWithInput)
