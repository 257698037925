import React, { memo } from 'react'
import { FieldLabel, TextAreaContainer } from './styles'

const MultilineTextArea = ({ name, placeholder, label, onChange, value, maxLength }) => {
    return (<FieldLabel>
        {label}
        <TextAreaContainer
            fluid
            width="100%"
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={(e, { value }) => onChange({ target: { value, name }, preventDefault: () => {} })}
        />
    </FieldLabel>)
}

export default memo(MultilineTextArea)
