import React, { memo } from 'react'
import {
    CloseButton,
    CloseButtonContainer,
    CloseButtonIcon,
    PopupBackground,
    PopupContainer,
    PopupContent,
} from './styles'
import CloseImage from '../../../assets/images/close-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import CreateArticlePopup from '../../article/create-popup/CreateArticlePopup'
import { closePopup as closePopupEvent } from '../../../reducers/popup'
import ParametersPopup from '../../article/parameters-popup/ParametersPopup'
import ChangePasswordPopup from '../../settings/change-password-popup/ChangePasswordPopup'
import CsvInstructionPopup from '../../article/csv-upload/CsvInstructionPopup'
import ReprocessConfirmationPopup from '../../article/view/reprocess-confirmation-popup/ReprocessConfirmationPopup'
import CancelArticlePopup from '../../article/cancel-creation-popup/CancelArticlePopup'
import CsvFormatErrorPopup from '../../article/csv-upload/CsvFormatErrorPopup'
import CancelSubscriptionPopup from '../../article/cancel-subscription-popup/CancelSubscriptionPopup'
import UpdateValuePopup from '../../article/list/side-menu/update-value-popup/UpdateValuePopup'
import ImageSelectionPopup from '../../article/view/image-edit-popup/ImageSelectionPopup'
import ImagePreviewPopup from '../../article/view/image-preview-popup/ImagePreviewPopup'
import MoveArticlePopup from '../../article/list/side-menu/update-value-popup/MoveArticlePopup'
import HumanizeArticlePopup from '../../article/humanize-article-popup/HumanizeArticlePopup'

export const PopupComponentKeys = {
    CREATE_ARTICLE: 'create-article',
    CANCEL_ARTICLE: 'cancel-article',
    ARTICLE_PARAMETERS: 'article-parameters',
    CHANGE_PASSWORD: 'change-password',
    UPLOAD_CSV_INSTRUCTION: 'upload-csv-instruction',
    REPROCESS_CONFIRMATIONS: 'reprocess-confirmation',
    CSV_FORMAT_ERROR: 'csv-format-error',
    CANCEL_SUBSCRIPTION: 'cancel-subscription',
    UPDATE_VALUE: 'update-value',
    MOVE_ARTICLE: 'move-article',
    IMAGE_SELECTION: 'image-selection',
    IMAGE_PREVIEW: 'image-preview',
    HUMANIZE_ARTICLE: 'humanize-article',
}

const Popup = () => {
    const popup = useSelector((state) => state.popup)

    const dispatch = useDispatch()

    const closePopup = (event) => {
        event.preventDefault()

        dispatch(closePopupEvent())
    }

    let popupComponent = null

    switch (popup.component) {
        case PopupComponentKeys.CREATE_ARTICLE:
            popupComponent = <CreateArticlePopup />
            break
        case PopupComponentKeys.ARTICLE_PARAMETERS:
            popupComponent = <ParametersPopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.CHANGE_PASSWORD:
            popupComponent = <ChangePasswordPopup />
            break
        case PopupComponentKeys.CANCEL_ARTICLE:
            popupComponent = <CancelArticlePopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.UPLOAD_CSV_INSTRUCTION:
            popupComponent = <CsvInstructionPopup />
            break
        case PopupComponentKeys.REPROCESS_CONFIRMATIONS:
            popupComponent = <ReprocessConfirmationPopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.CSV_FORMAT_ERROR:
            popupComponent = <CsvFormatErrorPopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.UPDATE_VALUE:
            popupComponent = <UpdateValuePopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.MOVE_ARTICLE:
            popupComponent = <MoveArticlePopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.IMAGE_SELECTION:
            popupComponent = <ImageSelectionPopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.IMAGE_PREVIEW:
            popupComponent = <ImagePreviewPopup extraData={popup.extraData} />
            break
        case PopupComponentKeys.CANCEL_SUBSCRIPTION:
            popupComponent = <CancelSubscriptionPopup />
            break
        case PopupComponentKeys.HUMANIZE_ARTICLE:
            popupComponent = <HumanizeArticlePopup extraData={popup.extraData} />
            break
    }

    if (!popupComponent) {
        return <>Something went wrong. Please try again, or contact support if the problem persists.</>
    }

    const getWidth = (key) => {
        const widthMap = {
            [PopupComponentKeys.CREATE_ARTICLE]: '644px',
            [PopupComponentKeys.ARTICLE_PARAMETERS]: '644px',
            [PopupComponentKeys.CHANGE_PASSWORD]: '400px',
            [PopupComponentKeys.CANCEL_ARTICLE]: '462px',
            [PopupComponentKeys.UPLOAD_CSV_INSTRUCTION]: '800px',
            [PopupComponentKeys.REPROCESS_CONFIRMATIONS]: '443px',
            [PopupComponentKeys.HUMANIZE_ARTICLE]: '500px',
            [PopupComponentKeys.CSV_FORMAT_ERROR]: '500px',
            [PopupComponentKeys.CANCEL_SUBSCRIPTION]: '443px',
            [PopupComponentKeys.UPDATE_VALUE]: '644px',
            [PopupComponentKeys.MOVE_ARTICLE]: '644px',
            [PopupComponentKeys.IMAGE_SELECTION]: '944px',
            [PopupComponentKeys.IMAGE_PREVIEW]: '990px',
        }

        return widthMap[key] || '100%' // Значення ширини за замовчуванням для невідомих ключів
    }

    const getMaxHeight = (key) => {
        const maxHeight = {
            [PopupComponentKeys.IMAGE_SELECTION]: '894px',
            [PopupComponentKeys.CREATE_ARTICLE]: '700px',
        }

        return maxHeight[key] || '800px'
    }

    const width = getWidth(popup.component) // Значення ширини для поточного попапу
    const maxHeight = getMaxHeight(popup.component)
    const mobileWidth = '95%'
    return (
        <PopupContainer>
            <PopupBackground onClick={closePopup} />

            <PopupContent width={width} mobileWidth={mobileWidth} maxHeight={maxHeight} {...popup.styles}>
                <CloseButtonContainer>
                    <CloseButton onClick={closePopup}>
                        <CloseButtonIcon src={CloseImage} />
                    </CloseButton>
                </CloseButtonContainer>
                {popupComponent}
            </PopupContent>
        </PopupContainer>
    )
}

export default memo(Popup)
