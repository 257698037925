import styled from 'styled-components'

export const ButtonContainer = styled.div`
    display: flex;
    margin-top: 40px;
`

export const ErrorPopupButtonContainer = styled(ButtonContainer)`
    justify-content: flex-end;
`

export const MainInformationContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
`
export const FeatureList = styled.ul`
    list-style: disc;
    margin: 20px;
`

export const Feature = styled.li`
    font-weight: 400;
`

export const FeatureTitle = styled.span`
    font-weight: 600;
`
export const ErrorMessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-top: 16px;
`