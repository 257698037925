import React, { useState } from 'react'
import styled from 'styled-components'
import { orange } from '../styles/colors'

const ToggleSwitchContainer = styled.label`
    display: inline-block;
    position: relative;
    width: 56px;
    height: 32px;
`

const ToggleSwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
        background-color: ${orange};
    }

    &:checked + span:before {
        transform: translateX(26px);
    }
`

const ToggleSwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d5d5d5;
    border-radius: 17px;
    transition: background-color 0.4s;

    &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 2px;
        bottom: 3px;
        background-color: white;
        border-radius: 50%;
        transition: transform 0.4s;
    }
`

const ToggleSwitch = ({ isChecked, onChange }) => {
    const [checked, setChecked] = useState(isChecked)

    const handleToggle = () => {
        setChecked(!checked)
        onChange(!checked)
    }

    return (
        <ToggleSwitchContainer>
            <ToggleSwitchInput type="checkbox" checked={checked} onChange={handleToggle} />
            <ToggleSwitchSlider />
        </ToggleSwitchContainer>
    )
}

export default ToggleSwitch
