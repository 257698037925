import React, { memo } from 'react'
import {
    AddFieldsContainer,
    DynamicTextInput,
    IconContainer,
    OptionalFieldContainer,
    OptionalFieldLabelContainer,
    RemoveIconContainer,
    RequiredFieldContainer,
} from './styles'
import StyledSVG from '../../styled-svg/StyledSVG'
import { FieldLabel } from '../styles'
import CloseIcon from '../../../../assets/images/close-icon.svg'
import BorderedAddIcon from '../../../../assets/images/bordered-add.svg'
import { RegularText } from '../../styles/text'
import { darkGray } from '../../styles/colors'

const DynamicGroupField = ({
    fields = [], // string[] | { id: string: value: string }[]
    addFieldAction,
    deleteFieldAction,
    changeEventAction,
    label,
    icon,
    placeholder,
    addButtonText,
    eachFieldHasLabel = false,
}) => {
    return (
        <>
            {label && <FieldLabel>{label}</FieldLabel>}

            {fields.map((field, index) => {
                const isObject = typeof field === 'object'

                return (
                    <div key={index}>
                        {index === 0 ? (
                            <RequiredFieldContainer>
                                {icon && (
                                    <IconContainer>
                                        <StyledSVG src={icon} />
                                    </IconContainer>
                                )}
                                <DynamicTextInput
                                    type="text"
                                    withIcon={icon}
                                    placeholder={placeholder}
                                    value={isObject ? field.value : field}
                                    onChange={changeEventAction}
                                    data-index={index}
                                    data-id={isObject ? field.id : undefined}
                                />
                            </RequiredFieldContainer>
                        ) : (
                            <>
                                {eachFieldHasLabel && (
                                    <OptionalFieldLabelContainer>
                                        <FieldLabel>
                                            {label} {index + 1}
                                        </FieldLabel>
                                    </OptionalFieldLabelContainer>
                                )}
                                <OptionalFieldContainer>
                                    {icon && (
                                        <IconContainer>
                                            <StyledSVG src={icon} />
                                        </IconContainer>
                                    )}
                                    <DynamicTextInput
                                        width={'93%'}
                                        withIcon={icon}
                                        type="text"
                                        placeholder={placeholder}
                                        value={isObject ? field.value : field}
                                        onChange={changeEventAction}
                                        data-index={index}
                                        data-id={isObject ? field.id : undefined}
                                    />
                                    <RemoveIconContainer onClick={() => deleteFieldAction(isObject ? field.id : index)}>
                                        <StyledSVG src={CloseIcon} />
                                    </RemoveIconContainer>
                                </OptionalFieldContainer>
                            </>
                        )}
                    </div>
                )
            })}

            <AddFieldsContainer onClick={addFieldAction}>
                <StyledSVG src={BorderedAddIcon} />
                <RegularText fontWeight={500} color={darkGray}>
                    {addButtonText}
                </RegularText>
            </AddFieldsContainer>
        </>
    )
}

export default memo(DynamicGroupField)
