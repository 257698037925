import styled from 'styled-components'

export const FormContainer = styled.form`
    position: relative;
    width: ${(props) => (props.width ? props.width : '100%')};
    margin-top: 40px;

    @media (max-width: 480px) {
        width: 350px;
    }
`

export const ButtonBlock = styled.div`
    position: relative;
    width: 100%;

    @media (max-width: 480px) {
        width: 350px;
    }
`
