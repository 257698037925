import { createSlice } from '@reduxjs/toolkit'
import { googleAuth, signIn } from '../../components/user/sign-in/slice'
import { failed, REQUEST_STATUSES, resetStatus } from '../reducer-helper'

const initialState = {
    data: {
        email: '',
        password: '',
    },
    validationErrors: {},
    globalError: null,
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const signInFormSlice = createSlice({
    name: 'forms.signInForm',
    initialState,
    extraReducers(builder) {
        builder.addCase(signIn.rejected, failed)
        builder.addCase(signIn.fulfilled, (state) => {
            resetStatus(state)
        })
        builder.addCase(googleAuth.rejected, failed)
    },
    reducers: {
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        changeEmailValue: (state, action) => {
            state.globalError = null
            state.data.email = action.payload.email
        },
        changePasswordValue: (state, action) => {
            state.globalError = null
            state.data.password = action.payload.password
        },
        setValidationError: (state, action) => {
            state.validationErrors[[action.payload.field]] = action.payload.message
        },
        resetError: (state) => {
            state.globalError = null
            state.validationErrors = {}
        },
        resetForm: () => initialState,
    },
})

const selectSignInFormSlice = (state) => state.forms.signInForm
export const selectSignInFormData = (state) => selectSignInFormSlice(state).data
export const selectRequestStatus = (state) => selectSignInFormSlice(state).status
export const selectSignInGlobalError = (state) => selectSignInFormSlice(state).globalError
export const selectValidationErrors = (state) => selectSignInFormSlice(state).validationErrors

const { reducer, actions } = signInFormSlice

export const { changeEmailValue, changePasswordValue, setValidationError, resetError, resetForm, changeRequestStatus } =
    actions

export default reducer
