import React, { memo, useCallback, useMemo } from 'react'
import { ArrowContainer, FilterToCollapseContainer, SmallTagContainer, TagsContainer } from './styles'
import { useDropdown } from '../../../common/hooks/useDropdown'
import { RegularText } from '../../../common/styles/text'
import ArrowDown from '../../../../assets/images/arrow-down.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { useDispatch, useSelector } from 'react-redux'
import CheckboxDropdown from '../../../common/dropdown/CheckboxDropdown'
import { updateSearchTags } from '../../../../reducers/common'
import { black, white } from '../../../common/styles/colors'
import TagsElements from './TagsElements'
import useWindowDimensions from '../../../common/hooks/useWindowDimensions'

const styles = {
    left: '16px',
    top: '42px',
}

function TagsFilter() {
    const dispatch = useDispatch()
    const searchTags = useSelector((state) => state.common.searchTags)
    const tags = useSelector((state) => state.user.tags)
    const { generateDropdown, showOptions, toggleDropdown } = useDropdown(null, styles)
    const { isMobile } = useWindowDimensions()

    const changeDropdownState = useCallback(() => {
        toggleDropdown()
    }, [toggleDropdown])

    const dropdownOptions = useMemo(() => {
        return tags.map((tag) => ({ name: tag }))
    }, [tags])

    const onSelect = useCallback(
        (tagName) => {
            const tagIndex = searchTags.findIndex((tag) => tag === tagName)
            console.log(tagIndex, tagName, searchTags)
            if (tagIndex === -1) {
                dispatch(updateSearchTags({ searchTags: [...searchTags, tagName] }))
            } else {
                const tempArray = [...searchTags]
                tempArray.splice(tagIndex, 1)
                dispatch(updateSearchTags({ searchTags: tempArray }))
            }
        },
        [searchTags],
    )

    return (
        <TagsContainer>
            {showOptions ? (
                <FilterToCollapseContainer background={white} onClick={changeDropdownState}>
                    <RegularText>Tags</RegularText>
                    <ArrowContainer isOpen={showOptions}>
                        <StyledSVG src={ArrowDown} />
                    </ArrowContainer>
                </FilterToCollapseContainer>
            ) : (
                <FilterToCollapseContainer onClick={changeDropdownState}>
                    <RegularText>Tags</RegularText>
                    <ArrowContainer isOpen={showOptions}>
                        <StyledSVG src={ArrowDown} />
                    </ArrowContainer>
                </FilterToCollapseContainer>
            )}
            {generateDropdown(
                <CheckboxDropdown
                    alignText="start"
                    items={dropdownOptions}
                    selectedItems={searchTags}
                    onSelect={onSelect}
                />,
            )}
            {!isMobile && <TagsElements />}
        </TagsContainer>
    )
}

export default memo(TagsFilter)
