import styled from 'styled-components'
import { white } from '../../styles/colors'
import { TextInput } from '../styles'

export const IconFieldContainer = styled.div`
    position: relative;
`

export const IconContainer = styled.div`
    position: absolute;
    top: 19px;
    left: 15px;
    z-index: 2;
`

export const IconTextInput = styled(TextInput)`
    width: 100%;
    padding: 10px 14px 10px 45px;
    background: ${(props) => (props.background ? props.background : white)};
`
