import React, { memo, useCallback, useMemo } from 'react'
import { SmallTagContainer } from './styles'
import { RegularText } from '../../../common/styles/text'
import CloseIcon from '../../../../assets/images/close-icon.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { useDispatch, useSelector } from 'react-redux'
import { black } from '../../../common/styles/colors'
import { updateSearchTags } from '../../../../reducers/common'

function TagsElements() {
    const dispatch = useDispatch()
    const searchTags = useSelector((state) => state.common.searchTags)

    const onSelect = useCallback(
        (tagName) => {
            const tagIndex = searchTags.findIndex((tag) => tag === tagName)
            console.log(tagIndex, tagName, searchTags)
            if (tagIndex === -1) {
                dispatch(updateSearchTags({ searchTags: [...searchTags, tagName] }))
            } else {
                const tempArray = [...searchTags]
                tempArray.splice(tagIndex, 1)
                dispatch(updateSearchTags({ searchTags: tempArray }))
            }
        },
        [searchTags],
    )

    const tagElements = useMemo(() => {
        return searchTags.map((tag, index) => (
            <SmallTagContainer key={index}>
                <RegularText fontWeight={500} color={black}>
                    {tag}
                </RegularText>
                <StyledSVG onClick={() => onSelect(tag)} src={CloseIcon} />
            </SmallTagContainer>
        ))
    }, [searchTags])

    return <>{tagElements}</>
}

export default memo(TagsElements)
