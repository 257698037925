import React, { memo, useMemo, useState } from 'react'
import AnimatedAccordion from '../animated-accordion/AnimatedAccordion'
import SavedPaymentMethodItem from './SavedPaymentMethodItem'
import { MethodsListTitleContainer, SavedPaymentMethodsContainer, SavedPaymentMethodsWrapper } from './styles'
import { RegularText } from '../styles/text'
import ArrowDown from '../../../assets/images/arrow-drop-down.svg'
import StyledSVG from '../styled-svg/StyledSVG'

const MOCKED_METHODS = [
    {
        id: 'method1',
        title: 'Method 1',
        cardType: 'VISA',
        card: 4242,
    },
    {
        id: 'customTitle',
        title: 'Custom title',
        cardType: 'VISA',
        card: 4242,
    },
]

const NEW_PAYMENT_METHOD_ID = 'new-payment-method'

function SavedPaymentMethodsList({ children }) {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedMethodId, setSelectedMethodId] = useState(NEW_PAYMENT_METHOD_ID)

    const paymentMethods = useMemo(() => {
        return MOCKED_METHODS.map((method) => (
            <SavedPaymentMethodItem
                key={method.id}
                id={method.id}
                isSelected={selectedMethodId === method.id}
                setSelected={setSelectedMethodId}
                methodTitle={method.title}
                card={method.card}
                cardType={method.cardType}
            />
        ))
    }, [selectedMethodId])

    return (
        <div>
            <SavedPaymentMethodsWrapper>
                <AnimatedAccordion
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    titleComponent={
                        <MethodsListTitleContainer isOpen={isOpen} onClick={() => setIsOpen((prevState) => !prevState)}>
                            <StyledSVG src={ArrowDown} />
                            <RegularText fontSize="24px">Saved payment methods</RegularText>
                        </MethodsListTitleContainer>
                    }
                    contentComponent={
                        <SavedPaymentMethodsContainer>
                            <>
                                {paymentMethods}
                                <SavedPaymentMethodItem
                                    key={NEW_PAYMENT_METHOD_ID}
                                    id={NEW_PAYMENT_METHOD_ID}
                                    isSelected={selectedMethodId === NEW_PAYMENT_METHOD_ID}
                                    setSelected={setSelectedMethodId}
                                    methodTitle="Use another payment method"
                                />
                            </>
                        </SavedPaymentMethodsContainer>
                    }
                />
            </SavedPaymentMethodsWrapper>
            {selectedMethodId === NEW_PAYMENT_METHOD_ID && <>{children}</>}
        </div>
    )
}

export default memo(SavedPaymentMethodsList)
