import styled from 'styled-components'

export const MiddleFormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || '232px'};
`

export const MiddleFormTitleContainer = styled.div`
    position: relative;
`

export const MiddleFormSubtitleContainer = styled.div`
    position: relative;
    margin-top: 8px;
`

export const ButtonsContainer = styled.div`
    position: relative;
    margin: 0 auto;

    @media (max-width: 480px) {
        width: 350px;
        & svg {
            width: 350px;
        }
    }
`

export const LineContainer = styled.div`
    margin: 50px 0;

    @media (max-width: 480px) {
        & svg {
            width: 350px;
        }
    }
`