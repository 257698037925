import React, { memo, useEffect, useState, useMemo } from 'react'
import ArticleItem from './ArticleItem'
import {
    ArticleActionsContainer,
    ArticlesContainer,
    ArticlesTitleContainer,
    ArticlesWrapper,
    EmptyArticleListContainer,
    ListContainer,
    SearchContainer,
} from './styles'
import PrivateArea from '../../common/layouts/private-area/PrivateArea'
import AddArticleItem from './AddArticleItem'
import SearchField from '../../common/form/search-field/SearchField'
import OutOfCreditBanner from './out-of-credit/OutOfCreditBanner'
import { RegularText } from '../../common/styles/text'
import Filter from './filter/Filter'
import { useDispatch, useSelector } from 'react-redux'
import { selectArticles } from '../../../reducers/articles'
import { getArticles } from '../slice'
import { ArticleStatus } from '../../../utils/utils'
import { selectPaymentInformation } from '../../../reducers/user'
import { useCreditRefresher } from '../../common/hooks/useCreditRefresher'
import SideMenu from './side-menu/SideMenu'
import FoldersList from './folders-list/FoldersList'
import TagsFilter from './filter/TagsFilter'
import { black } from '../../common/styles/colors'
import useWindowDimensions from '../../common/hooks/useWindowDimensions'
import { TagsContainer } from './filter/styles'
import TagsElements from './filter/TagsElements'
import { useFolders } from '../hooks/useFolders'
import { useSearchParams } from 'react-router-dom'
import { selectActiveCompanyUuid, selectActiveFolderUuid } from '../../../reducers/common'

function ArticleList() {
    useCreditRefresher()
    const companies = useSelector((state) => state.user.companies)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const articles = useSelector(selectArticles)
    const searchText = useSelector((state) => state.common.searchText)
    const searchTags = useSelector((state) => state.common.searchTags)
    const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)
    const activeFolderUuid = useSelector((state) => state.common.activeFolderUuid)
    const { credits } = useSelector(selectPaymentInformation) || {}
    const articlePrice = useSelector((state) => state.common.articlePrice)
    const { isMobile } = useWindowDimensions()
    const { getArticlesInFolder, getFoldersList } = useFolders()

    const [activeFiltering, setActiveFiltering] = useState(null)

    const searchRegExp = useMemo(() => {
        const escapedInput = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toUpperCase()
        return new RegExp(`${escapedInput}`, 'i')
    }, [searchText])

    const articlesToDisplay = useMemo(() => {
        if (articles.length === 0) return []

        let filteredArticles = articles

        if (searchText || activeFiltering) {
            filteredArticles = (
                activeFiltering
                    ? articles.filter(({ status }) =>
                          activeFiltering === ArticleStatus.PAUSED
                              ? status === ArticleStatus.PAUSED || status === ArticleStatus.NOT_ENOUGH_CREDIT
                              : status === activeFiltering,
                      )
                    : articles
            ).filter(({ keywords }) => !searchText || searchRegExp.test(keywords.toUpperCase()))
        }

        if (activeCompanyUuid) {
            filteredArticles = filteredArticles.filter((article) => article?.company?.uuid === activeCompanyUuid)

            if (activeFolderUuid) {
                const activeArticleIds = getArticlesInFolder(activeFolderUuid).map((article) => article?.uuid)
                filteredArticles = filteredArticles.filter((article) => activeArticleIds.includes(article?.id))
            }
        }

        return filteredArticles.slice().sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
    }, [articles, activeFiltering, searchText, searchRegExp, activeCompanyUuid, activeFolderUuid])

    useEffect(() => {
        dispatch(getArticles())
    }, [])

    useEffect(() => {
        // TODO: Add some animation or visual confirmation for refreshing
        let id
        if (articles.find((a) => a.status === ArticleStatus.IN_PROGRESS || a.status === ArticleStatus.IN_QUEUE)) {
            id = setTimeout(() => dispatch(getArticles({ silent: true })), 10000)
        }
        return () => clearTimeout(id)
    }, [articles])

    useEffect(() => {
        const paramsObject = {}
        if (activeCompanyUuid) {
            paramsObject.company = activeCompanyUuid
        }
        if (activeFolderUuid) {
            paramsObject.folder = activeFolderUuid
        }
        setSearchParams(paramsObject)
    }, [activeCompanyUuid, activeFolderUuid])

    useEffect(() => {
        const company = searchParams.get('company')
        const folder = searchParams.get('folder')

        if (company !== activeCompanyUuid) {
            dispatch(
                selectActiveCompanyUuid({
                    activeCompanyUuid: company,
                }),
            )
        }

        if (folder !== activeFolderUuid) {
            getFoldersList(company)
            dispatch(
                selectActiveFolderUuid({
                    activeFolderUuid: folder,
                }),
            )
        }
    }, [searchParams])

    const articlesListTitle = useMemo(() => {
        if(!activeCompanyUuid) {
            return 'All Articles'
        } else if (!activeFolderUuid) {
            return `All ${companies.find((company) => company.uuid === activeCompanyUuid)?.name} Articles`
        } else {
            return 'Articles'
        }
    }, [companies, activeCompanyUuid, activeFolderUuid]);

    return (
        <>
            {articlePrice > credits && <OutOfCreditBanner />}
            <PrivateArea>
                <ArticlesContainer>
                    {!isMobile && <SideMenu />}
                    <ArticlesWrapper>
                        <FoldersList />
                        <ArticleActionsContainer>
                            {searchTags?.length ? (
                                <TagsFilter />
                            ) : (
                                <ArticlesTitleContainer>
                                    <RegularText color={black}>{articlesListTitle}</RegularText>
                                </ArticlesTitleContainer>
                            )}
                            <Filter changeEvent={setActiveFiltering} />
                        </ArticleActionsContainer>
                        {isMobile && (
                            <ArticleActionsContainer>
                                <TagsContainer>
                                    <TagsElements />
                                </TagsContainer>
                            </ArticleActionsContainer>
                        )}
                        <ListContainer>
                            {articlesToDisplay.length === 0 && (searchText !== '') ? (
                                <EmptyArticleListContainer>
                                    <RegularText lineHeight={'32px'} fontSize={'24px'}>
                                        Sorry, none of your documents contain "{searchText}"
                                    </RegularText>
                                </EmptyArticleListContainer>
                            ) : (
                                <>
                                    <AddArticleItem />
                                    {articlesToDisplay?.map((article) => (
                                        <ArticleItem article={article} key={article.id} />
                                    ))}
                                </>
                            )}
                        </ListContainer>
                    </ArticlesWrapper>
                </ArticlesContainer>
            </PrivateArea>
        </>
    )
}

export default memo(ArticleList)
