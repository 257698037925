import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
    ActionContainer,
    ArticleContainer,
    ArticleEditButton,
    ArticleHeader,
    ArticleImage,
    ArticleNotFoundListContainer,
    ArticleSettingsButtonToCollapseMobileContainer,
    ArticleSettingsCollapsedMobileContainer,
    ArticleSettingsContainer,
    ArticleTextContainer,
    CollapsedReprocessingHistoryContainer,
    CollapseReprocessingHistoryContainer,
    HistoryBlockContainer,
    HistoryInfoContainer,
    HistoryMarkerContainer,
    HumanizeContainer,
    HumanizeTitleContainer,
    IconContainer,
    ImageAttributionContainer,
    ImageWrapper,
    LineContainer,
    MobileHeaderParametersPopupContainer,
    MobileParametersPopupBackgroundContainer,
    MobileParametersPopupContentContainer,
    ReprocessBlockContainer,
    ReprocessContainer,
    ReprocessCostContainer,
    ReprocessingHistoryContainer,
    ReprocessingLayoutContainer,
    ReprocessTitleContainer,
    SaveChangesButtonContainer,
    WordsContainer,
} from './styles'
import PrivateArea from '../../common/layouts/private-area/PrivateArea'
import { RegularText } from '../../common/styles/text'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import CachedIcon from '../../../assets/images/cached.svg'
import DatabaseIcon from '../../../assets/images/database.svg'
import ImageIcon from '../../../assets/images/image.svg'
import { black, darkGray, naturalGray, white, orange } from '../../common/styles/colors'
import ArrowDownIcon from '../../../assets/images/arrow-down.svg'
import ArrowUpIcon from '../../../assets/images/arrow-up.svg'
import NotActiveRadioButtonIcon from '../../../assets/images/not-active-radio-button.svg'
import ActiveRadioButtonIcon from '../../../assets/images/active-radio-button.svg'
import RefreshIcon from '../../../assets/images/refresh.svg'
import EditIcon from '../../../assets/images/edit-icon.svg'
import CheckIcon from '../../../assets/images/check.svg'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { clearArticle, selectArticle } from '../../../reducers/articles'
import { ArticleStatus, countWords, formatFullDate } from '../../../utils/utils'
import { showPopup } from '../../../reducers/popup'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { CloseButton, CloseButtonContainer, CloseButtonIcon } from '../../common/popup/styles'
import CloseImage from '../../../assets/images/close-icon.svg'
import ParametersDetails from './ParametersDetails'
import CopyAction from './CopyAction'
import { getArticle, setArticleImage } from '../slice'
import ArticlePath from './ArticlePath'
import EditableSectionsList from './editable-article/EditableSectionsList'
import RichEditorToolbar from './editable-article/components/Toolbar.js/RichEditorToolbar'
import { EditableSectionHeader, EditableSectionWithHeaderContainer } from './editable-article/styles'
import TransparentBorderButton from '../../common/button/transparent-border-button/TransparentBorderButton'
import { EditorState } from 'draft-js'
import Stack from '../../common/layouts/Stack/Stack'
import { Icon } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

function ArticleView() {
    const dispatch = useDispatch()
    let { articleId } = useParams()

    const article = useSelector(selectArticle)
    const [editable, setEditable] = useState(false)
    const [innerEditorState, setInnerEditorState] = useState(EditorState.createEmpty())
    const [currentHistoryVersion, setCurrentHistoryVersion] = useState(null)
    const [reprocessingHistoryShow, setReprocessingHistoryShow] = useState(false)
    const [currentMainImage, setCurrentMainImage] = useState(null)
    const [showMobileSettings, setShowMobileSettings] = useState(false)
    const priceValue = useSelector((state) => state.common.articlePrice)
    const editorState = useSelector((state) => state.articles.editorState)
    const setEditorState = useSelector((state) => state.articles.setEditorState)
    const actualWordCount = useMemo(
        () => countWords(currentHistoryVersion?.articleTextContent || ''),
        [currentHistoryVersion],
    )

    useEffect(() => {
        if (articleId) {
            dispatch(getArticle(articleId))
        }

        return () => {
            dispatch(clearArticle())
        }
    }, [])

    useEffect(() => {
        if (article) {
            setCurrentHistoryVersion(article.history[0])
            setCurrentMainImage(article.history[0]?.featuredPhoto)
        }
    }, [article])

    useEffect(() => {
        setCurrentMainImage(currentHistoryVersion?.featuredPhoto)
    }, [currentHistoryVersion])

    useEffect(() => {
        if (showMobileSettings) {
            document.body.classList.toggle('no-scroll', true)
        }
        return () => {
            document.body.classList.toggle('no-scroll', false)
        }
    }, [showMobileSettings])

    const showArticle =
        article &&
        (article.status === ArticleStatus.READY ||
            article.status === ArticleStatus.REPROCESSING ||
            (article.status === ArticleStatus.IN_QUEUE && !!article.history.length) ||
            (article.status === ArticleStatus.IN_PROGRESS && !!article.history.length))

    const inProgress =
        article?.status === ArticleStatus.IN_PROGRESS ||
        article?.status === ArticleStatus.REPROCESSING ||
        article?.status === ArticleStatus.IN_QUEUE

    const handleImageSelection = useCallback((image) => {
        dispatch(
            setArticleImage({
                articleId: articleId,
                data: {
                    photoUrl: image.url,
                    photoAttributionUrl: image.attributionUrl,
                },
            }),
        )
            .unwrap()
            .then(() => {
                setCurrentMainImage(image)
                NotificationManager.info('Featured photo updated')
            })
            .catch(() => {
                NotificationManager.error('Something went wrong...')
            })
    })

    const startImageEditingFlow = useCallback(() => {
        dispatch(
            showPopup({ component: PopupComponentKeys.IMAGE_SELECTION, extraData: { onSelect: handleImageSelection } }),
        )
    }, [articleId])

    return (
        <PrivateArea>
            <ArticleContainer>
                {showArticle && (
                    <>
                        {editable ? (
                            <EditableSectionWithHeaderContainer>
                                <EditableSectionHeader>
                                    <SaveChangesButtonContainer onClick={() => setEditable(false)}>
                                        <TransparentBorderButton text="Save changes" color={orange} />
                                        <StyledSVG src={CheckIcon} />
                                    </SaveChangesButtonContainer>
                                    <RichEditorToolbar
                                        editorState={editorState || innerEditorState}
                                        setEditorState={setEditorState || setInnerEditorState}
                                    />
                                </EditableSectionHeader>
                                <EditableSectionsList
                                    sectionsContent={
                                        currentHistoryVersion ? currentHistoryVersion.articleHtmlContent : ''
                                    }
                                />
                            </EditableSectionWithHeaderContainer>
                        ) : (
                            <ArticleTextContainer>
                                {/* <ArticleHeader>
                                    <ArticlePath articleTitle={article?.articleTitle} />
                                    <ArticleEditButton onClick={() => setEditable(true)}>
                                        <StyledSVG src={EditIcon} />
                                        <RegularText>Edit</RegularText>
                                    </ArticleEditButton>
                                </ArticleHeader> */}
                                {currentMainImage && (
                                    <ImageWrapper>
                                        <ActionContainer>
                                            <Stack direction="column" spacing="8px">
                                                <TransparentBorderButton
                                                    color={white}
                                                    text="Change image"
                                                    icon={ImageIcon}
                                                    action={startImageEditingFlow}
                                                />
                                                <RegularText
                                                    fontSize="14px"
                                                    fontWeight={400}
                                                    color={white}
                                                    textAlign="center"
                                                    constantSize
                                                >
                                                    Max image size 50MB
                                                </RegularText>
                                            </Stack>
                                        </ActionContainer>
                                        <ArticleImage src={currentMainImage.url}></ArticleImage>
                                        <ImageAttributionContainer>
                                            <RegularText constantSize fontSize="14px" color={darkGray}>
                                                Photo provided by{' '}
                                                <a target="_blank" href={currentMainImage.attributionUrl}>
                                                    Pexels
                                                </a>
                                            </RegularText>
                                        </ImageAttributionContainer>
                                    </ImageWrapper>
                                )}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: currentHistoryVersion ? currentHistoryVersion.articleHtmlContent : '',
                                    }}
                                />
                                {inProgress && (
                                    <>
                                        <ReprocessingLayoutContainer />
                                        <ReprocessBlockContainer>
                                            <StyledSVG src={RefreshIcon} />
                                            <RegularText color={white}>Reprocessing...</RegularText>
                                        </ReprocessBlockContainer>
                                    </>
                                )}
                            </ArticleTextContainer>
                        )}
                        {!showMobileSettings ? (
                            <ArticleSettingsButtonToCollapseMobileContainer onClick={() => setShowMobileSettings(true)}>
                                <RegularText color={'rgba(255, 106, 23, 1)'}>Show more article details</RegularText>
                            </ArticleSettingsButtonToCollapseMobileContainer>
                        ) : (
                            <>
                                <MobileParametersPopupBackgroundContainer />
                                <ArticleSettingsCollapsedMobileContainer>
                                    <MobileHeaderParametersPopupContainer>
                                        <RegularText lineHeight="32px" fontSize="48px">
                                            Article details
                                        </RegularText>
                                        <CloseButtonContainer>
                                            <CloseButton onClick={() => setShowMobileSettings(false)}>
                                                <CloseButtonIcon src={CloseImage} />
                                            </CloseButton>
                                        </CloseButtonContainer>
                                    </MobileHeaderParametersPopupContainer>
                                    <MobileParametersPopupContentContainer>
                                        <CopyAction currentArticle={article} />

                                        <ReprocessContainer>
                                            <ReprocessTitleContainer
                                                onClick={() =>
                                                    dispatch(
                                                        showPopup({
                                                            component: PopupComponentKeys.REPROCESS_CONFIRMATIONS,
                                                            extraData: { articleId: article.id },
                                                        }),
                                                    )
                                                }
                                            >
                                                <IconContainer>
                                                    <StyledSVG src={CachedIcon} />
                                                </IconContainer>
                                                <RegularText>Reprocess Article</RegularText>
                                            </ReprocessTitleContainer>

                                            <ReprocessCostContainer>
                                                <StyledSVG color={black} src={DatabaseIcon} />
                                                <RegularText>{priceValue}</RegularText>
                                            </ReprocessCostContainer>
                                        </ReprocessContainer>
                                        <HumanizeContainer>
                                            <HumanizeTitleContainer
                                                onClick={() =>
                                                    dispatch(
                                                        showPopup({
                                                            component: PopupComponentKeys.HUMANIZE_ARTICLE,
                                                            extraData: { articleId: article.id },
                                                        }),
                                                    )
                                                }
                                            >
                                                <IconContainer>
                                                    <Icon name="magic" />
                                                </IconContainer>
                                                <RegularText>Humanize Article</RegularText>
                                            </HumanizeTitleContainer>
                                        </HumanizeContainer>
                                        <WordsContainer>
                                            <RegularText fontSize={'40px'} lineHeight={'48px'}>
                                                {actualWordCount || ''}
                                            </RegularText>
                                            <RegularText fontWeight={500}>words</RegularText>
                                        </WordsContainer>

                                        <ReprocessingHistoryContainer>
                                            <CollapseReprocessingHistoryContainer
                                                onClick={() => setReprocessingHistoryShow(!reprocessingHistoryShow)}
                                            >
                                                <RegularText>History</RegularText>
                                                {reprocessingHistoryShow ? (
                                                    <StyledSVG src={ArrowUpIcon} />
                                                ) : (
                                                    <StyledSVG
                                                        color={black}
                                                        width={12}
                                                        height={9}
                                                        src={ArrowDownIcon}
                                                    />
                                                )}
                                            </CollapseReprocessingHistoryContainer>
                                            {reprocessingHistoryShow && (
                                                <CollapsedReprocessingHistoryContainer>
                                                    {article.history.map((history, index) => (
                                                        <div key={index}>
                                                            <HistoryBlockContainer
                                                                onClick={() => setCurrentHistoryVersion(history)}
                                                            >
                                                                <HistoryMarkerContainer>
                                                                    <StyledSVG
                                                                        src={
                                                                            history.uuid !== currentHistoryVersion?.uuid
                                                                                ? NotActiveRadioButtonIcon
                                                                                : ActiveRadioButtonIcon
                                                                        }
                                                                    />
                                                                </HistoryMarkerContainer>
                                                                <HistoryInfoContainer>
                                                                    <RegularText fontWeight={500}>
                                                                        {formatFullDate(history.createdTime)}
                                                                    </RegularText>
                                                                    <RegularText color={naturalGray} fontWeight={400}>
                                                                        {history.type === 'humanizing'
                                                                            ? 'Humanized' :
                                                                            history.type === 'first_generating'
                                                                                ? 'Created' :
                                                                                history.type === 'changed_photo'
                                                                                    ? 'Changed Photo'
                                                                                    : 'Reprocessed'}
                                                                    </RegularText>
                                                                </HistoryInfoContainer>
                                                            </HistoryBlockContainer>
                                                            {article.history.length > 1 &&
                                                                index !== article.history.length - 1 && (
                                                                    <LineContainer />
                                                                )}
                                                        </div>
                                                    ))}
                                                </CollapsedReprocessingHistoryContainer>
                                            )}
                                        </ReprocessingHistoryContainer>

                                        <ParametersDetails currentArticle={article} />
                                    </MobileParametersPopupContentContainer>
                                </ArticleSettingsCollapsedMobileContainer>
                            </>
                        )}
                        <ArticleSettingsContainer>
                            <CopyAction currentArticle={currentHistoryVersion} />

                            <ReprocessContainer>
                                <ReprocessTitleContainer
                                    onClick={() =>
                                        dispatch(
                                            showPopup({
                                                component: PopupComponentKeys.REPROCESS_CONFIRMATIONS,
                                                extraData: { articleId: article.id },
                                            }),
                                        )
                                    }
                                >
                                    <IconContainer>
                                        <StyledSVG src={CachedIcon} />
                                    </IconContainer>
                                    <RegularText>Reprocess Article</RegularText>
                                </ReprocessTitleContainer>
                                <ReprocessCostContainer>
                                    <StyledSVG color={black} src={DatabaseIcon} />
                                    <RegularText>{priceValue}</RegularText>
                                </ReprocessCostContainer>
                            </ReprocessContainer>
                            <HumanizeContainer>
                                <HumanizeTitleContainer
                                    onClick={() =>
                                        dispatch(
                                            showPopup({
                                                component: PopupComponentKeys.HUMANIZE_ARTICLE,
                                                extraData: { articleId: article.id },
                                            }),
                                        )
                                    }
                                >
                                    <IconContainer>
                                        <Icon name="magic" />
                                    </IconContainer>
                                    <RegularText>Humanize Article</RegularText>
                                </HumanizeTitleContainer>
                            </HumanizeContainer>

                            <WordsContainer>
                                <RegularText fontSize={'40px'} lineHeight={'48px'}>
                                    {actualWordCount || ''}
                                </RegularText>
                                <RegularText fontWeight={500}>words</RegularText>
                            </WordsContainer>

                            <ReprocessingHistoryContainer>
                                <CollapseReprocessingHistoryContainer
                                    onClick={() => setReprocessingHistoryShow(!reprocessingHistoryShow)}
                                >
                                    <RegularText>History</RegularText>
                                    {reprocessingHistoryShow ? (
                                        <StyledSVG src={ArrowUpIcon} />
                                    ) : (
                                        <StyledSVG color={black} width={12} height={9} src={ArrowDownIcon} />
                                    )}
                                </CollapseReprocessingHistoryContainer>
                                {reprocessingHistoryShow && (
                                    <CollapsedReprocessingHistoryContainer>
                                        {article.history.map((history, index) => (
                                            <div key={index}>
                                                <HistoryBlockContainer
                                                    onClick={() => setCurrentHistoryVersion(history)}
                                                >
                                                    <HistoryMarkerContainer>
                                                        <StyledSVG
                                                            src={
                                                                history.uuid !== currentHistoryVersion?.uuid
                                                                    ? NotActiveRadioButtonIcon
                                                                    : ActiveRadioButtonIcon
                                                            }
                                                        />
                                                    </HistoryMarkerContainer>
                                                    <HistoryInfoContainer>
                                                        <RegularText fontWeight={500}>
                                                            {formatFullDate(history.createdTime)}
                                                        </RegularText>
                                                        <RegularText color={naturalGray} fontWeight={400}>
                                                        {history.type === 'humanizing'
                                                            ? 'Humanized' :
                                                            history.type === 'first_generating'
                                                                ? 'Created' :
                                                                history.type === 'changed_photo'
                                                                    ? 'Changed Photo'
                                                                    : 'Reprocessed'}
                                                        </RegularText>
                                                    </HistoryInfoContainer>
                                                </HistoryBlockContainer>
                                                {article.history.length > 1 && index !== article.history.length - 1 && (
                                                    <LineContainer />
                                                )}
                                            </div>
                                        ))}
                                    </CollapsedReprocessingHistoryContainer>
                                )}
                            </ReprocessingHistoryContainer>
                            <ParametersDetails currentArticle={article} />
                        </ArticleSettingsContainer>
                    </>
                )}

                {!showArticle && (
                    <ArticleNotFoundListContainer>
                        <RegularText lineHeight={'32px'} fontSize={'24px'}>
                            Sorry, article not found
                        </RegularText>
                    </ArticleNotFoundListContainer>
                )}
            </ArticleContainer>
        </PrivateArea>
    )
}

export default memo(ArticleView)
