import React, { memo } from 'react'
import { MessageContainer, OutOfCreditBannerContainer, OutOfCreditLinkContainer } from './styles'
import { RegularText } from '../../../common/styles/text'
import { white } from '../../../common/styles/colors'
import { ROUTES } from '../../../../utils/utils'

function OutOfCreditBanner() {
    return (
        <OutOfCreditBannerContainer>
            <MessageContainer>
                <RegularText fontWeight={500} color={white}>
                    Out of credits?
                </RegularText>
                <OutOfCreditLinkContainer to={ROUTES.CREDIT}>Buy more credits</OutOfCreditLinkContainer>
            </MessageContainer>
        </OutOfCreditBannerContainer>
    )
}

export default memo(OutOfCreditBanner)
