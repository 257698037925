import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showPopup } from '../../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../../common/popup/Popup'

export const useUpdateValuePopup = () => {
    const dispatch = useDispatch()

    const openPopup = useCallback((data) => {
        dispatch(
            showPopup({
                component: PopupComponentKeys.UPDATE_VALUE,
                extraData: data,
            }),
        )
    }, [])

    return openPopup
}
