import React, { memo, useRef, useState, useEffect } from 'react'
import { AddArticleItemContainer, AddButtonContainer, AddIconContainer, UploadSVGContainer } from './styles'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import AddIcon from '../../../assets/images/add.svg'
import { H2White } from '../../common/styles/text'
import UploadIcon from '../../../assets/images/upload.svg'
import InfoIcon from '../../../assets/images/info.svg'
import TransparentBorderIconButton from '../../common/button/transparent-border-button/TransparentBorderButton'
import { showPopup } from '../../../reducers/popup'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { useDispatch, useSelector } from 'react-redux'
import { useCsvParser } from '../../common/hooks'
import { createArticles, deleteArticle, getArticles } from '../slice'
import { NotificationManager } from 'react-notifications'
import { formatArticlePayload } from '../../../utils/formatArticlePayload'
import { selectAvailableProducts, selectCurrentSubscription } from '../../../reducers/subscription'
import { subscriptionDetails, subscriptionProductsList } from '../../settings/slice'
import { selectPaymentInformation } from '../../../reducers/user'
import { validateArticlePayload } from '../../../utils/validateArticlePayload'

function AddArticleItem() {
    const dispatch = useDispatch()
    const fileInputRef = useRef(null)
    const currentSubscription = useSelector(selectCurrentSubscription)
    const availableProducts = useSelector(selectAvailableProducts)
    const payment = useSelector(selectPaymentInformation)
    const [canUseCsv, setCanUseCsv] = useState(false)

    useEffect(() => {
        if(!availableProducts || availableProducts.length === 0) {
            dispatch(subscriptionProductsList())
        }
        
        if (!currentSubscription && payment?.subscriptionId !== null) {
            dispatch(subscriptionDetails({ subscriptionId: payment?.subscriptionId }))
        }
    }, [])

    useEffect(() => {
        if (currentSubscription) {
            const currentProduct = availableProducts.find(
                (availableProduct) => availableProduct.id === currentSubscription.plan.product,
            )
            setCanUseCsv(currentProduct?.metadata?.free !== "true")
        }
    }, [currentSubscription])

    const { parseCsvToArticles, getCsvFileText, isArticleValid } = useCsvParser()

    const removeArticles = async (ids) => {
        await Promise.all(ids.map((id) => dispatch(deleteArticle(id))))
    }

    const createArticlesAction = async (articles) => {
        const formattedArticles = articles.map(formatArticlePayload)
        let results = []
        try {
            results = await dispatch(createArticles(formattedArticles)).unwrap()
        } catch (e) {
            console.error(e)
        }

        if (articles.length === results.length) {
            NotificationManager.info(`${results.length} Articles submitted`)
            await dispatch(getArticles())
            return
        } else {
            dispatch(showPopup({ component: PopupComponentKeys.CSV_FORMAT_ERROR }))
        }
    }

    const handleButtonClick = () => {
        if(canUseCsv) {
            fileInputRef.current.click()
        } else {
            showCSVInstruction()
        }
    }

    const handleFileUpload = async (event) => {
        const files = Array.from(event.target.files)
        const csvFiles = files.filter(({ type }) => type === 'text/csv')

        if (csvFiles.length === 0) return

        const articles = await Promise.all(
            csvFiles.map(async (file) => {
                const csvContentText = await getCsvFileText(file)
                return parseCsvToArticles(csvContentText)
            }),
        )
        const flattenArticles = articles.flat()
        if (flattenArticles.length === 0) return

        const errorMessages = []

        flattenArticles.forEach((article, index) => {
            const message = validateArticlePayload(article)

            if(message) {
                errorMessages.push({index, message})
            }
        })

        if (!errorMessages.length) {
            await createArticlesAction(flattenArticles)
        } else {
            dispatch(showPopup({ component: PopupComponentKeys.CSV_FORMAT_ERROR, extraData: {errorMessages} }))
        }
    }

    const showCreateArticleForm = () => {
        dispatch(showPopup({ component: PopupComponentKeys.CREATE_ARTICLE }))
    }

    const showCSVInstruction = () => {
        dispatch(showPopup({ component: PopupComponentKeys.UPLOAD_CSV_INSTRUCTION }))
    }

    return (
        <AddArticleItemContainer>
            <AddButtonContainer onClick={() => showCreateArticleForm()}>
                <AddIconContainer>
                    <StyledSVG src={AddIcon} height="6rem" width="6rem" />
                </AddIconContainer>
                <H2White>Create</H2White>
            </AddButtonContainer>
            <UploadSVGContainer>
                <TransparentBorderIconButton text="Upload CSV" icon={UploadIcon} action={handleButtonClick} />
                <input
                    multiple
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                />
                <StyledSVG onClick={showCSVInstruction} src={InfoIcon} />
            </UploadSVGContainer>
        </AddArticleItemContainer>
    )
}

export default memo(AddArticleItem)
