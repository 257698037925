import { useCallback, useEffect, useState } from "react";

export const useHash = ({ prefix="", onHashChange=()=>{}, defaultHash="" }) => {
    const [hash, setHash] = useState(window.location.hash.replace('#', ''))

    const syncHash = useCallback(() => {
        const hashBase = window.location.hash.replace(`#${prefix}`, '');
        if(hashBase !== hash) {
            setHash(hashBase)
            onHashChange(hash)
        }
    }, [hash])

    useEffect(() => {
        window.addEventListener("hashchange", syncHash, false);
        window.addEventListener("popstate", syncHash, false);
        window.addEventListener("pushstate", syncHash, false);
        return () => {
            window.removeEventListener("hashchange", syncHash)
            window.removeEventListener("popstate", syncHash)
            window.removeEventListener("pushstate", syncHash)
        }
    }, [])

    useEffect(() => {
        const fullHash = `#${prefix}${hash || defaultHash}`;
        if(window.location.hash !== fullHash) {
            window.location.hash = fullHash;
        }
    }, [hash]);

    return [hash, setHash]
}