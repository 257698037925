import { memo } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const StyledSVG = styled(SVG)`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    margin: ${({ margin }) => margin ?? 0};
    & path {
        fill: ${({ color }) => color};
    }
    & circle {
        fill: ${({ color }) => color};
    }
`

export default memo(StyledSVG)
