import styled from 'styled-components'
import { naturalGray, orange, regularGray, white } from '../styles/colors'
export const FieldLabel = styled.label`
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${regularGray};

    &:hover {
        cursor: pointer;
    }
`

export const TextInput = styled.input`
    position: relative;
    width: 100%;
    height: 48px;
    line-height: 40px;
    padding: 10px 14px;
    background: ${white};
    //border: '#solid 1px #efefef';
    border: ${(props) => (props.error ? 'solid 2px #FF3D31' : 'solid 1px #efefef')};
    box-sizing: border-box;
    border-radius: 8px;

    margin-top: 6px;
    font-family: 'Inter';

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;

    font-size: 14px;
    font-weight: 400;

    transition: border 275ms ease;

    &::-webkit-input-placeholder {
        /* Edge */
        color: ${naturalGray};
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${naturalGray};
    }

    &::placeholder {
        color: ${naturalGray};
    }

    &:disabled {
        border: 2px solid #f1edeb !important;
    }

    &:hover,
    &:focus {
        border: solid 2px ${orange};
    }
`

export const ErrorMessageContainer = styled.div`
    margin-top: 5px;
`
