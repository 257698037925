import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { confirmNewPassword, requestChangePassword } from '../../components/user/forgot-password/slice'
import { Status } from '../../utils/utils'

const initialState = {
    step: 1,
    recaptcha: '',
    data: {
        email: '',
        password: '',
        confirmPassword: '',
    },
    validationErrors: {},
    globalError: null,
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

function success(state) {
    state.status = Status.SUCCESS
    delete state.globalError
}

const forgotPasswordFormSlice = createSlice({
    name: 'forms.forgotPasswordForm',
    initialState,
    extraReducers(builder) {
        builder.addCase(requestChangePassword.rejected, failed)
        builder.addCase(confirmNewPassword.rejected, failed)
        builder.addCase(requestChangePassword.fulfilled, (state) => {
            state.step = 2
            success(state)
        })
    },
    reducers: {
        changeEmailValue: (state, action) => {
            state.data.email = action.payload.email
        },
        changePasswordValue: (state, action) => {
            state.data.password = action.payload.password
        },
        changeConfirmPasswordValue: (state, action) => {
            state.data.confirmPassword = action.payload.confirmPassword
        },
        changeStep: (state, action) => {
            state.step = action.payload
        },
        setRecaptcha: (state, action) => {
            state.recaptcha = action.payload
        },
        setValidationError: (state, action) => {
            state.validationErrors[[action.payload.field]] = action.payload.message
        },
        resetError: (state) => {
            state.globalError = null
            state.validationErrors = {}
        },
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        resetForm: () => initialState,
    },
})

const selectForgotPasswordFormSlice = (state) => state.forms.forgotPasswordForm

export const selectStep = (state) => selectForgotPasswordFormSlice(state).step
export const selectForgotPasswordFormData = (state) => selectForgotPasswordFormSlice(state).data
export const selectForgotPasswordRecaptcha = (state) => selectForgotPasswordFormSlice(state).recaptcha
export const selectRequestStatus = (state) => selectForgotPasswordFormSlice(state).status
export const selectGlobalError = (state) => selectForgotPasswordFormSlice(state).globalError
export const selectValidationErrors = (state) => selectForgotPasswordFormSlice(state).validationErrors

const { reducer, actions } = forgotPasswordFormSlice

export const {
    changeEmailValue,
    changePasswordValue,
    changeConfirmPasswordValue,
    changeStep,
    resetForm,
    setValidationError,
    changeRequestStatus,
    setRecaptcha
} = actions

export default reducer
