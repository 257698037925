import React, { memo } from 'react'
import { RegularText } from '../../common/styles/text'
import { PopupContent } from '../styles'
import { useDispatch } from 'react-redux'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { NotificationManager } from 'react-notifications'
import { ButtonContainer, ButtonsContainer, MainInformationContainer } from './styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import TransparentBorderButton from '../../common/button/transparent-border-button/TransparentBorderButton'
import { closePopup } from '../../../reducers/popup'
import { orange } from '../../common/styles/colors'
import { deleteArticle, getArticles } from '../slice'

function CancelArticlePopup({ extraData }) {
    const dispatch = useDispatch()

    const cancelArticleAction = async () => {
        dispatch(closePopup({ component: PopupComponentKeys.CANCEL_ARTICLE }))

        try {
            await dispatch(deleteArticle(extraData.articleId)).unwrap()
            await dispatch(getArticles()).unwrap()
            NotificationManager.info(extraData.isCreatedArticle ? 'Article deleted.' : 'Article canceled.')
        } catch (err) {
            NotificationManager.error(extraData.isCreatedArticle ? 'Article deletion failed.' : 'Article canceling failed.')
        }
    }

    const closePopupAction = () => {
        dispatch(closePopup({ component: PopupComponentKeys.CANCEL_ARTICLE }))
    }

    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    {extraData.isCreatedArticle ? 'Delete Article' : 'Cancel Article Creation'}
                </RegularText>
            </div>
            <MainInformationContainer>
                <RegularText lineHeight={'24px'} fontWeight={400}>
                    {extraData.isCreatedArticle ? 'Are you sure you want to delete this article?' : 'Are you sure you want to cancel the creation of the article? Your credits will be refunded to your account'}
                </RegularText>
            </MainInformationContainer>
            <ButtonsContainer>
                <ButtonContainer>
                    <SimpleButton action={cancelArticleAction} text="Yes" />
                </ButtonContainer>
                <ButtonContainer>
                    <TransparentBorderButton color={orange} action={closePopupAction} text="Cancel" />
                </ButtonContainer>
            </ButtonsContainer>
        </PopupContent>
    )
}

export default memo(CancelArticlePopup)
