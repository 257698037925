import React, { memo } from 'react'
import { AddSectionContainer, AddSectionSeparator } from '../styles'
import TransparentBorderButton from '../../../../common/button/transparent-border-button/TransparentBorderButton'
import AddIcon from '../../../../../assets/images/add.svg'
import { RegularText } from '../../../../common/styles/text'
import { naturalGray } from '../../../../common/styles/colors'

function AddEditableSections({ onAdd }) {
    return (
        <AddSectionContainer>
            <AddSectionSeparator />
            <TransparentBorderButton
                action={onAdd}
                icon={AddIcon}
                text={
                    <RegularText color={naturalGray} fontSize="14px" constantSize>
                        Add section
                    </RegularText>
                }
                color="#DAD4CF"
            />
            <AddSectionSeparator />
        </AddSectionContainer>
    )
}

export default memo(AddEditableSections)
