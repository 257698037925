import styled from 'styled-components'
import { darkGray, orange, naturalGray, regularGray, superLightSilver, white } from '../../styles/colors'

export const SelectboxContainer = styled.div`
    position: relative;
    width: 100%;
    margin-top: 4px;
`

export const SelectboxFieldBox = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 48px;
    line-height: 24px;
    padding: 10px 14px;
    background: ${white};
    border: solid 1px #efefef;
    box-sizing: border-box;
    border-radius: 8px;

    margin-top: 4px;
    font-family: 'Inter';

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;

    font-size: 14px;
    font-weight: 400;

    transition: border 275ms ease;

    &::-webkit-input-placeholder {
        /* Edge */
        color: ${naturalGray};
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${naturalGray};
    }

    &::placeholder {
        color: ${naturalGray};
    }

    &:disabled {
        opacity: 0.4;
    }

    &:hover,
    &:focus {
        border: solid 2px ${orange};
    }
`

export const SelectedValue = styled.div`
    position: relative;

    font-size: 14px;
    font-weight: 400;
`

export const ArrowIconContainer = styled.div`
    position: absolute;
    width: 12px;
    height: 8px;

    line-height: 0;

    right: 16px;
    top: 16px;
`

export const ArrowIcon = styled.img`
    position: relative;
    width: 12px;
    height: 8px;
`

export const SelectboxOptionsContainer = styled.div`
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    max-height: 320px;
    overflow-y: scroll;
    background: #ffffff;
    border: 1px solid ${superLightSilver};
    box-sizing: border-box;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;

    z-index: 999;
`

export const SelectboxOption = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    box-sizing: border-box;

    font-size: 14px;
    color: ${regularGray};

    transition: background 275ms ease, color 275ms ease;

    &:hover {
        cursor: pointer;
        background: ${superLightSilver};
        color: ${darkGray};
    }
`
