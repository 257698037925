import React, { memo } from 'react'
import { ButtonPrefixIcon, ButtonSuffixIcon } from './styles'
import { ButtonComponent } from './styles'
import { white } from '../../styles/colors'
import { ButtonText } from '../styles'

const LinkButton = ({ text, href, iconPrefix, iconSuffix, target, color = white }) => {
    return (
        <ButtonComponent href={href} target={target}>
            <ButtonPrefixIcon src={iconPrefix} />
            <ButtonText color={color}>{text}</ButtonText>
            <ButtonSuffixIcon src={iconSuffix} />
        </ButtonComponent>
    )
}

export default memo(LinkButton)
