import React, { memo, useCallback } from 'react'
import { ContetnContainer, FolderContainer, ThreeDotsContainer } from './styles'
import { RegularText } from '../../../common/styles/text'
import { black } from '../../../common/styles/colors'
import FolderIcon from '../../../../assets/images/folder.svg'
import ThreeDots from '../../../../assets/images/three-dots.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { useDropdown } from '../../../common/hooks/useDropdown'
import { useDispatch } from 'react-redux'
import { selectActiveFolderUuid } from '../../../../reducers/common'
import { useUpdateValuePopup } from '../side-menu/update-value-popup/hooks/useUpdateValuePopup'
import { useFolders } from '../../hooks/useFolders'

const styles = {
    top: '80%',
    right: '-60%',
}

function FolderItem({ folder }) {
    const dispatch = useDispatch()
    const openPopup = useUpdateValuePopup()
    const { renameFolder, deleteFolder } = useFolders()

    const onRename = () => {
        openPopup({
            title: 'Rename folder',
            subTitle: 'Folder name',
            buttonText: 'Save',
            handleClick: (value, close) => {
                renameFolder(value, folder.uuid)
                close()
            },
        })
    }

    const onDelete = () => {
        deleteFolder(folder.uuid)
    }

    const editOptions = [
        {
            id: 'rename-option',
            name: 'Rename folder',
            action: (e) => {
                e.preventDefault()
                e.stopPropagation()
                onRename()
            },
        },
        {
            id: 'delete-option',
            name: 'Delete folder',
            action: (e) => {
                e.preventDefault()
                e.stopPropagation()
                onDelete()
            },
        },
    ]
    const { generateDropdown, toggleDropdown } = useDropdown(editOptions, styles)

    const handleFolderSelection = useCallback(() => {
        dispatch(selectActiveFolderUuid({ activeFolderUuid: folder.uuid }))
    }, [folder, dispatch])

    const openDropdown = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            toggleDropdown()
        },
        [toggleDropdown],
    )

    return (
        <FolderContainer onClick={handleFolderSelection}>
            <ContetnContainer>
                <StyledSVG src={FolderIcon} />
                <RegularText color={black}>{folder.name}</RegularText>
            </ContetnContainer>
            <ThreeDotsContainer onClick={openDropdown}>
                <StyledSVG src={ThreeDots} />
            </ThreeDotsContainer>
            {generateDropdown()}
        </FolderContainer>
    )
}

export default memo(FolderItem)
