import styled from 'styled-components'
import { orange, white } from '../styles/colors'

export const ButtonComponent = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  height: 48px;
  width: ${(props) => props.width || 'inherit'};
  border: none;
  background: ${(props) => props.background ?? orange};
  border-radius: 24px;
  justify-content: center;
  padding: 12px 24px;
  box-sizing: border-box;
  align-items: center;
  margin: ${(props) => props.margin ?? '0 auto'};

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ff6a17;
  }

  &:disabled {
    background: #d0cbc8;
  }
`

export const ButtonText = styled.p`
  color: ${(props) => props.color ?? white};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
`
