import React, { memo, useMemo } from 'react'
import { ArticlePreviewWrapper, InfoContainer, PreviewContainer, TitleContainer } from '../styles'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import { RegularText } from '../../../common/styles/text'
import PointIcon from '../../../../assets/images/point.svg'
import { darkGray } from '../../../common/styles/colors'
import { countWords, formatShortDate } from '../../../../utils/utils'
import ReadyArticlePreview from './ReadyArticlePreview'
import { EditArticleMenu } from './EditArticleMenu'
import { ArticlePreviewImage, StyledPreviewFrame } from './styled'

function ReadyArticle({ article }) {
    const lastArticleVersion = article?.history?.at(-1)
    const actualWordCount = useMemo(
        () => countWords(lastArticleVersion?.articleTextContent || ''),
        [lastArticleVersion],
    )
    const previewHtml = useMemo(() => {
        const photoStyles = /*css*/`
            .preview-image {
                width: 100% !important;
                border-radius: 5px;
                margin-bottom: 5px;
                height: auto;
            }
        `;
        const [styles, html] = lastArticleVersion?.articleHtmlContent?.split('</style>')
        const photoHtml = (lastArticleVersion?.featuredPhoto?.url ?
            `<img class="preview-image" src="${lastArticleVersion?.featuredPhoto?.url}" />` :
            ''
        );
        return `${styles}${photoStyles}</style>${photoHtml}${html}`;
    }, [lastArticleVersion])

    return (
        <ArticlePreviewWrapper>
            <PreviewContainer>
                <ReadyArticlePreview lastVersion={previewHtml} />
            </PreviewContainer>
            <TitleContainer>
                <RegularText fontWeight={500}>{article.articleTitle}</RegularText>
            </TitleContainer>
            <InfoContainer>
                <RegularText color={darkGray} fontSize="0.875rem" fontWeight="400">
                    {article ? formatShortDate(article.createdTime) : ''}
                </RegularText>
                <StyledSVG src={PointIcon} />
                <RegularText color={darkGray} fontSize="0.875rem" fontWeight="400">
                    {(actualWordCount && `${actualWordCount} Words`) || ''}
                </RegularText>
                <EditArticleMenu article={article} />
            </InfoContainer>
        </ArticlePreviewWrapper>
    )
}

export default memo(ReadyArticle)
