import styled from 'styled-components'
import { white } from '../common/styles/colors'

export const CreditContainer = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    align-content: start;
    align-items: start;
    margin: 0 auto;

    @media (max-width: 1220px) {
        width: 75%;
    }

    @media (max-width: 768px) {
        width: 75%;
    }

    @media (max-width: 480px) {
        width: 65%;
    }
`
export const BackButtonContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 12px;
    display: flex;
`

export const CurrentCreditContainer = styled.div`
    background: linear-gradient(228.34deg, #ff7425 12.04%, #ff5c01 100%);
    border-radius: 24px;
    width: 100%;
`

export const CurrentCreditTitleContainer = styled.div`
    padding: 16px 24px 0 24px;
    margin-bottom: 17px;
`

export const CurrentCreditAmountContainer = styled.div`
    display: flex;
    padding: 10px 24px 32px 24px;
    justify-content: space-between;
`
export const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 90px;

    @media (max-width: 480px) {
        width: 100%;
        margin-right: 20px;
        justify-content: end;
    }
`

export const IncreaseCreditHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 56px;
`
export const IncreaseCreditTitleContainer = styled.div`
    padding: 10px 24px 0 24px;
`
export const IncreaseCreditContainer = styled.div`
    display: flex;
    background-color: ${white};
    border-radius: 24px;
    padding: 16px 0 28px 0;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;
`

export const AmountOptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 52px;
    padding: 0 24px;
    z-index: 3;

    @media (max-width: 480px) {
        flex-direction: column;
        margin-top: 22px;
    }
`

export const AmountOptionContainer = styled.div`
    box-sizing: border-box;
    width: 32%;
    height: 209px;
    background: #ffffff;
    border: 1px solid #f1edeb;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    position: relative;

    @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 50px;
    }
`

export const BonusContainer = styled.div`
    background: rgba(255, 106, 23, 0.1);
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    margin-top: 10px;
`

export const SeparateLine = styled.div`
    border-bottom: 1px solid #d5d0cd;
    height: 1px;
    width: 100%;
    margin-top: 54px;
    margin-bottom: 28px;

    @media (max-width: 480px) {
        margin-top: 10px;
    }
`
export const AutoPaymentContainer = styled.div`
    padding: 0 24px;
    height: 100px;

    @media (max-width: 480px) {
        & p {
            font-size: 14px;
            margin-right: 50px;
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    padding: 0 24px;
    align-self: flex-start;
    width: 170px;

    @media (max-width: 480px) {
        margin-top: 20px;
    }
`

export const SwitchContainer = styled.div`
    display: flex;
    position: relative;
    bottom: 50px;
    justify-content: end;
`

export const MostPopularOptionContainer = styled.div`
    box-sizing: border-box;
    width: 32%;
    height: 209px;
    position: relative;

    @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 30px;
    }
`

export const MostPopularMarkOptionContainer = styled.div`
    position: absolute;
    background: rgba(255, 106, 23, 0.1);
    border: 1px solid rgba(255, 106, 23, 0.1);
    border-radius: 16px;
    width: 100%;
    height: 100px;
    z-index: -1;
    top: -30px;
    display: flex;
    justify-content: center;
    padding-top: 5px;
`

export const MostPopularAmountOptionContainer = styled.div`
    width: 100%;
    height: 100%;
    z-index: 3;

    background: #ffffff;
    border: 1px solid #f1edeb;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
`

export const PlansListContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 26px;

    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
    }
`
export const PlanInformationContainer = styled.div`
    box-sizing: border-box;
    padding: 24px 15px 32px 16px;
    position: relative;
    width: 259px;
    height: 460px;

    border-radius: 24px;
    background: ${(props) => (props.color ? props.color : white)};
    margin-bottom: 68px;
    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        height: 380px;
    }
`

export const PlanHeaderContainer = styled.div`
    border-bottom: 1px solid #d5d0cd;
    width: 100%;
    padding-bottom: 24px;
`

export const PlanDescriptionContainer = styled.div`
    padding-top: 24px;
`
export const PlanDetailsContainer = styled.div`
    margin-top: 10px;
`
export const PlanDetailContainer = styled.div`
    display: flex;
    padding: 6px;
    align-items: baseline;
`
export const PlanButtonContainer = styled.div`
    position: absolute;
    bottom: 20px;
    width: 180px;
    left: 50%;
    transform: translateX(-50%);
`

export const PaymentTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 500px;
    margin-bottom: 24px;
`