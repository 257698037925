import React, { memo } from 'react'
import { HeaderContainer, LeftContentContainer, LogoTitle, RightContentContainer } from '../../header-styles'
import LogoIcon from '../../../../../assets/images/logo-icon.svg'
import StyledSVG from '../../../styled-svg/StyledSVG'
import SimpleButton from '../../../button/simple-button/SimpleButton'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../../../utils/utils'

function Header({ rightButtonTitle, rightButtonAction }) {
    const navigate = useNavigate()
    return (
        <HeaderContainer>
            <LeftContentContainer onClick={() => {
                const token = window.localStorage.getItem('api-token')
                navigate(token ? ROUTES.ARTICLES : ROUTES.SIGN_IN)
            }}>
                <StyledSVG src={LogoIcon} />
                <LogoTitle>Quillora</LogoTitle>
            </LeftContentContainer>
            {rightButtonTitle ? <RightContentContainer>
                <SimpleButton text={rightButtonTitle} action={rightButtonAction} />
            </RightContentContainer> : ''}
        </HeaderContainer>
    )
}

export default memo(Header)
