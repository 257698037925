import React, { memo } from 'react'
import { ErrorMessageContainer, FieldLabel, TextInput } from '../styles'
import { RegularText } from '../../styles/text'

const TextField = ({
    label,
    value,
    changeEvent,
    blurEvent,
    placeholder,
    name = '',
    disabled = false,
    type = 'text',
    errorMessage = null,
    min,
    max,
    autoFocus
}) => {
    return (
        <FieldLabel>
            {label}
            <TextInput
                error={errorMessage !== null}
                type={type}
                placeholder={placeholder}
                value={value}
                name={name}
                disabled={disabled}
                onChange={changeEvent}
                onBlur={blurEvent}
                min={min}
                max={max}
                autoFocus={!!autoFocus}
            />
            {errorMessage && (
                <ErrorMessageContainer>
                    <RegularText fontSize={'15px'} fontWeight={400} color={'#FF3D31'}>
                        {errorMessage}
                    </RegularText>
                </ErrorMessageContainer>
            )}
        </FieldLabel>
    )
}

export default memo(TextField)
