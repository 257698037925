import styled, { css } from 'styled-components'

export const MainImage = styled.img`
    max-height: 596px;
    width: fit-content;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;

    @media (max-width: 480px) {
        height: 516px;
        max-width: calc(100% + 32px);
    }
`

export const ArrowContainer = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -60px;
    cursor: pointer;

    ${(props) =>
        props.reversed &&
        css`
            left: -60px;
            right: unset;
            transform: translateY(-50%);

            & svg {
                transform: rotate(180deg);
            }
        `}

    @media (max-width: 480px) {
        right: 16px;
        ${(props) =>
            props.reversed &&
            css`
                left: 16px;
                right: unset;
            `};
    }
`

export const ImageAttributionContainer = styled.div`
    margin-bottom: -22px;
    margin-left: 16px;
    margin-top: 8px;

    & a {
        color: #42a5f5;
    }
`
