import React, { memo, useCallback, useMemo } from 'react'
import { ContentItemContainer } from './styles'
import FolderIcon from '../../../../assets/images/folder.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import ItemWrapper from './ItemWrapper'
import { RegularText } from '../../../common/styles/text'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateValuePopup } from './update-value-popup/hooks/useUpdateValuePopup'
import { selectActiveCompanyUuid, selectActiveFolderUuid } from '../../../../reducers/common'
import { useFolders } from '../../hooks/useFolders'

function AccordionContentItem({ folder, companyUuid, handleFolderSelection, handleCompanySelection, withoutOptions }) {
    const activeFolderUuid = useSelector((state) => state.common.activeFolderUuid)
    const dispatch = useDispatch()
    const openPopup = useUpdateValuePopup()
    const { deleteFolder, renameFolder } = useFolders()

    const handleClick = useCallback(() => {
        if (handleFolderSelection) {
            handleFolderSelection(folder.uuid)
            handleCompanySelection(companyUuid)
            return
        }
        dispatch(selectActiveCompanyUuid({ activeCompanyUuid: companyUuid }))
        dispatch(selectActiveFolderUuid({ activeFolderUuid: folder.uuid }))
    }, [handleCompanySelection, handleFolderSelection, folder])

    const onRename = () => {
        openPopup({
            title: 'Rename folder',
            subTitle: 'Folder name',
            buttonText: 'Save',
            handleClick: (value, close) => {
                renameFolder(value, folder.uuid, companyUuid)
                close()
            },
        })
    }

    const onDelete = () => {
        deleteFolder(folder.uuid, companyUuid)
    }

    const isActive = useMemo(() => {
        if (activeFolderUuid === folder.name) {
            return true
        }
        return false
    }, [activeFolderUuid, folder.name])

    return (
        <ContentItemContainer active={isActive} onClick={handleClick}>
            <StyledSVG src={FolderIcon} />
            <ItemWrapper
                withoutMenu={withoutOptions}
                onRename={onRename}
                onDelete={onDelete}
                content={<RegularText>{folder.name}</RegularText>}
            />
        </ContentItemContainer>
    )
}

export default memo(AccordionContentItem)
