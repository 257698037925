import React, { memo, useState } from 'react'
import {
    ActiveFilterContainer,
    IconContainer,
    FilterContainer,
    FilterOptionsContainer,
    FilterToCollapseContainer,
} from './styles'
import { RegularText } from '../../../common/styles/text'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import FilterIcon from '../../../../assets/images/sort.svg'
import CloseIcon from '../../../../assets/images/close-icon.svg'
import { white } from '../../../common/styles/colors'
import Dropdown from '../../../common/dropdown/Dropdown'
import { ArticleStatus } from '../../../../utils/utils'

function Filter({ changeEvent }) {
    const [showOptions, setShowOptions] = useState(false)
    const [activeFiltering, setActiveFiltering] = useState(null)

    const filteringOptions = [
        { id: ArticleStatus.IN_QUEUE, name: 'In queue', action: () => changeFilterOption(ArticleStatus.IN_QUEUE) },
        { id: ArticleStatus.PAUSED, name: 'Paused', action: () => changeFilterOption(ArticleStatus.PAUSED) },
        { id: ArticleStatus.ACTIVE, name: 'Active', action: () => changeFilterOption(ArticleStatus.ACTIVE) },
    ]

    const changeFilterOption = (id) => {
        const index = filteringOptions.findIndex((filter) => id === filter.id)
        if (index !== -1) {
            setActiveFiltering(filteringOptions[index])
            setShowOptions(false)
            changeEvent(filteringOptions[index].id)
        }
    }

    const cancelFilter = () => {
        setShowOptions(false)
        setActiveFiltering(null)
        changeEvent(null)
    }

    return (
        <FilterContainer>
            {showOptions ? (
                <>
                    <FilterToCollapseContainer background={white} onClick={() => setShowOptions(!showOptions)}>
                        <StyledSVG src={FilterIcon} />
                        <RegularText>&nbsp;&nbsp;Filter By</RegularText>
                    </FilterToCollapseContainer>
                    <FilterOptionsContainer>
                        <Dropdown alignText="start" items={filteringOptions} />
                    </FilterOptionsContainer>
                </>
            ) : (
                <FilterToCollapseContainer onClick={() => setShowOptions(!showOptions)}>
                    <StyledSVG src={FilterIcon} />
                    <RegularText>&nbsp;&nbsp;Filter By</RegularText>
                </FilterToCollapseContainer>
            )}

            {activeFiltering && (
                <ActiveFilterContainer onClick={cancelFilter}>
                    <RegularText color={white}>{activeFiltering.name}</RegularText>
                    <IconContainer>
                        <StyledSVG color={white} src={CloseIcon} />
                    </IconContainer>
                </ActiveFilterContainer>
            )}
        </FilterContainer>
    )
}

export default memo(Filter)
