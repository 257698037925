import React, { memo, useCallback, useMemo, useState } from 'react'
import AnimatedAccordion from '../../../common/animated-accordion/AnimatedAccordion'
import AccordionTitle from './AccordionTitle'
import { CompanyListContainer, FolderListContainer } from './styles'
import CompanyItem from './CompanyItem'

function CompaniesList({ companyData, handleCompanySelection, handleFolderSelection, withoutOptions }) {
    const [openCompanyIndexes, setOpenCompanyIndexes] = useState([])

    const updateState = useCallback(
        (index) => {
            const companyIndex = openCompanyIndexes.findIndex((companyIndex) => companyIndex === index)

            if (companyIndex === -1) {
                setOpenCompanyIndexes((prevState) => [...prevState, index])
            } else {
                const updatedState = [...openCompanyIndexes]
                updatedState.splice(companyIndex, 1)
                setOpenCompanyIndexes(updatedState)
            }
        },
        [openCompanyIndexes],
    )

    const companiesList = useMemo(() => {
        return companyData.map((company, index) => (
            <AnimatedAccordion
                key={index}
                titleComponent={
                    <AccordionTitle
                        isOpen={openCompanyIndexes.includes(index)}
                        companyName={company.name}
                        companyUuid={company.uuid}
                        handleCompanySelection={handleCompanySelection}
                        handleFolderSelection={handleFolderSelection}
                        withoutOptions={withoutOptions}
                    />
                }
                contentComponent={
                    <FolderListContainer>
                        <CompanyItem
                            companyUuid={company.uuid}
                            handleCompanySelection={handleCompanySelection}
                            handleFolderSelection={handleFolderSelection}
                            withoutOptions={withoutOptions}
                        />
                    </FolderListContainer>
                }
                isOpen={openCompanyIndexes.includes(index)}
                setIsOpen={() => updateState(index)}
            />
        ))
    }, [companyData, openCompanyIndexes, handleCompanySelection, handleFolderSelection, withoutOptions])

    return <CompanyListContainer>{companiesList}</CompanyListContainer>
}

export default memo(CompaniesList)
