import React, { memo } from 'react'
import { SearchIconContainer, SearchFieldContainer, SearchInput } from './styles'
import StyledSVG from '../../styled-svg/StyledSVG'
import SearchIcon from '../../../../assets/images/search.svg'

const SearchField = ({ value, changeEvent, placeholder, width, height, ...rest }) => {
    return (
        <SearchFieldContainer width={width} height={height}>
            <SearchIconContainer>
                <StyledSVG src={SearchIcon} />
            </SearchIconContainer>
            <SearchInput
                type="text"
                placeholder={placeholder || 'Search'}
                value={value}
                onChange={(event) => changeEvent(event.target.value)}
                {...rest}
            />
        </SearchFieldContainer>
    )
}

export default memo(SearchField)
