import React, { memo } from 'react'
import { ButtonComponent, ButtonText } from '../styles'
import { orange, white } from '../../styles/colors'

const SimpleButton = ({
    text,
    action,
    color = white,
    background = orange,
    width = null,
    margin = null,
    additionalInformation = null,
    disabled = false,
}) => {
    return (
        <ButtonComponent width={width} disabled={disabled} background={background} margin={margin} onClick={action}>
            {additionalInformation}
            <ButtonText color={color}>{text}</ButtonText>
        </ButtonComponent>
    )
}

export default memo(SimpleButton)
