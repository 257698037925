import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const signIn = createAsyncThunk('user/auth/sign-in', async ({ email, password, recaptcha }, thunkApi) => {
    try {
        return await UserAgent.signIn(email, password, recaptcha)
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const googleAuth = createAsyncThunk('user/auth/google', async ({ accessCode }, thunkApi) => {
    try {
        return await UserAgent.googleAuth(accessCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})
