import React, { memo } from 'react'
import { ButtonIcon } from './styles'
import { ButtonComponent, ButtonText } from '../styles'
import { white } from '../../styles/colors'

const IconButton = ({ text, action, icon, color = white }) => {
    return (
        <ButtonComponent onClick={action}>
            <ButtonIcon src={icon} />
            <ButtonText color={color}>{text}</ButtonText>
        </ButtonComponent>
    )
}

export default memo(IconButton)
