import React, { memo, useEffect } from 'react'
import {
    ArticleContainer,
    ArticleSettingsContainer,
    IconContainer,
    OutlineArticleSettingsContainer,
    OutlineSectionListWrapper,
    OutlineSectionNavigationContainer,
    ReprocessContainer,
    ReprocessCostContainer,
    ReprocessTitleContainer,
} from './styles'
import { RegularText } from '../../common/styles/text'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import PrivateArea from '../../common/layouts/private-area/PrivateArea'
import ParametersDetails from './ParametersDetails'
import { clearArticle, selectArticle } from '../../../reducers/articles'
import { useDispatch, useSelector } from 'react-redux'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { showPopup } from '../../../reducers/popup'
import CachedIcon from '../../../assets/images/cached.svg'
import DatabaseIcon from '../../../assets/images/database.svg'
import { black } from '../../common/styles/colors'
import { useParams } from 'react-router'
import { getArticle } from '../slice'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import { EditableSectionWithHeaderContainer, OutlineTitleContainer } from './editable-article/styles'
import EditableSectionsList from './editable-article/EditableSectionsList'
import BackNavigation from './editable-article/components/outline-components/BackNavigation'

const HARD_CODED_DATA = [
    {
        subtitle: 'Intruduction 1',
        words: 500,
        content: ['Intruduction', 'How to create smth', 'how to select smth'],
    },
    {
        subtitle: 'Intruduction 2',
        words: 500,
        content: ['Intruduction', 'How to create smth', 'how to select smth'],
    },
    {
        subtitle: 'Intruduction 3',
        words: 500,
        content: ['Intruduction', 'How to create smth', 'how to select smth'],
    },
    {
        subtitle: 'Intruduction 4',
        words: 500,
        content: ['Intruduction', 'How to create smth', 'how to select smth'],
    },
]

function ArticleOutline() {
    const dispatch = useDispatch()
    let { articleId } = useParams()

    const article = useSelector(selectArticle)
    const priceValue = useSelector((state) => state.common.articlePrice)

    useEffect(() => {
        if (articleId) {
            dispatch(getArticle(articleId))
        }

        return () => {
            dispatch(clearArticle())
        }
    }, [])

    return (
        <>
            <OutlineSectionNavigationContainer>
                <BackNavigation title="Back" />
            </OutlineSectionNavigationContainer>
            <PrivateArea>
                <ArticleContainer>
                    <EditableSectionWithHeaderContainer>
                        <OutlineTitleContainer>
                            <RegularText fontSize="32px" fontWeight={600}>
                                Outline review
                            </RegularText>
                            <RegularText fontSize="14px" fontWeight={400}>
                                You can make changes manually or reprocess all article.
                            </RegularText>
                        </OutlineTitleContainer>
                        <OutlineSectionListWrapper>
                            <EditableSectionsList sectionsContent={HARD_CODED_DATA} sectionType="outline" />
                        </OutlineSectionListWrapper>
                    </EditableSectionWithHeaderContainer>
                    <OutlineArticleSettingsContainer showOnMobile={true} marginTop="10px">
                        <SimpleButton background={black} width="100%" text="Submit" />
                        <ReprocessContainer>
                            <ReprocessTitleContainer
                                onClick={() =>
                                    dispatch(
                                        showPopup({
                                            component: PopupComponentKeys.REPROCESS_CONFIRMATIONS,
                                            extraData: { articleId: article.id },
                                        }),
                                    )
                                }
                            >
                                <IconContainer>
                                    <StyledSVG src={CachedIcon} />
                                </IconContainer>
                                <RegularText>Reprocess the Article</RegularText>
                            </ReprocessTitleContainer>
                            <ReprocessCostContainer>
                                <StyledSVG color={black} src={DatabaseIcon} />
                                <RegularText>{priceValue}</RegularText>
                            </ReprocessCostContainer>
                        </ReprocessContainer>
                        <ParametersDetails currentArticle={article} />
                    </OutlineArticleSettingsContainer>
                </ArticleContainer>
            </PrivateArea>
        </>
    )
}

export default memo(ArticleOutline)
