import React, { memo } from 'react'
import { RegularText } from '../../common/styles/text'
import { PopupContent } from '../styles'
import { ButtonContainer, Feature, FeatureList, FeatureTitle, MainInformationContainer } from './styles'
import DownloadIcon from '../../../assets/images/download.svg'
import LinkButton from '../../common/button/link-button/LinkButton'
import CsvTemplate from '../../../assets/templates/template.csv'

function CsvInstructionPopup() {
    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    CSV instructions
                </RegularText>
            </div>
            <MainInformationContainer>
                    <RegularText lineHeight={'24px'} fontWeight={600}>
                        Exclusive for Paid Subscribers
                    </RegularText>
                    <FeatureList>
                        <Feature>
                            <FeatureTitle>Download:</FeatureTitle>
                            Click the "Download CSV Template" button.
                        </Feature>
                        <Feature>
                            <FeatureTitle>Fill Out:</FeatureTitle>
                            "Keyword Phrase" and "Company Name" are mandatory. All other fields are optional.
                        </Feature>
                        <Feature>
                            <FeatureTitle>Tones:</FeatureTitle>
                            If specifying a tone, choose from: professional, friendly, instructional, persuasive, conversational, inspirational, authoritative, urgent, or fun.
                        </Feature>
                        <Feature>
                            <FeatureTitle>Save:</FeatureTitle>
                            Store the filled CSV on your device.
                        </Feature>
                        <Feature>
                            <FeatureTitle>Upload:</FeatureTitle>
                            Press "Upload CSV" below "Create" to queue up your articles.
                        </Feature>
                    </FeatureList>
                    <RegularText lineHeight={'24px'} fontWeight={600}>
                        This is a premium feature unavailable for free accounts. Ensure details are accurate before upload to streamline the content creation process.
                    </RegularText>
                <ButtonContainer>
                    <LinkButton
                        iconPrefix={DownloadIcon}
                        href={CsvTemplate}
                        text="Download CSV template"
                        target="_blank"
                    />
                </ButtonContainer>
            </MainInformationContainer>
        </PopupContent>
    )
}

export default memo(CsvInstructionPopup)
