import styled, { css } from 'styled-components'
import { black, darkGray } from '../../../common/styles/colors'
import { TagContainer } from '../side-menu/filters/styles'

export const FilterContainer = styled.div`
    position: relative;
    margin-left: 40px;
    justify-content: start;
    display: flex;
    height: 40px;
    width: fit-content;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 200px;
        margin-left: -10px;

        & p {
            font-size: 14px;
        }
    }
`

export const FilterOptionsContainer = styled.div`
    position: absolute;
    top: 50px;
    left: -84px;
    z-index: 999;
    height: 100%;
    width: 200px;

    @media (max-width: 480px) {
        width: 100px;
    }
`

export const ActiveFilterContainer = styled.div`
    margin-left: 20px;
    background: #282828;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 32px;
    padding: 0 20px;
`

export const IconContainer = styled.div`
    margin-left: 10px;
    display: flex;
`

export const FilterToCollapseContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.background ? props.background : 'transparent')};
    border-radius: 32px;
    padding: 10px 15px;
`

export const TagsContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    max-width: 85%;
`

export const ArrowContainer = styled.div`
    & svg {
        margin-left: 8px;
        width: 12px;
        height: 12px;
        transition: 0.2s;

        ${(props) =>
            props.isOpen &&
            css`
                transform: rotate(180deg);
            `}

        & path {
            fill: ${black};
        }
    }
`

export const SmallTagContainer = styled(TagContainer)`
    background: #e7e2de;
    padding: 8px 16px;
    column-gap: 12px;

    & svg {
        width: 11px;
        height: 11px;

        & path {
            fill: ${darkGray};
        }
    }
`
