export const formatArticlePayload = (article) => {
    const words = parseInt(article.words, 10)
    const callToAction =
        article.callToActionTitle || article.callToActionLink
            ? { title: article.callToActionTitle || null, link: article.callToActionLink || null }
            : null
    const links = article.links.length === 1 && article.links[0] === '' ? null : article.links

    return {
        companyUuid: article.companyUuid,
        keywords: article.keywords,
        articleTitle: article.articleTitle || null,
        words: words || null,
        tone: article.tone ? article.tone.toLowerCase() : null,
        callToAction,
        links,
        locationId: article.locationId,
        languageCode: article.languageCode,
        additionalKeywords: article.additionalKeywords?.filter(k => !!k).map(k => `"${k}"`).join(', '),
        additionalDetails: article.additionalDetails,
        autoHumanize: !!article.autoHumanize,
    }
}
