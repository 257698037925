import React, { memo, useCallback } from 'react'
import { ContentItemContainer } from './styles'
import AddFolderIcon from '../../../../assets/images/add-folder.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import ItemWrapper from './ItemWrapper'
import { RegularText } from '../../../common/styles/text'
import { naturalGray } from '../../../common/styles/colors'
import { useUpdateValuePopup } from './update-value-popup/hooks/useUpdateValuePopup'
import { useFolders } from '../../hooks/useFolders'

function AddFolderItem({ companyUuid }) {
    const { createFolder } = useFolders()
    const openPopup = useUpdateValuePopup()

    const handleClick = useCallback(() => {
        openPopup({
            title: 'New folder',
            subTitle: 'Folder name',
            buttonText: 'Create',
            handleClick: async (value, close) => {
                await createFolder(value, companyUuid)
                close()
            },
        })
    }, [openPopup])

    return (
        <ContentItemContainer onClick={handleClick}>
            <StyledSVG src={AddFolderIcon} />
            <ItemWrapper
                withoutMenu
                content={
                    <RegularText fontWeight={400} color={naturalGray}>
                        Create folder
                    </RegularText>
                }
            />
        </ContentItemContainer>
    )
}

export default memo(AddFolderItem)
