import React, { memo } from 'react'
import { DropdownContainer, IconContainer, ItemContainer } from './styles'
import StyledSVG from '../styled-svg/StyledSVG'
import { RegularText } from '../styles/text'

const Dropdown = ({ wrapperRef, items, alignText = 'center' }) => {
    return (
        <DropdownContainer ref={wrapperRef}>
            {items.filter(i => !!i).map((item, index) => (
                <ItemContainer alignText={alignText} key={index} onClick={item.action}>
                    <IconContainer>
                        <StyledSVG src={item.icon} />
                    </IconContainer>
                    <RegularText fontWeight={500}>{item.name}</RegularText>
                </ItemContainer>
            ))}
        </DropdownContainer>
    )
}

export default memo(Dropdown)
