import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../api/userAgent'
import { PaymentAgent } from '../../api/paymentAgent'
import { CompanyAgent } from '../../api/companyAgent'

export const updateProfile = createAsyncThunk('user/profile/update', async ({ firstName, lastName }, thunkApi) => {
    try {
        const response = await UserAgent.updateProfile(firstName, lastName)

        return { ...response, firstName: firstName, lastName: lastName }
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const creditPacksProductsList = createAsyncThunk(
    'payment/credit/products/list',
    async (undefined, thunkApi) => {
        try {
            const response = await PaymentAgent.creditPackProductsList()

            return response
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)