import React, { memo } from 'react'
import { ArticleItemContainer } from './styles'
import PausedArticle from './item/PausedArticle'
import InQueueArticle from './item/InQueueArticle'
import { ArticleStatus } from '../../../utils/utils'
import { showPopup } from '../../../reducers/popup'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import InProgressArticle from './item/InProgressArticle'
import NotEnoughCreditArticle from './item/NotEnoughCreditArticle'
import ReadyArticle from "./item/ReadyArticle";
import ErrorArticle from './item/ErrorArticle'

function ArticleItem({ article }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onItemClick = () => {
        article.status === ArticleStatus.READY || 
        article.status === ArticleStatus.REPROCESSING || 
        (article.status === ArticleStatus.IN_QUEUE && !!article.history.length) ||
        (article.status === ArticleStatus.IN_PROGRESS && !!article.history.length) ? 
        navigate(`/articles/${article.id}`) : 
        dispatch(showPopup({ component: PopupComponentKeys.ARTICLE_PARAMETERS, extraData: { article: article } }))
    }

    return (
        <ArticleItemContainer onClick={onItemClick}>
            {
                {
                    [ArticleStatus.IN_QUEUE]: <InQueueArticle article={article} />,
                    [ArticleStatus.READY]: <ReadyArticle article={article} />,
                    [ArticleStatus.IN_PROGRESS]: <InProgressArticle article={article} />,
                    [ArticleStatus.PAUSED]: <PausedArticle article={article} />,
                    [ArticleStatus.NOT_ENOUGH_CREDIT]: <NotEnoughCreditArticle article={article} />,
                    [ArticleStatus.ERROR]: <ErrorArticle article={article} />,
                }[article.status]
            }
        </ArticleItemContainer>
    )
}

export default memo(ArticleItem)
