import React, { memo, useEffect } from 'react'
import { RegularText } from '../../common/styles/text'
import { BlockContainer, ButtonContainer, GeneralButtonContainer, SeparateLineContainer, MainContainer } from './styles'
import { black, orange } from '../../common/styles/colors'
import { FormContainer } from '../../common/styles/form-styles'
import { FieldContainer, FieldsInRowContainer, ShortFieldContainer } from '../../common/form/text-field/styles'
import TextField from '../../common/form/text-field/TextField'
import TransparentBorderIconButton from '../../common/button/transparent-border-button/TransparentBorderButton'
import LockIcon from '../../../assets/images/lock.svg'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import { showPopup } from '../../../reducers/popup'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeFirstNameValue,
    changeLastNameValue,
    selectProfileFormData,
    selectRequestStatus,
    selectValidationErrors,
    setValidationError,
    changeRequestStatus,
    setProfile,
    resetForm,
} from '../../../reducers/forms/profile-form'
import { selectUserEmail, selectUserProfile } from '../../../reducers/user'
import { updateProfile } from '../slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loading from '../../common/loading/Loading'

function General() {
    const dispatch = useDispatch()
    const profile = useSelector(selectUserProfile)
    const userEmail = useSelector(selectUserEmail)
    const profileForm = useSelector(selectProfileFormData)
    const requestStatus = useSelector(selectRequestStatus)
    const validationErrors = useSelector(selectValidationErrors)

    useEffect(() => {
        return () => {
            dispatch(resetForm())
        }
    }, [])

    useEffect(() => {
        dispatch(setProfile({ firstName: profile?.firstName, lastName: profile?.lastName }))
    }, [profile])

    const changeFirstNameField = (event) => {
        event.preventDefault()
        dispatch(changeFirstNameValue({ firstName: event.target.value }))
    }

    const changeLastNameField = (event) => {
        event.preventDefault()
        dispatch(changeLastNameValue({ lastName: event.target.value }))
    }

    const showChangePasswordForm = () => {
        dispatch(showPopup({ component: PopupComponentKeys.CHANGE_PASSWORD }))
    }

    const saveChanges = (event) => {
        const { firstName, lastName } = profileForm

        const validateField = (fieldName, fieldValue, regex, errorMessage) => {
            if (!regex.test(fieldValue)) {
                dispatch(setValidationError({ field: fieldName, message: errorMessage }))
                return false
            }
            return true
        }

        let validForm = true
        validForm = validateField('firstName', firstName, /.+/, 'Empty first name') && validForm
        validForm = validateField('lastName', lastName, /.+/, 'Empty last name') && validForm

        if (validForm) {
            dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
            dispatch(updateProfile({ firstName: profileForm.firstName, lastName: profileForm.lastName }))
        }
    }

    return (
        <>
            {requestStatus === REQUEST_STATUSES.PENDING && <Loading />}
            <MainContainer>
                <BlockContainer>
                    <div>
                        <RegularText fontSize={'24px'} lineHeight={'32px'} color={black}>
                            Name
                        </RegularText>
                    </div>
                    <div>
                        <FormContainer width={'80%'}>
                            <FieldsInRowContainer>
                                <ShortFieldContainer>
                                    <fieldset>
                                        <TextField
                                            label={'First name'}
                                            errorMessage={validationErrors.firstName}
                                            value={profileForm.firstName}
                                            changeEvent={changeFirstNameField}
                                        />
                                    </fieldset>
                                </ShortFieldContainer>
                                <ShortFieldContainer>
                                    <fieldset>
                                        <TextField
                                            label={'Last name'}
                                            errorMessage={validationErrors.lastName}
                                            value={profileForm.lastName}
                                            changeEvent={changeLastNameField}
                                        />
                                    </fieldset>
                                </ShortFieldContainer>
                            </FieldsInRowContainer>
                        </FormContainer>
                    </div>
                </BlockContainer>
                <BlockContainer>
                    <div>
                        <RegularText fontSize={'24px'} lineHeight={'32px'} color={black}>
                            Contact Info
                        </RegularText>
                    </div>
                    <FormContainer width={'38%'}>
                        <FieldContainer>
                            <fieldset>
                                {/*todo: add validation*/}
                                <TextField label={'Email'} disabled={true} value={userEmail} />
                            </fieldset>
                        </FieldContainer>
                    </FormContainer>
                    <SeparateLineContainer />
                    <RegularText fontSize={'24px'} lineHeight={'32px'} color={black}>
                        Password
                    </RegularText>
                    <ButtonContainer>
                        <TransparentBorderIconButton
                            color={orange}
                            text="Change password"
                            icon={LockIcon}
                            action={showChangePasswordForm}
                        />
                    </ButtonContainer>
                </BlockContainer>
                {/* <BlockContainer>
                    <div>
                        <RegularText fontSize={'24px'} lineHeight={'32px'} color={black}>
                            Delete my account
                        </RegularText>
                        <RegularText fontWeight={'400'} lineHeight={'32px'} color={black}>
                            Do you want to downgrade instead? Manage Subscriptions
                        </RegularText>
                    </div>
                    <ButtonContainer>
                        <TransparentBorderIconButton color={orange} text="Delete my account" />
                    </ButtonContainer>
                </BlockContainer> */}

                <GeneralButtonContainer>
                    <SimpleButton action={saveChanges} text="Save changes" />
                </GeneralButtonContainer>
            </MainContainer>
        </>
    )
}

export default memo(General)
