import styled, { css } from 'styled-components'
import { black, naturalGray, orange } from '../../../common/styles/colors'
import { DROPZONE_HEIGHT, DROPZONE_HEIGHT_MOBILE } from './utils'

export const TabsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d5d0cd;
    align-self: center;
    align-content: center;
    margin: auto;
    cursor: pointer;
    @media (max-width: 780px) {
        width: 100%;
    }
`

export const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    margin: 32px 0 0;
    width: 100%;
    padding-bottom: 13px;
    z-index: 9;
    text-align: center;
    position: relative;
    top: 3px;

    & p {
        color: ${(props) => (props.active ? black : naturalGray)};
    }

    & svg path {
        fill: ${(props) => (props.active ? black : naturalGray)};
    }

    @media (max-width: 780px) {
        width: 100%;
        top: 23px;
        margin: 20px 0;

        & p {
            font-size: 14px;
            font-weight: 400;
        }
    }
`

export const TabUnderline = styled.span`
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: ${(props) => props.offset}%;
    background: ${black};
    transition: 0.5s;
`

export const ImageGallery = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 7px;
    column-gap: 7px;
    overflow-y: auto;

    @media (max-width: 480px) {
        max-height: 450px;
    }
`

export const GalleryImage = styled.img`
    height: 141px;
    width: 32%;
    object-fit: cover;
    border-radius: 2px;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 48%;
        height: 107px;
    }
`

export const DropzoneContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.height || DROPZONE_HEIGHT + 'px'};
    width: 100%;
    border: 1px dashed #d4d0cd;
    border-radius: 8px;
    cursor: pointer;
    ${(props) =>
        props.height &&
        css`
            padding: 8px 0 16px;
        `}

    @media (max-width: 480px) {
        height: ${(props) => props.height || DROPZONE_HEIGHT_MOBILE + 'px'};
    }
`

export const DropzoneWrapper = styled.div`
    height: ${DROPZONE_HEIGHT}px;

    @media (max-width: 480px) {
        height: ${DROPZONE_HEIGHT_MOBILE}px;

        & svg circle {
            fill: #f9f9f9;
        }
    }
`

export const HiglightedText = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: ${orange};
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const TextContainer = styled.div`
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
`

export const PreviewContainer = styled.div`
    padding: 0 16px;

    & svg {
        width: 16px;
        height: 16px;
    }
`

export const PreviewImage = styled.img`
    max-height: 596px;
    max-width: calc(100% - 32px);
    margin: auto;
    border-radius: 10px;
    object-fit: contain;

    @media (max-width: 480px) {
        height: 516px;
    }
`

export const ResponsiveContainer = styled.div`
    & .skeleton-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 8px;
    }

    @media (max-width: 480px) {
        height: 506px;
    }
`
