import React, { memo, useState, useEffect, useRef } from 'react'
import UserBlock from './user-block/UserBlock'
import UserPopover from './user-popover/UserPopover'
import StyledSVG from '../../../styled-svg/StyledSVG'
import LogoIcon from '../../../../../assets/images/logo-icon.svg'
import DatabaseIcon from '../../../../../assets/images/database.svg'
import IconButton from '../../../button/icon-button/IconButton'
import { HeaderContainer, LeftContentContainer, RightContentContainer, LogoTitle } from '../../header-styles'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { selectPaymentInformation } from '../../../../../reducers/user'
import { ROUTES } from '../../../../../utils/utils'
import Hamburger from 'hamburger-react'
import { MenuConatiner } from '../styles'
import { toggleBurgerMenu } from '../../../../../reducers/common'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import SideMenu from '../../../../article/list/side-menu/SideMenu'

function Header() {
    const dispatch = useDispatch()
    const burgerMenuOpen = useSelector((state) => state.common.burgerMenuOpen)
    const { isMobile } = useWindowDimensions()
    const wrapperRef = useRef(null)
    const navigate = useNavigate()
    const payment = useSelector(selectPaymentInformation)

    const [credits, setCredits] = useState(0)
    const [showPopover, setShowPopover] = useState(false)

    useEffect(() => {
        if (payment) {
            setCredits(payment.credits)
        }
    }, [payment])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowPopover(false)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    return (
        <>
            {isMobile && <SideMenu />}
            <HeaderContainer>
                {isMobile && (
                    <MenuConatiner>
                        <Hamburger
                            toggled={burgerMenuOpen}
                            onToggle={() => dispatch(toggleBurgerMenu())}
                            size={20}
                            color="#D9D9D9"
                        />
                    </MenuConatiner>
                )}
                <LeftContentContainer onClick={() => navigate(ROUTES.ARTICLES)}>
                    <StyledSVG src={LogoIcon} />
                    <LogoTitle>Quillora</LogoTitle>
                </LeftContentContainer>
                <RightContentContainer>
                    <IconButton text={credits} icon={DatabaseIcon} action={() => navigate(ROUTES.CREDIT)} />
                    <UserBlock
                        showPopupCallback={(event) => {
                            event.preventDefault()
                            setShowPopover(!showPopover)
                        }}
                    />
                    {showPopover && (
                        <UserPopover
                            closePopover={() => {
                                setShowPopover(false)
                            }}
                            wrapperRef={wrapperRef}
                        />
                    )}
                </RightContentContainer>
            </HeaderContainer>
        </>
    )
}

export default memo(Header)
