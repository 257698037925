import React, { memo } from 'react'
import { ButtonContainer, OutlineInput, OutlineSectionContentItemContainer } from './styles'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import DehazeIcon from '../../../../../../assets/images/dehaze.svg'
import AddIcon from '../../../../../../assets/images/add.svg'
import RemoveIcon from '../../../../../../assets/images/remove.svg'

function OutlineSectionContentItem({ value, setValue, withIcon, isLast, onDelete, onAdd }) {
    return (
        <OutlineSectionContentItemContainer>
            {withIcon && <StyledSVG src={DehazeIcon} />}
            <OutlineInput value={value} onChange={(e) => setValue(e.target.value)} placeholder='Enter main point' />
            <ButtonContainer>
                <StyledSVG src={RemoveIcon} onClick={onDelete} />
                {isLast && <StyledSVG src={AddIcon} onClick={onAdd} />}
            </ButtonContainer>
        </OutlineSectionContentItemContainer>
    )
}

export default memo(OutlineSectionContentItem)
