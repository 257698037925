import styled from 'styled-components'
import { orange } from '../../styles/colors'

export const ButtonComponent = styled.a`
    position: relative;
    display: flex;
    height: 48px;
    width: inherit;
    border: none;
    background: ${(props) => props.background ?? orange};
    border-radius: 24px;
    justify-content: center;
    padding: 12px 24px;
    box-sizing: border-box;
    align-items: center;
    margin: ${(props) => props.margin ?? '0 auto'};
    text-decoration: none;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ff6a17;
    }
`

export const ButtonPrefixIcon = styled.img`
    margin-right: 8px;
`

export const ButtonSuffixIcon = styled.img`
    margin-left: 8px;
`
