import React, { memo, useState, useMemo, useEffect, useCallback } from 'react'
import { RegularText } from '../../common/styles/text'
import { FieldContainer } from '../../common/form/text-field/styles'
import TextField from '../../common/form/text-field/TextField'
import { FormContainer } from '../../common/styles/form-styles'
import TextareaField from '../../common/form/textarea-field/TextareaField'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import ArrowUpIcon from '../../../assets/images/arrow-up.svg'
import ArrowDownIcon from '../../../assets/images/arrow-down.svg'
import IconField from '../../common/form/icon-field/IconField'
import UrlIcon from '../../../assets/images/url.svg'
import SettingsIcon from '../../../assets/images/settings.svg'
import SelectboxField from '../../common/form/selectbox/SelectboxField'
import DynamicGroupField from '../../common/form/dynamic-group-field/DynamicGroupField'
import {
    ButtonsContainer,
    CheckboxContainer,
    LabelParenthetic,
    OptionalInputsCollapseHeaderContainer,
    OptionalInputsTitleContainer,
    OutOfCreditContainer,
    OutOfCreditLinkContainer,
    PopupContent,
    SeparateLineContainer,
} from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, selectCreateArticleFormData, setFieldValue } from '../../../reducers/forms/create-article-form'
import SubmitArticleButton from './submit-article-button/SubmitArticleButton'
import { PopupComponentKeys } from '../../common/popup/Popup'
import { closePopup } from '../../../reducers/popup'
import { NotificationManager } from 'react-notifications'
import { black, darkGray, naturalGray, redOrange, white } from '../../common/styles/colors'
import { useNavigate } from 'react-router'
import { createArticle, getArticles, getLanguages, getLocations } from '../slice'
import { CompanyStatus, ROUTES } from '../../../utils/utils'
import { selectCompanies, selectPaymentInformation } from '../../../reducers/user'
import { formatArticlePayload } from '../../../utils/formatArticlePayload'
import { ErrorMessagesContainer } from '../csv-upload/styles'
import { validateArticlePayload } from '../../../utils/validateArticlePayload'
import Checkbox from '../../common/switch/Checkbox'
import CompaniesList from '../list/side-menu/CompaniesList'
import CompanyFolderDropdown from '../list/CompanyFolderDropdown'
import AutocompleteDropdown from '../../common/autocomplete-dropdown/AutocompleteDropdown'
import { selectArticleLanguages, selectArticleLocations } from '../../../reducers/articles'
import MultilineTextArea from '../../common/multiline-text-area/MultilineTextArea'
import { useArticleLanguages } from '../../common/hooks/useArticleLanguages'
import { useArticleLocations } from '../../common/hooks/useArticleLocations'

function CreateArticlePopup() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const priceValue = useSelector((state) => state.common.articlePrice)
    const { credits } = useSelector(selectPaymentInformation)

    const [optionalInputsShow, setOptionalInputsShow] = useState(false)
    const [errorTextList, setErrorTextList] = useState([])
    const formData = useSelector(selectCreateArticleFormData)
    const userCompanies = useSelector(selectCompanies)
    const articleLocations = useArticleLocations()
    const articleLocationOptions = useMemo(() => articleLocations.map(({ code, name }) => ({ value: code, text: name })), [articleLocations])
    const articleLanguages = useArticleLanguages()
    const articleLanguageOptions = useMemo(() => articleLanguages.map(({ code, name }) => ({ value: code, text: name })), [articleLanguages])

    const companies = useMemo(
        () =>
            userCompanies
                .filter(({ status }) => status === CompanyStatus.ACTIVE)
                .map(({ uuid, name }) => ({ name, value: uuid })),
        [userCompanies],
    )

    const errorText = useMemo(() => {
        if (!errorTextList.length) return
        return errorTextList.map((error) => <RegularText color={redOrange}>{error.message}</RegularText>)
    }, [errorTextList])

    const buyMoreCredit = () => {
        dispatch(closePopup({ component: PopupComponentKeys.CREATE_ARTICLE }))
        navigate(ROUTES.CREDIT)
    }

    const handleChangeField = (event) => {
        event.preventDefault()
        const fieldName = event.target.name

        if (!fieldName) {
            throw new Error('Field name should be specified.')
        }

        dispatch(setFieldValue({ key: fieldName, value: event.target.value }))
    }

    const handleBlurField = (event) => {
        event.preventDefault()
        const fieldName = event.target.name

        if (!fieldName) {
            throw new Error('Field name should be specified.')
        }

        let value = event.target.value
        if (fieldName === 'words' && event.target.value !== '') {
            value = Number.parseInt(event.target.value || 0)
            value = Math.min(Math.max(value, event.target.min), event.target.max).toString()
        }

        dispatch(setFieldValue({ key: fieldName, value }))
    }

    const handleSelectCompany = (key, value) => {
        console.log('key:', key)
        console.log('value:', value)
        dispatch(setFieldValue({ key, value }))
    }

    const handleChangeTone = (key, value) => {
        dispatch(setFieldValue({ key, value }))
    }

    const handleAddList = (name) => {
        const prev = formData[name]
        const next = [...prev, '']

        dispatch(setFieldValue({ key: name, value: next }))
    }

    const handleDeleteList = (name, index) => {
        const prev = formData[name]
        const next = [...prev]
        next.splice(index, 1)

        dispatch(setFieldValue({ key: name, value: next }))
    }

    const handleUpdateList = (name, event) => {
        const index = event.target.attributes['data-index'].value
        const value = event.target.value

        const prev = formData[name]
        const next = [...prev]
        next[index] = value

        dispatch(setFieldValue({ key: name, value: next }))
    }

    const handleCreateArticle = async () => {
        try {
            const articleData = formatArticlePayload(formData)
            const errorMessages = [] // todo: add possibility to keep a list of errors
            const message = validateArticlePayload(articleData)
            if (message) {
                errorMessages.push({ message })
            }

            if (!errorMessages.length) {
                setErrorTextList([])
                await dispatch(createArticle(articleData)).unwrap()
                NotificationManager.info('Article submitted')
                await dispatch(getArticles())
            } else {
                setErrorTextList(errorMessages)
                return
            }
        } catch (err) {
            NotificationManager.error('Article submission failed')

            console.error(err)
        }

        dispatch(resetForm())
        dispatch(closePopup({ component: PopupComponentKeys.CREATE_ARTICLE }))
    }

    return (
        <PopupContent>
            <div>
                <RegularText lineHeight="32px" fontSize="24px">
                    Create Article
                </RegularText>
            </div>
            <div>
                <FormContainer>
                    <fieldset>
                        <FieldContainer>
                            <fieldset>
                                <TextareaField
                                    value={formData.keywords}
                                    changeEvent={handleChangeField}
                                    label="Keyword Phrase"
                                    placeholder="Add keyword phrase"
                                    name="keywords"
                                />
                            </fieldset>
                        </FieldContainer>
                        <FieldContainer>
                            <fieldset>
                                <CompanyFolderDropdown
                                    handleSelectCompany={handleSelectCompany}
                                    companyUuid={formData.companyUuid}
                                    folderUuid={formData.folderUuid}
                                />
                            </fieldset>
                        </FieldContainer>
                        <SeparateLineContainer />
                        <>
                            <OptionalInputsCollapseHeaderContainer
                                onClick={() => setOptionalInputsShow(!optionalInputsShow)}
                            >
                                <OptionalInputsTitleContainer>
                                    <RegularText color={darkGray} fontWeight={500} marginRight="10px">
                                        Optional Inputs
                                    </RegularText>
                                    <RegularText color={naturalGray} fontWeight={500} fontSize="14px" lineHeight="18px">
                                        Title, Word Count, Links, Company Name
                                    </RegularText>
                                </OptionalInputsTitleContainer>
                                <div>
                                    {optionalInputsShow ? (
                                        <StyledSVG src={ArrowUpIcon} />
                                    ) : (
                                        <StyledSVG color={black} width={12} height={9} src={ArrowDownIcon} />
                                    )}
                                </div>
                            </OptionalInputsCollapseHeaderContainer>

                            {optionalInputsShow && (
                                <div>
                                    {/* <FieldContainer>
                                        <fieldset>
                                            <TextField
                                                label="Article Title"
                                                placeholder="Enter the title or we will create it automatically"
                                                name="articleTitle"
                                                changeEvent={handleChangeField}
                                            />
                                        </fieldset>
                                    </FieldContainer> */}
                                    <FieldContainer>
                                        <fieldset>
                                            {/*todo: add validation*/}
                                            <TextField
                                                value={formData.words}
                                                label="Target Word Count"
                                                placeholder="Default is 1000 words"
                                                min="500"
                                                max="3000"
                                                type="number"
                                                name="words"
                                                blurEvent={handleBlurField}
                                                changeEvent={handleChangeField}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <fieldset>
                                            <AutocompleteDropdown
                                                name="locationId"
                                                value={formData.locationId}
                                                placeholder='Select Geo Location'
                                                label="Target Geo Location"
                                                items={articleLocationOptions}
                                                onChange={handleChangeField}
                                                maxResults={25}
                                                forceFilter={true}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <fieldset>
                                            <AutocompleteDropdown
                                                name="languageCode"
                                                placeholder='Select Language'
                                                value={formData.languageCode}
                                                label="Language"
                                                items={articleLanguageOptions}
                                                onChange={handleChangeField}
                                                forceFilter={false}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <fieldset>
                                            <SelectboxField
                                                label="Tone of Article"
                                                name="tone"
                                                selectedValue={formData.tone}
                                                changeValueEvent={(value, fieldName) =>
                                                    handleChangeTone(fieldName, value)
                                                }
                                                options={[
                                                    { name: 'Professional', value: 'professional' },
                                                    { name: 'Friendly', value: 'friendly' },
                                                    { name: 'Instructional', value: 'instructive' },
                                                    { name: 'Persuasive', value: 'persuasive' },
                                                    { name: 'Conversational', value: 'conversational' },
                                                    { name: 'Inspirational', value: 'inspirational' },
                                                    { name: 'Authoritative', value: 'authoritative' },
                                                    { name: 'Urgent', value: 'urgent' },
                                                    { name: 'Fun', value: 'fun' },
                                                ]}
                                            />
                                        </fieldset>
                                    </FieldContainer>

                                    <FieldContainer>
                                        <fieldset>
                                            {/*todo: add validation*/}
                                            <TextField
                                                label={
                                                    <>
                                                        Call to Action{' '}
                                                        <LabelParenthetic>
                                                            (call to action uses selected company)
                                                        </LabelParenthetic>
                                                    </>
                                                }
                                                value={formData.callToActionTitle}
                                                placeholder="Input Desired Action for Custom CTA"
                                                name="callToActionTitle"
                                                changeEvent={handleChangeField}
                                            />
                                        </fieldset>
                                        <fieldset>
                                            {/*todo: add validation*/}
                                            <IconField
                                                icon={UrlIcon}
                                                value={formData.callToActionLink}
                                                placeholder="URL"
                                                name="callToActionLink"
                                                changeEvent={handleChangeField}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <fieldset>
                                            <DynamicGroupField
                                                fields={formData.additionalKeywords}
                                                placeholder="Enter keywords"
                                                label="Additional Keywords"
                                                addButtonText="add a keyword"
                                                eachFieldHasLabel={false}
                                                addFieldAction={() => handleAddList('additionalKeywords')}
                                                deleteFieldAction={(index) => handleDeleteList('additionalKeywords', index)}
                                                changeEventAction={(event) => handleUpdateList('additionalKeywords', event)}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <fieldset>
                                            <MultilineTextArea
                                                name="additionalDetails"
                                                placeholder="Enter additional details"
                                                label="Additional Details"
                                                maxLength={500}
                                                value={formData.additionalDetails}
                                                onChange={handleChangeField}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <fieldset>
                                            <DynamicGroupField
                                                fields={formData.links}
                                                placeholder="Enter a URL address"
                                                label="Links"
                                                addButtonText="add a link"
                                                eachFieldHasLabel={false}
                                                addFieldAction={() => handleAddList('links')}
                                                deleteFieldAction={(index) => handleDeleteList('links', index)}
                                                changeEventAction={(event) => handleUpdateList('links', event)}
                                                icon={UrlIcon}
                                            />
                                        </fieldset>
                                    </FieldContainer>
                                    <CheckboxContainer>
                                        <Checkbox
                                            name="autoHumanize"
                                            width="18px"
                                            height="18px"
                                            borderRadius="4px"
                                            isChecked={formData.autoHumanize}
                                            onChange={(checked) => (
                                                handleChangeField({
                                                    target: { name: 'autoHumanize', value: checked },
                                                    preventDefault: () => {}
                                                })
                                            )}
                                        />
                                        <RegularText fontWeight={500}>Auto-humanize?</RegularText>
                                    </CheckboxContainer>
                                </div>
                            )}
                        </>
                        <ButtonsContainer>
                            <SubmitArticleButton action={handleCreateArticle} text="Submit" price={priceValue} />
                        </ButtonsContainer>
                        {/* <CheckboxContainer>
                            <Checkbox width="18px" height="18px" borderRadius="4px" />
                            <RegularText fontWeight={500}>Outline preview</RegularText>
                        </CheckboxContainer> */}
                        <ErrorMessagesContainer>{errorText}</ErrorMessagesContainer>
                    </fieldset>
                </FormContainer>
                {priceValue > credits && (
                    <OutOfCreditContainer onClick={buyMoreCredit}>
                        <RegularText fontWeight={500} color={white}>
                            Out of credits?
                        </RegularText>
                        <OutOfCreditLinkContainer>Buy more credits</OutOfCreditLinkContainer>
                    </OutOfCreditContainer>
                )}
                <RegularText
                    fontSize="14px"
                    fontWeight={200}
                    color="rgba(0,0,0,.6)"
                    marginTop="15px"
                    textAlign="center"
                    keepFontSize={true}
                >
                    Our automated system aims for precision, but it may occasionally produce unintended results. Users
                    are responsible for content they publish. Please review for content accuracy.
                </RegularText>
            </div>
        </PopupContent>
    )
}

export default memo(CreateArticlePopup)
