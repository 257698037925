import styled from 'styled-components'
import { naturalGray, redOrange } from '../../styles/colors'

export const TextareaInput = styled.textarea`
    position: relative;
    width: 100%;
    height: 48px;
    padding: 10px 14px;
    background: #f9f9f9;
    border: 2px solid #f1edeb;
    box-sizing: border-box;
    border-radius: 8px;
    line-height: 24px;
    margin-top: 4px;
    font-family: 'Inter';

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;

    font-size: 14px;
    font-weight: 400;

    transition: border 275ms ease;

    &::-webkit-input-placeholder {
        /* Edge */
        color: ${naturalGray};
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${naturalGray};
    }

    &::placeholder {
        color: ${naturalGray};
    }

    &:hover,
    &:focus-visible,
    &:focus {
        outline: none;
        box-shadow: none;
        border: solid 2px ${redOrange} !important;
    }

    &:disabled {
        border: 2px solid #f1edeb !important;
    }

    resize: none;
    overflow: hidden;
`
