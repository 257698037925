import React, { memo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const AnimatedAccordion = ({ titleComponent, contentComponent, isOpen, setIsOpen }) => {
    return (
        <>
            <motion.div initial={false}>
                {React.cloneElement(titleComponent, {setIsOpen})}
            </motion.div>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.section
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 },
                        }}
                        transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        {contentComponent}
                    </motion.section>
                )}
            </AnimatePresence>
        </>
    )
}

export default memo(AnimatedAccordion)
