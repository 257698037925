import React, { memo } from 'react'
import { WrapperComponent } from './styles'

function Wrapper({mt, children}) {
    return (
        <WrapperComponent marginTop={mt}>
            {children}
        </WrapperComponent>
    )
}

export default memo(Wrapper)
