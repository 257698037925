import styled from 'styled-components'
import { redOrange, regularGray } from '../styles/colors'
import { Dropdown } from 'semantic-ui-react'

export const AutocompleteDropdownContainer = styled(Dropdown)`
    display: block !important;
    border: 1px solid #f1edeb !important;
    border-radius: 8px !important;
    margin-top: 5px;
    padding: 15px !important;
    min-height: 0 !important;

    &.active {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &:hover,
    &:focus-visible,
    &:focus {
        outline: none;
        box-shadow: none;
        padding: 14px !important;
        border: solid 2px ${redOrange} !important;

        .menu {
            left: 0;
            border: solid 2px ${redOrange} !important;
        }
    }

    .search {
        padding-top: 0.9em !important;
        &:hover,
        &:focus-visible,
        &:focus {
            outline: none;
            box-shadow: none;
            border: solid 2px ${redOrange} !important;
        }
    }

    i {
        cursor: pointer;
        float: right !important;
        font-size: 1.25em !important;
        margin-right: -5px;
    }

    .visible.transition {
        display: block !important;
        visibility: visible !important;
    }


    .menu {
        width: 100% !important;
        min-width: 100% !important;
        left: 0;
        border: 1px solid #f1edeb !important;

        &:hover,
        &:focus-visible,
        &:focus {
            left: 0;
            outline: none;
            box-shadow: none;
            border: solid 2px ${redOrange} !important;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            height: 10px;
        }
        
        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, .1);
            border-radius: 0;
        }

        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            border-radius: 5px;
            background: rgba(0, 0, 0, .25);
            -webkit-transition: color .2s ease;
            transition: color .2s ease;
        }
    }
`

export const FieldLabel = styled.label`
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: ${regularGray};

    &:hover {
        cursor: pointer;
    }
`