import React, { useState } from 'react'
import { AtomicBlockUtils, EditorState } from 'draft-js'
import { RichTextEditorButton } from '../../styles'
import EmbedImageIcon from '../../../../../../assets/images/embed-image.svg'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import EnterInfoPopup from './EnterInfoPopup'

function EmbeddedImage({ editorState, setEditorState }) {
    const [isOpen, setIsOpen] = useState(false)

    const onAddImage = (urlValue) => {
        const contentState = editorState.getCurrentContent()
        const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src: urlValue })
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity }, 'create-entity')
        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '))
    }

    return (
        <RichTextEditorButton onClick={() => setIsOpen((prevState) => !prevState)}>
            <StyledSVG src={EmbedImageIcon} />
            <div
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <EnterInfoPopup isOpen={isOpen} setIsOpen={setIsOpen} onClick={onAddImage} placeholder="Enter URL" />
            </div>
        </RichTextEditorButton>
    )
}

export default EmbeddedImage
