import styled from 'styled-components'

export const DropdownContainer = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 4px;
    border: 1px solid #f1edeb;
    border-radius: 13px;
`

export const ItemContainer = styled.div`
    cursor: pointer;
    background-color: white;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.alignText ? props.alignText : 'center')};
    align-items: center;

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    &:hover {
        background-color: #f9f9f9;
    }
`

export const CheckboxItemContainer = styled(ItemContainer)`
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 4px 16px;
`

export const IconContainer = styled.div`
    margin-right: 10px;
`
