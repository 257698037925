import React, { memo, useEffect, useState } from 'react'
import PrivateArea from '../../common/layouts/private-area/PrivateArea'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PaymentAgent } from '../../../api/paymentAgent'
import PaymentForm from './PaymentForm'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../utils/utils'
import { STRIPE_PUBLISHABLE_KEY } from '../../../configs'

function CreditCheckoutStripe() {
    const navigate = useNavigate()
    const location = useLocation()
    const [clientSecret, setClientSecret] = useState(null)
    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

    useEffect(() => {
        if (
            location.state === null ||
            location.state.productId === undefined
        ) {
            navigate(ROUTES.CREDIT)
            return
        }
        const fetchClientSecret = async () => {
            const result = await PaymentAgent.createCreditPaymentIntent(
                location.state.productId,
            )
            setClientSecret(result.client_secret)
        }

        fetchClientSecret()
    }, [])

    return (
        <PrivateArea>
            {clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <PaymentForm
                        creditAmount={location.state.creditAmount}
                        price={location.state.price}
                        productId={location.state.productId}
                    />
                </Elements>
            )}
        </PrivateArea>
    )
}

export default memo(CreditCheckoutStripe)
