import React, { memo } from 'react'
import { ArrowContainer, IconContainer, UserBlockContainer, UserImageContainer, UserNameContainer } from './styles'
import UserIcon from '../../../../../../assets/images/user-icon.svg'
import ArrowDownIcon from '../../../../../../assets/images/arrow-down.svg'
import { RegularText } from '../../../../styles/text'
import StyledSVG from '../../../../styled-svg/StyledSVG'
import { useSelector } from 'react-redux'
import { selectUserProfile } from '../../../../../../reducers/user'

function UserBlock({ showPopupCallback }) {
    const profile = useSelector(selectUserProfile)

    return (
        <UserBlockContainer onClick={showPopupCallback}>
            <UserImageContainer>
                <IconContainer>
                    <StyledSVG width={16} height={16} color={'#ffffff'} src={UserIcon} />
                </IconContainer>
            </UserImageContainer>
            <UserNameContainer>
                <RegularText color="#FFFFFF">{profile?.firstName}</RegularText>
            </UserNameContainer>
            <ArrowContainer>
                <StyledSVG src={ArrowDownIcon} />
            </ArrowContainer>
        </UserBlockContainer>
    )
}

export default memo(UserBlock)
