import styled, { css } from 'styled-components'
import { bgGray, white } from '../../common/styles/colors'

export const ArticlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 16.25rem;
    margin-top: -1.5rem;
    
    @media (max-width: 780px) {
        margin-left: 0;
    }
`

export const ArticlesContainer = styled.div`
    position: relative;
    padding: 2.5rem;
    width: calc(100% - 5rem);
    display: flex;
    column-gap: 2rem;

    @media (max-width: 480px) {
        width: 100%;
        margin-top: 20px;
        padding: 0 0 40px 0;
        column-gap: 0;
    }
`

export const ArticleActionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.75rem;

    @media (max-width: 480px) {
        justify-content: flex-start;
        padding: 0 16px;
    }
`

export const ArticlesTitleContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 1.25rem;
`

export const SearchContainer = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 480px) {
        padding: 0 20px;
        width: 90%;

        & input {
            width: 220px;
        }
    }
`

export const EmptyArticleListContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: 480px) {
        margin: 0 auto;
    }
`

export const ListContainer = styled.div`
    display: flex;
    position: relative;
    flex-flow: row wrap;

    @media (max-width: 480px) {
        justify-content: space-around;
        padding: 0 15px;
    }
`

export const EditButtonContainer = styled.div`
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
    background: #f1f1f1;
    transition: 0.2s;
    width: 1.75rem;
    height: 1.75rem;

    & svg {
        transform: rotate(90deg);
    }

    ${(props) =>
        props.showMenu &&
        css`
            opacity: 1;
        `}

    @media (max-width: 480px) {
        opacity: 1;
        background: none;
    }
`

export const ArticleItemContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 16.188rem;
    height: 18.75rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 1.5rem;
    background-color: ${white};
    transition: background-color 0.25s;

    &:hover {
        background-color: #f9f9f9;
    }

    &:hover ${EditButtonContainer} {
        opacity: 1 !important;
    }

    @media (max-width: 480px) {
        margin: 4px auto;

        & p {
            font-size: 14px;
        }

        & svg {
            width: 100%;
        }
    }
`

export const AddArticleItemContainer = styled.div`
    position: relative;
    width: 16.188rem;
    height: 18.75rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border-radius: 1.5rem;
    background-color: #ff7425;

    @media (max-width: 480px) {
        width: 100%;
        height: 216px;
        margin: 0 auto;
        margin-bottom: 8px;
    }
`

export const AddButtonContainer = styled.div`
    position: relative;
    margin: 0.25rem;
    height: 13.75rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    @media (max-width: 480px) {
        height: 152px;
    }
`

export const AddIconContainer = styled.div`
    margin-bottom: 1rem;

    @media (max-width: 480px) {
        margin-bottom: 0;

        & svg {
            width: 80px;
        }
    }
`

export const UploadSVGContainer = styled.div`
    display: flex;
    margin: 0.875rem;

    @media (max-width: 480px) {
        margin: 5px 14px;
        display: flex;
        justify-content: space-between;

        & button {
            width: 85%;
            margin: 0;
            height: 44px;
        }
    }
`

/* Paused article style start */

export const HeaderContainer = styled.div`
    background: rgba(226, 27, 0, 0.05);
    height: 2rem;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const KeywordsContainer = styled.div`
    flex-grow: 1;
    padding: 1rem;
    margin-right: 1rem;
`

export const RenewArticleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    margin: 0 1rem;
    width: calc(100% - 2rem);
    border-radius: 2rem;
    border: none;
    background-color: ${bgGray};
    cursor: pointer;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    color: #282828;
`

export const BottomContainer = styled.div`
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 3.125rem;
`

export const StatusContainer = styled.div`
    margin: 1rem 1.25rem;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 480px) {
        margin: 5px 0 5px 8px;

        & svg {
            width: 14px;
        }
        & p {
            font-size: 12px;
        }
    }
`

export const ActionContainer = styled.button`
    margin: 1rem 1.25rem;
    display: flex;
    justify-content: flex-end;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: 480px) {
        margin: 5px 10px 5px 2px;

        & p {
            font-size: 14px;
        }
    }
`

/* Paused article style end */

/* Ready article style starts */

export const PreviewContainer = styled.div`
    position: relative;
`

export const TitleContainer = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5rem;
    margin: 0.25rem 1rem;
    max-width: 12.5rem;
`

export const InfoContainer = styled.div`
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    width: 85%;
    align-items: center;

    @media (max-width: 480px) {
        width: 100%;
        justify-content: start;

        & svg {
            width: 20px;
        }
    }
`

export const ArticlePreviewWrapper = styled.div`
    position: relative;

    &:hover ${EditButtonContainer} {
        opacity: 1;
    }
`

export const CompanyFolderDropdownValueContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
`

/* Ready article style end */
