import styled from 'styled-components'

export const ButtonsContainer = styled.div`
    padding: 4px;
    height: 48px;
    display: flex;
    justify-content: end;
`

export const ButtonContainer = styled.div`
    width: 112px;
    margin-right: 15px;
`

export const MainInformationContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
`
