import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { changePassword } from '../../components/settings/change-password-popup/slice'
import { Status } from '../../utils/utils'
import { NotificationManager } from 'react-notifications'

const initialState = {
    data: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    },
    validationErrors: {},
    globalError: null,
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

function success(state) {
    state.status = Status.SUCCESS
    delete state.globalError
}

const changePasswordFormSlice = createSlice({
    name: 'forms.changePasswordForm',
    initialState,
    extraReducers(builder) {
        builder.addCase(changePassword.rejected, failed)
        builder.addCase(changePassword.fulfilled, (state) => {
            success(state)
            NotificationManager.info(`Password changed`)
        })
    },
    reducers: {
        changeOldPasswordValue: (state, action) => {
            state.data.oldPassword = action.payload.oldPassword
        },
        changeNewPasswordValue: (state, action) => {
            state.data.newPassword = action.payload.newPassword
        },
        changeConfirmPasswordValue: (state, action) => {
            state.data.confirmPassword = action.payload.confirmPassword
        },
        setValidationError: (state, action) => {
            state.validationErrors[[action.payload.field]] = action.payload.message
        },
        resetError: (state) => {
            state.globalError = null
            state.validationErrors = {}
        },
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        resetForm: () => initialState,
    },
})

const selectChangePasswordFormSlice = (state) => state.forms.changePasswordForm

export const selectStep = (state) => selectChangePasswordFormSlice(state).step
export const selectChangePasswordFormData = (state) => selectChangePasswordFormSlice(state).data
export const selectRequestStatus = (state) => selectChangePasswordFormSlice(state).status
export const selectGlobalError = (state) => selectChangePasswordFormSlice(state).globalError
export const selectValidationErrors = (state) => selectChangePasswordFormSlice(state).validationErrors

const { reducer, actions } = changePasswordFormSlice

export const {
    changeOldPasswordValue,
    changeNewPasswordValue,
    changeConfirmPasswordValue,
    changeRequestStatus,
    resetForm,
    resetError,
    setValidationError,
} = actions

export default reducer
