import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const signUp = createAsyncThunk(
    'user/auth/sign-up',
    async ({ email, password, confirmPassword, firstName, lastName, companyName, recaptcha }, thunkApi) => {
        try {
            return await UserAgent.signUp(email, password, confirmPassword, firstName, lastName, companyName, recaptcha)
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data)
        }
    },
)

export const confirmEmail = createAsyncThunk('user/auth/confirm-email', async ({ confirmEmailToken }, thunkApi) => {
    try {
        return await UserAgent.confirmEmail(confirmEmailToken)
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data)
    }
})
