import { useCallback } from 'react'
import { parseCsvToArray } from '../../../utils/csvParser'
import { useSelector } from 'react-redux'
import { selectCompanies } from '../../../reducers/user'
import { selectArticleLanguages, selectArticleLocations } from '../../../reducers/articles'

const ARTICLE_KEY_TOKENS = {
    companyUuid: ['company'],
    keywords: ['keyword', 'phrase'],
    articleTitle: ['title'],
    words: ['target', 'word', 'count'],
    tone: ['tone'],
    callToActionLink: ['call', 'to', 'action', 'url'],
    callToActionTitle: ['call', 'to', 'action'],
    links: ['links'],
    additionalKeywords: ['additional', 'keywords'],
    additionalDetails: ['additional', 'details'],
    languageCode: ['language'],
    locationId: ['location'],
}

const ARTICLE_REQUIRED_FIELDS = ['companyUuid', 'keywords']
const ARTICLE_NUMBER_FIELDS = ['words']
const ARTICLE_ARRAY_FIELDS = ['links']
const ARTICLE_COMPANY_FIELD = 'companyUuid'
const ARTICLE_LOCATION_FIELD = 'locationId'
const ARTICLE_LANGUAGE_FIELD = 'languageCode'

export const useCsvParser = () => {
    const userCompanies = useSelector(selectCompanies)
    const articleLocations = useSelector(selectArticleLocations)
    const articleLanguages = useSelector(selectArticleLanguages)
    const getCompanyUuid = useCallback(
        (companyName) => {
            return userCompanies.find(({ name }) => name.toLowerCase() === companyName.toLowerCase())?.uuid
        },
        [userCompanies],
    )
    const getLocationId = useCallback(
        (locationName) => {
            return articleLocations.find(({ name }) => name.toLowerCase() === locationName.toLowerCase())?.code
        },
        [articleLocations],
    )
    const getLanguageCode = useCallback(
        (languageName) => {
            return articleLanguages.find(({ name }) => name.toLowerCase() === languageName.toLowerCase())?.code
        },
        [articleLanguages],
    )

    const convertRawDataToArticles = useCallback(
        (rawDataArray) => {
            const tokenEntries = Object.entries(ARTICLE_KEY_TOKENS)

            return rawDataArray.map((data) => {
                return Object.keys(data).reduce((article, key) => {
                    const lowercasedKey = key.toLowerCase()
                    const tokenEntry = tokenEntries.find(([, tokens]) =>
                        tokens.every((token) => lowercasedKey.includes(token)),
                    )

                    if (!tokenEntry) return article

                    const [articleObjKey] = tokenEntry
                    const value = data[key]

                    if (ARTICLE_NUMBER_FIELDS.includes(articleObjKey)) {
                        return { ...article, [articleObjKey]: (isNaN(+value) || value === '') ? null : +value }
                    }
                    if (ARTICLE_ARRAY_FIELDS.includes(articleObjKey)) {
                        return { ...article, [articleObjKey]: Array.isArray(value) ? value : [] }
                    }
                    if (articleObjKey === ARTICLE_COMPANY_FIELD) {
                        const companyUuid = getCompanyUuid(value.toString())

                        return { ...article, [articleObjKey]: companyUuid ?? '' }
                    }
                    if (articleObjKey === ARTICLE_LOCATION_FIELD) {
                        const locationId = getLocationId(value.toString())

                        return { ...article, [articleObjKey]: locationId ?? '' }
                    }
                    if (articleObjKey === ARTICLE_LANGUAGE_FIELD) {
                        const languageCode = getLanguageCode(value.toString())

                        return { ...article, [articleObjKey]: languageCode ?? '' }
                    }

                    return { ...article, [articleObjKey]: value }
                }, {})
            })
        },
        [getCompanyUuid],
    )

    // get csv file content as plain string
    const getCsvFileText = useCallback((file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader()

            fileReader.onload = (event) => {
                const text = event.target.result
                resolve(text.toString())
            }

            fileReader.readAsText(file)
        })
    }, [])

    // check if article has all required fields
    const isArticleValid = useCallback((article) => {
        return ARTICLE_REQUIRED_FIELDS.every((key) => !!article[key])
    }, [])

    // parse csv plain text into array of articles
    const parseCsvToArticles = useCallback(
        (str) => {
            const rawDataArray = parseCsvToArray(str)

            return convertRawDataToArticles(rawDataArray)
        },
        [convertRawDataToArticles],
    )

    return {
        parseCsvToArticles,
        isArticleValid,
        getCsvFileText,
    }
}
