import React, { memo, useCallback, useMemo } from 'react'
import { TitleContainer } from './styles'
import ArrowDownIcon from '../../../../assets/images/arrow-drop-down.svg'
import StyledSVG from '../../../common/styled-svg/StyledSVG'
import ItemWrapper from './ItemWrapper'
import { RegularText } from '../../../common/styles/text'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateValuePopup } from './update-value-popup/hooks/useUpdateValuePopup'
import { selectActiveCompanyUuid, selectActiveFolderUuid } from '../../../../reducers/common'
import { deleteCompany, updateCompany } from '../../../settings/slice'
import { NotificationManager } from 'react-notifications'
import { deleteCompanyState, updateCompanyState } from '../../../../reducers/user'
import { Icon } from 'semantic-ui-react'

function AccordionTitle({
    companyName,
    companyUuid,
    isOpen,
    setIsOpen,
    handleCompanySelection,
    handleFolderSelection,
    withoutOptions,
}) {
    const activeCompanyUuid = useSelector((state) => state.common.activeCompanyUuid)
    const activeFolderUuid = useSelector((state) => state.common.activeFolderUuid)
    const dispatch = useDispatch()
    const openPopup = useUpdateValuePopup()

    const handleClick = useCallback(() => {
        if (handleCompanySelection) {
            handleCompanySelection(companyUuid)
            handleFolderSelection(null)
            return
        }
        dispatch(selectActiveCompanyUuid({ activeCompanyUuid: companyUuid }))
        dispatch(selectActiveFolderUuid({ activeFolderUuid: null }))
    }, [handleCompanySelection, handleFolderSelection, companyUuid])

    const toggleAccordionExpanding = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            setIsOpen(!isOpen)
        },
        [isOpen],
    )

    const onRename = () => {
        openPopup({
            title: 'Rename Company',
            subTitle: 'Company Name',
            buttonText: 'Save',
            handleClick: (value, close) => {
                dispatch(updateCompany({ companyId: companyUuid, name: value }))
                    .unwrap()
                    .then(() => {
                        dispatch(updateCompanyState({ uuid: companyUuid, name: value }))
                        NotificationManager.info('Company renamed')
                    })
                    .catch(() => {
                        NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
                    })
                close()
            },
        })
    }

    const onDelete = () => {
        dispatch(deleteCompany(companyUuid))
            .unwrap()
            .then(() => {
                dispatch(deleteCompanyState({ uuid: companyUuid }))
                NotificationManager.info('Company deleted')
            })
            .catch(() => {
                NotificationManager.error('Something went wrong. Please try again, or contact support if the problem persists.')
            })
        close()
    }

    const isActive = useMemo(() => {
        if (activeCompanyUuid === companyUuid && !activeFolderUuid) {
            return true
        }
        return false
    }, [activeCompanyUuid, activeFolderUuid, companyUuid])

    return (
        <TitleContainer isOpen={isOpen} active={isActive && !withoutOptions} onClick={handleClick}>
            <Icon name="caret down" onClick={toggleAccordionExpanding} />
            <ItemWrapper
                withoutMenu={withoutOptions}
                onRename={onRename}
                onDelete={onDelete}
                content={<RegularText>{companyName}</RegularText>}
            />
        </TitleContainer>
    )
}

export default memo(AccordionTitle)
