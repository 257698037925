import styled from 'styled-components'

export const StackComponent = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: ${(props) => props.justify};
    row-gap: ${(props) => props.spacing};
    column-gap: ${(props) => props.spacing};
    width: ${(props) => props.width || 'fit-content'};
`
