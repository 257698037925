export const white = `#ffffff`
export const black = `#282828`
export const darkBlack = `#000000`
export const orange = `#ff6a17`
export const redOrange = `#ff3d31`
export const vividRed = `#E90E00`
export const naturalGray = `#8b8581`
export const bgGray = `#F0F0F0`
export const darkGray = `#484543`
export const regularGray = `#373737`
export const layoutComponentsBackgroundColor = `#FAFBFC`
export const layoutComponentsBorderColor = `#E4E4E4`
export const superLightSilver = `#efefef`
export const lightRedColor = `#e19191`
export const lightGreenColor = `#19AF9D`
