import styled from 'styled-components'

export const ContentContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
`

export const MainContent = styled.div`
    position: absolute;
    left: 0;
    top: 5rem;
    bottom: 0;
    width: 100%;
`

export const MenuConatiner = styled.div`
    margin-right: -38px;
    background: #3A3A3A;
    border-radius: 8px;
    margin-left: 8px;
`