import React, { memo, useCallback, useEffect, useState } from 'react'
import { H1Title, RegularText, SubtitleText } from '../../common/styles/text'
import {
    ButtonsContainer,
    MiddleFormContainer,
    MiddleFormSubtitleContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import TextField from '../../common/form/text-field/TextField'
import { FieldContainer, FieldsInRowContainer, ShortFieldContainer } from '../../common/form/text-field/styles'
import { ButtonBlock, FormContainer } from '../../common/styles/form-styles'
import SimpleButton from '../../common/button/simple-button/SimpleButton'
import { LogInLink, LogInLinkContainer, RuleContainer, ValidationRulesContainer, EulaWarningText} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeEmailValue,
    changeFirstNameValue,
    changeLastNameValue,
    changeStep,
    selectSignUpFormData,
    selectGlobalError,
    selectValidationErrors,
    setValidationError,
    changePasswordValue,
    changeConfirmPasswordValue,
} from '../../../reducers/forms/sign-up-form'
import { emailRegex, ROUTES } from '../../../utils/utils'
import ErrorMessage from '../../common/error-message/ErrorMessage'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { signUp } from './slice'
import { tagEvent } from '../../../utils/tagEvent'
import StyledSVG from '../../common/styled-svg/StyledSVG'
import CheckIcon from '../../../assets/images/check.svg'
import PointIcon from '../../../assets/images/point.svg'

function SinglePageSignUpForm() {
    const dispatch = useDispatch()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const signUpFormData = useSelector(selectSignUpFormData)
    const globalError = useSelector(selectGlobalError)
    const validationErrors = useSelector(selectValidationErrors)

    const [validationRules, setValidationRules] = useState({
        isSymbolValid: false,
        isLengthValid: false,
        isUppercaseValid: false,
        isDigitValid: false,
        invalidCharacters: []
    })

    const changeEmailField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'email', message: null }))
        dispatch(changeEmailValue({ email: event.target.value }))
    }

    const changeFirstNameField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'firstName', message: null }))
        dispatch(changeFirstNameValue({ firstName: event.target.value }))
    }

    const changeLastNameField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'lastName', message: null }))
        dispatch(changeLastNameValue({ lastName: event.target.value }))
    }

    const changePasswordField = (event) => {
        event.preventDefault()
        const password = event.target.value

        const symbolRegex = /[!$@*#%^&()_=+~|{}<>,.?-]/
        const uppercaseRegex = /[A-Z]/
        const digitRegex = /[0-9]/
        const invalidRegex = /[^a-zA-Z0-9!$@*#%^&()_=+~|{}<>,.? -]/g

        setValidationRules({
            isSymbolValid: symbolRegex.test(password),
            isLengthValid: password.length >= 8,
            isUppercaseValid: uppercaseRegex.test(password),
            isDigitValid: digitRegex.test(password),
            invalidCharacters: password.match(invalidRegex) || []
        })

        dispatch(setValidationError({ field: 'password', message: null }))
        dispatch(changePasswordValue({ password: password }))
    }

    const changeConfirmPasswordField = (event) => {
        event.preventDefault()
        dispatch(setValidationError({ field: 'currentPassword', message: null }))
        dispatch(changeConfirmPasswordValue({ confirmPassword: event.target.value }))
    }

    const areFieldsValid = () => {
        const { email, firstName, lastName } = signUpFormData

        const validateField = (fieldName, fieldValue, regex, errorMessage) => {
            if (!regex.test(fieldValue)) {
                dispatch(setValidationError({ field: fieldName, message: errorMessage }))
                return false
            }
            return true
        }

        let validForm = true
        validForm = validateField('email', email, emailRegex, 'Invalid email') && validForm
        validForm = validateField('firstName', firstName, /.+/, 'Empty first name') && validForm
        validForm = validateField('lastName', lastName, /.+/, 'Empty last name') && validForm
        validForm = validForm &&
            validateField('password', password, /.+/, 'Empty password') &&
            validationRules.isDigitValid &&
            validationRules.isSymbolValid &&
            validationRules.isUppercaseValid &&
            validationRules.isLengthValid &&
            validationRules?.invalidCharacters.length === 0

        if (confirmPassword !== password) {
            dispatch(setValidationError({ field: 'confirmPassword', message: 'Passwords not match' }))
            validForm = false
        }
        validForm = validateField('confirmPassword', confirmPassword, /.+/, 'Empty confirm password') && validForm

        return validForm
    }

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
          return;
        }
    
        areFieldsValid && confirmAction(await executeRecaptcha('signup'))
    }, [executeRecaptcha, signUpFormData]);

    const confirmAction = (recaptcha) => {
        dispatch(
            signUp({
                email: signUpFormData.email,
                password: signUpFormData.password,
                confirmPassword: signUpFormData.confirmPassword,
                companyName: signUpFormData.companyName,
                firstName: signUpFormData.firstName,
                lastName: signUpFormData.lastName,
                recaptcha
            }),
        )
        tagEvent("sign_up")
        tagEvent('conversion', { send_to: 'AW-11416898693/2uVpCPH2940ZEIWZgMQq' })
    }

    return (
        <MiddleFormContainer marginTop="130px">
            <MiddleFormTitleContainer>
                <H1Title>Sign Up</H1Title>
            </MiddleFormTitleContainer>
            <MiddleFormSubtitleContainer>
                <SubtitleText>Please enter your details.</SubtitleText>
            </MiddleFormSubtitleContainer>

            <ErrorMessage error={globalError} />

            <FormContainer onSubmit={handleReCaptchaVerify}>
                <fieldset>
                    <FieldContainer>
                        <fieldset>
                            <TextField
                                autoFocus
                                label={'Email'}
                                value={signUpFormData.email}
                                changeEvent={changeEmailField}
                                errorMessage={validationErrors.email}
                                placeholder={'Enter your email'}
                            />
                        </fieldset>
                    </FieldContainer>
                    <FieldsInRowContainer>
                        <ShortFieldContainer>
                            <fieldset>
                                <TextField
                                    label={'First Name'}
                                    value={signUpFormData.firstName}
                                    errorMessage={validationErrors.firstName}
                                    changeEvent={changeFirstNameField}
                                    placeholder={'Enter your first name'}
                                />
                            </fieldset>
                        </ShortFieldContainer>
                        <ShortFieldContainer>
                            <fieldset>
                                <TextField
                                    label={'Last Name'}
                                    value={signUpFormData.lastName}
                                    errorMessage={validationErrors.lastName}
                                    changeEvent={changeLastNameField}
                                    placeholder={'Enter your last name'}
                                />
                            </fieldset>
                        </ShortFieldContainer>
                    </FieldsInRowContainer>
                    <FieldContainer>
                        <fieldset>
                            {/*todo: add validation*/}
                            <TextField
                                label={'Password'}
                                value={signUpFormData.password}
                                changeEvent={changePasswordField}
                                errorMessage={validationErrors.password}
                                placeholder={'Enter your password'}
                                type={'password'}
                            />
                            <ValidationRulesContainer>
                                <RegularText lineHeight={'24px'} fontWeight={400}>
                                    Password must have:
                                </RegularText>
                                <RuleContainer>
                                    {validationRules.isSymbolValid ? (
                                        <StyledSVG color={'rgba(52, 168, 83, 1)'} src={CheckIcon} />
                                    ) : (
                                        <StyledSVG width="18" color={'rgba(102, 112, 133, 1)'} src={PointIcon} />
                                    )}
                                    <RegularText
                                        lineHeight={'24px'}
                                        fontWeight={400}
                                        color={
                                            validationRules.isSymbolValid
                                                ? 'rgba(52, 168, 83, 1)'
                                                : 'rgba(139, 133, 129, 1)'
                                        }
                                    >
                                        &ensp; At least one symbol (!, $, @, *, etc)
                                    </RegularText>
                                </RuleContainer>
                                <RuleContainer>
                                    {validationRules.isLengthValid ? (
                                        <StyledSVG color={'rgba(52, 168, 83, 1)'} src={CheckIcon} />
                                    ) : (
                                        <StyledSVG width="18" color={'rgba(102, 112, 133, 1)'} src={PointIcon} />
                                    )}
                                    <RegularText
                                        lineHeight={'24px'}
                                        fontWeight={400}
                                        color={
                                            validationRules.isLengthValid
                                                ? 'rgba(52, 168, 83, 1)'
                                                : 'rgba(139, 133, 129, 1)'
                                        }
                                    >
                                        &ensp; At least 8 characters
                                    </RegularText>
                                </RuleContainer>
                                <RuleContainer>
                                    {validationRules.isUppercaseValid ? (
                                        <StyledSVG color={'rgba(52, 168, 83, 1)'} src={CheckIcon} />
                                    ) : (
                                        <StyledSVG width="18" color={'rgba(102, 112, 133, 1)'} src={PointIcon} />
                                    )}
                                    <RegularText
                                        lineHeight={'24px'}
                                        fontWeight={400}
                                        color={
                                            validationRules.isUppercaseValid
                                                ? 'rgba(52, 168, 83, 1)'
                                                : 'rgba(139, 133, 129, 1)'
                                        }
                                    >
                                        &ensp; At least one uppercase character
                                    </RegularText>
                                </RuleContainer>
                                <RuleContainer>
                                    {validationRules.isDigitValid ? (
                                        <StyledSVG color={'rgba(52, 168, 83, 1)'} src={CheckIcon} />
                                    ) : (
                                        <StyledSVG width="18" color={'rgba(102, 112, 133, 1)'} src={PointIcon} />
                                    )}
                                    <RegularText
                                        lineHeight={'24px'}
                                        fontWeight={400}
                                        color={
                                            validationRules.isDigitValid
                                                ? 'rgba(52, 168, 83, 1)'
                                                : 'rgba(139, 133, 129, 1)'
                                        }
                                    >
                                        &ensp; At least one digit (0-9)
                                    </RegularText>
                                </RuleContainer>
                                <RuleContainer>
                                    {validationRules.invalidCharacters?.length === 0 ? (
                                        <StyledSVG color={'rgba(52, 168, 83, 1)'} src={CheckIcon} />
                                    ) : (
                                        <StyledSVG width="18" color={'rgba(102, 112, 133, 1)'} src={PointIcon} />
                                    )}
                                    <RegularText
                                        lineHeight={'24px'}
                                        fontWeight={400}
                                        color={
                                            validationRules.invalidCharacters?.length === 0
                                                ? 'rgba(52, 168, 83, 1)'
                                                : 'rgba(139, 133, 129, 1)'
                                        }
                                    >
                                        &ensp; Contains no invalid characters {
                                            validationRules.invalidCharacters?.length > 0 ?
                                            `(${validationRules.invalidCharacters.join(", ")})` :
                                            ''
                                        }
                                    </RegularText>
                                </RuleContainer>
                            </ValidationRulesContainer>
                        </fieldset>
                    </FieldContainer>
                    <FieldContainer>
                        <fieldset>
                            {/*todo: add validation*/}
                            <TextField
                                label={'Confirm password'}
                                value={signUpFormData.confirmPassword}
                                errorMessage={validationErrors.confirmPassword}
                                changeEvent={changeConfirmPasswordField}
                                placeholder={'Confirm your password'}
                                type={'password'}
                            />
                        </fieldset>
                    </FieldContainer>
                </fieldset>
                <ButtonsContainer>
                    <ButtonBlock>
                        <SimpleButton text="Sign Up" />
                    </ButtonBlock>
                </ButtonsContainer>
                <EulaWarningText>By signing up, you are agreeing to abide by our end-user license agreement, which can be found <a href="https://quillora.com/eula">here.</a></EulaWarningText>
            </FormContainer>
            <LogInLinkContainer>
                <RegularText>Already have an account? &ensp; </RegularText>
                <LogInLink to={ROUTES.SIGN_IN}>Log in</LogInLink>
            </LogInLinkContainer>
        </MiddleFormContainer>
    )
}

export default memo(SinglePageSignUpForm)
