import styled from 'styled-components'
import { white } from '../../styles/colors'
import { TextInput } from '../styles'

export const SearchFieldContainer = styled.div`
    position: relative;
    width: ${(props) => props.width || '259px'};
    height: ${(props) => props.height || '40px'};
`

export const SearchIconContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 15px;
    z-index: 2;
    transform: translateY(-50%);
`

export const SearchInput = styled(TextInput)`
    width: 100%;
    max-height: 100%;
    padding: 10px 14px 10px 45px;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    margin-top: 0;
    -moz-border-radius: 32px;
    background: ${(props) => (props.background ? props.background : white)};
`
