import { useState, useEffect, useCallback } from "react";

const MOBILE_MAX_WIDTH = 480

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({ width: 0 });

    if (typeof window !== "undefined") {
        const getWindowDimensions = useCallback(() => {
            const { innerWidth: width, innerHeight: height } = window;
            const isMobile = width < MOBILE_MAX_WIDTH;
            return {
                width,
                height,
                isMobile,
            };
        }, [window]);

        useEffect(() => {
            setWindowDimensions(getWindowDimensions());
            const handleResize = () =>
                setWindowDimensions(getWindowDimensions());

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);
    }

    return windowDimensions;
}
