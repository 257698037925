import styled from 'styled-components'
import { naturalGray, white } from '../../../../common/styles/colors'
import SearchField from '../../../../common/form/search-field/SearchField'
import { SearchFieldContainer, SearchIconContainer } from '../../../../common/form/search-field/styles'

export const FilterMenuContainer = styled.div`
    position: absolute;
    width: 498px;
    background: rgba(40, 40, 40, 0.95);
    backdrop-filter: blur(6px);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15), 0px 16px 104px 0px rgba(0, 0, 0, 0.3),
        0px 24px 196px 0px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    padding: 8px 22px 24px;
    border: 2px solid ${naturalGray};
    z-index: 150;

    & ${SearchFieldContainer} svg path {
        fill: ${white};
    }

    & ${SearchIconContainer} {
        left: 0;
    }

    @media (max-width: 480px) {
        width: calc(100vw - 24px);
        height: 100vh;
        top: -100px;
        left: -20px;
        padding-top: 54px;
    }
`

export const FilterColseButton = styled.div`
    position: absolute;
    top: 24px;
    right: 28px;

    & svg path {
        fill: ${white};
    }
`

export const ArticlesListWrapper = styled.div`
    padding: 20px 0 32px;
    border-bottom: 1px solid rgba(212, 208, 205, 0.2);
    border-top: 1px solid rgba(212, 208, 205, 0.2);
`

export const ArticlesListContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`

export const TitlesContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const ArticlesListButtonContainer = styled.div`
    cursor: pointer;

    & p {
        text-transform: none !important;
    }
`

export const TitleWrapper = styled.div`
    & p {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;
    }
`

export const ArticleItemContainer = styled.div`
    display: flex;
    column-gap: 16px;

    & svg {
        min-width: 32px;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    & p {
        max-width: 410px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;

        & span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
            background: #905f00;
        }
    }

    @media (max-width: 480px) {
        max-width: 290px;
    }
`

export const DescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 9px;
`

export const CircleBullet = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${naturalGray};
`

export const FilterSearchField = styled(SearchField)`
    background: none;
    border: none;
    outline: none;
    color: ${white};
    padding-left: 32px;

    &:hover,
    &:focus {
        border: none;
    }
`

export const TagsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    margin-top: 22px;
`

export const TagsListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
`

export const TagContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-radius: 32px;
    background: rgba(246, 246, 246, 0.2);
    padding: 8px 16px 8px 12px;
    cursor: pointer;
`
