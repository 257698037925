import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closePopup } from '../../../../../reducers/popup'
import { PopupComponentKeys } from '../../../../common/popup/Popup'
import { RegularText } from '../../../../common/styles/text'
import { black, white } from '../../../../common/styles/colors'
import { CustomButton, EditField, EditPopupContainer } from './styles'

function UpdateValuePopup({ extraData }) {
    const dispatch = useDispatch()
    const [editFieldValue, setEditFieldValue] = useState('')

    const closePopupAction = () => {
        const close = () => dispatch(closePopup({ component: PopupComponentKeys.UPDATE_VALUE }))
        if (extraData.handleClick) {
            extraData.handleClick(editFieldValue, close)
        } else {
            close()
        }
    }

    return (
        <EditPopupContainer>
            <RegularText fontSize="24px" color={black}>
                {extraData.title}
            </RegularText>
            <div>
                <RegularText>{extraData.subTitle}</RegularText>
                <EditField value={editFieldValue} onChange={(e) => setEditFieldValue(e.target.value)} />
            </div>
            <CustomButton onClick={closePopupAction}>
                <RegularText color={white}>{extraData.buttonText}</RegularText>
            </CustomButton>
        </EditPopupContainer>
    )
}

export default memo(UpdateValuePopup)
