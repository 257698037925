import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { confirmEmail, signUp } from '../../components/user/sign-up/slice'

const initialState = {
    step: 1,
    data: {
        email: '',
        firstName: '',
        lastName: '',
        companyName: 'None',
        password: '',
        confirmPassword: '',
    },
    validationErrors: {},
    globalError: null,
    status: REQUEST_STATUSES.NOT_TRIGGERED,
}

const signUpFormSlice = createSlice({
    name: 'forms.signUpForm',
    initialState,
    extraReducers(builder) {
        builder.addCase(signUp.fulfilled, (state) => {
            state.step = 2
        })
        builder.addCase(signUp.rejected, failed)
        builder.addCase(confirmEmail.rejected, failed)
        builder.addCase(confirmEmail.fulfilled, (state, action) => {
            state.status = REQUEST_STATUSES.DONE
        })
    },
    reducers: {
        changeRequestStatus: (state, action) => {
            state.status = action.payload.status
        },
        changeStep: (state, action) => {
            state.globalError = null
            state.step = action.payload
        },
        changeEmailValue: (state, action) => {
            state.globalError = null
            state.data.email = action.payload.email
        },
        changeFirstNameValue: (state, action) => {
            state.globalError = null
            state.data.firstName = action.payload.firstName
        },
        changeLastNameValue: (state, action) => {
            state.globalError = null
            state.data.lastName = action.payload.lastName
        },
        changeCompanyNameValue: (state, action) => {
            state.globalError = null
            state.data.companyName = action.payload.companyName
        },
        changePasswordValue: (state, action) => {
            state.globalError = null
            state.data.password = action.payload.password
        },
        changeConfirmPasswordValue: (state, action) => {
            state.globalError = null
            state.data.confirmPassword = action.payload.confirmPassword
        },
        setValidationError: (state, action) => {
            state.validationErrors[[action.payload.field]] = action.payload.message
        },
        resetError: (state) => {
            state.globalError = null
            state.validationErrors = {}
        },
        resetForm: () => initialState,
    },
})

const selectSignUpFormSlice = (state) => state.forms.signUpForm

export const selectSignUpFormData = (state) => selectSignUpFormSlice(state).data
export const selectStep = (state) => selectSignUpFormSlice(state).step
export const selectRequestStatus = (state) => selectSignUpFormSlice(state).status
export const selectGlobalError = (state) => selectSignUpFormSlice(state).globalError
export const selectValidationErrors = (state) => selectSignUpFormSlice(state).validationErrors

const { reducer, actions } = signUpFormSlice

export const {
    changeEmailValue,
    changeLastNameValue,
    changeFirstNameValue,
    changeCompanyNameValue,
    changePasswordValue,
    changeConfirmPasswordValue,
    changeStep,
    resetForm,
    setValidationError,
    changeRequestStatus,
} = actions

export default reducer
