import React, { memo, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from './PaymentForm'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import PrivateArea from '../../../common/layouts/private-area/PrivateArea'
import { ROUTES } from '../../../../utils/utils'
import { PaymentAgent } from '../../../../api/paymentAgent'
import { STRIPE_PUBLISHABLE_KEY } from '../../../../configs'

function SubscriptionCheckoutStripe() {
    const navigate = useNavigate()
    const location = useLocation()
    const [clientSecret, setClientSecret] = useState(null)
    const [subscriptionId, setSubscriptionId] = useState(null)

    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

    useEffect(() => {
        if (location.state === null || location.state.productId === undefined) {
            navigate(ROUTES.SETTINGS)
            return
        }
        const fetchClientSecret = async () => {
            const { clientSecret, subscriptionId } = await PaymentAgent.createSubscription(location.state.productId)
            setClientSecret(clientSecret)
            setSubscriptionId(subscriptionId)
        }

        fetchClientSecret()
    }, [])

    return (
        <PrivateArea>
            {clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                    <PaymentForm
                        subscriptionId={subscriptionId}
                        price={location.state.price}
                        productId={location.state.productId}
                        period={location.state.period}
                    />
                </Elements>
            )}
        </PrivateArea>
    )
}

export default memo(SubscriptionCheckoutStripe)
