import styled from 'styled-components'

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 48px;
`

export const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 810px;
    align-items: center;
    border-bottom: 1px solid #d5d0cd;
    align-self: center;
    align-content: center;
    margin: auto;
    cursor: pointer;
    @media (max-width: 780px) {
        width: 100%;
    }
`

export const TabContainer = styled.div`
    border-bottom: ${(props) => (props.active ? '5px solid #FF6A17' : 'none')};
    margin: 48px 55px 0 55px;
    width: 126px;
    padding-bottom: 13px;
    z-index: 9;
    text-align: center;
    position: relative;
    top: 3px;

    @media (max-width: 780px) {
        width: 100%;
        top: 23px;
        margin: 20px 0;

        & p {
            font-size: 14px;
            font-weight: 400;
        }
    }
`

export const ContentContainer = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: auto;

    @media (max-width: 780px) {
        width: 90%;
        & form {
            width: 100%;
        }
    }
`

export const PaymentFormContainer = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 100px auto;
`

export const ButtonBlock = styled.div`
    margin-top: 20px;
    width: 300px;
`
