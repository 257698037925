import React, { useState } from 'react'
import { CompositeDecorator, EditorState, Modifier } from 'draft-js'
import Link from './Link'
import { RichTextEditorButton } from '../../styles'
import EmbedLinkIcon from '../../../../../../assets/images/embed-link.svg'
import StyledSVG from '../../../../../common/styled-svg/StyledSVG'
import EnterInfoPopup from './EnterInfoPopup'

function EmbeddedLink({ editorState, setEditorState }) {
    const [isOpen, setIsOpen] = useState(false)

    const findLinkEntities = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity()
            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
        }, callback)
    }

    const createLinkDecorator = () =>
        new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ])

    const getCurrentTextSelection = (editorState) => {
        const selectionState = editorState.getSelection()
        const anchorKey = selectionState.getAnchorKey()
        const currentContent = editorState.getCurrentContent()
        const currentContentBlock = currentContent.getBlockForKey(anchorKey)
        const start = selectionState.getStartOffset()
        const end = selectionState.getEndOffset()
        const selectedText = currentContentBlock.getText().slice(start, end)

        return selectedText
    }

    const onAddLink = (linkUrl) => {
        const decorator = createLinkDecorator()
        if (linkUrl) {
            let displayLink = getCurrentTextSelection(editorState)

            if (displayLink) {
                const currentContent = editorState.getCurrentContent()
                currentContent.createEntity('LINK', 'MUTABLE', {
                    url: linkUrl,
                })

                let entityKey = currentContent.getLastCreatedEntityKey()
                const selection = editorState.getSelection()
                const textWithEntity = Modifier.replaceText(
                    currentContent,
                    selection,
                    displayLink,
                    editorState.getCurrentInlineStyle(),
                    entityKey,
                )
                let newState = EditorState.createWithContent(textWithEntity, decorator)
                setEditorState(newState)
            }
        }
    }

    return (
        <RichTextEditorButton onClick={() => setIsOpen((prevState) => !prevState)}>
            <StyledSVG src={EmbedLinkIcon} />
            <div onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}>
                <EnterInfoPopup isOpen={isOpen} setIsOpen={setIsOpen} onClick={onAddLink} placeholder="Enter URL" />
            </div>
        </RichTextEditorButton>
    )
}

export default EmbeddedLink
