import React, {memo} from 'react'
import { H1Title, SubtitleText } from '../../common/styles/text'
import {
    MiddleFormContainer,
    MiddleFormSubtitleContainer,
    MiddleFormTitleContainer,
} from '../../common/layouts/public-area/middle-form/styles'
import { useSelector } from 'react-redux'
import { selectForgotPasswordFormData } from '../../../reducers/forms/forgot-password-form'

function EmailSentConfirmation() {
    const forgotPasswordFormData = useSelector(selectForgotPasswordFormData)

    return (
        <MiddleFormContainer marginTop="130px">
            <MiddleFormTitleContainer>
                <H1Title>Password Reset Email Sent!</H1Title>
            </MiddleFormTitleContainer>
            <MiddleFormSubtitleContainer>
                <SubtitleText>
                    We've emailed a link to reset your password to <b>{forgotPasswordFormData.email}</b>! If you don't see it in a few minutes, please check your spam folder.
                </SubtitleText>
            </MiddleFormSubtitleContainer>
        </MiddleFormContainer>
    )
}

export default memo(EmailSentConfirmation)
